import React, { Component, Fragment } from "react";
import { reduxForm, Field, initialize, FieldArray, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withLocalize } from "react-localize-redux";
import { updateQuestionAction } from '../../redux/wedding/wedding.actions';
import BaseButton from "../../components/buttons/BaseButton";
import {
  FormContainer,
  HeaderContainer,
  PageTitle,
  HeaderTitle,
  BaseForm,
  HeaderButtonsContainer,
  SpinLoading,
  DefaultLanguageTab
} from "../../styles/BasicStyles";
import { Tabs, Row, Col, notification, Modal, Radio, Alert, Menu, Icon, Dropdown, Button, Tooltip, BackTop, Tag } from "antd";
import moment from "moment";
import { ActivateRoomPlan, AnswerQuestion, ApproveRoomPlan, ChangeRoomPlanTemplate, CheckSubmitRoomPlan, CheckUpdateCoupleTable, DisapproveRoomPlan, GetCoupleTableEditor, GetOurGuests, GetOurGuestsEditor, SaveGuests, SaveOurGuestsEditor, SaveRoomPlanImage, SaveSectionNotes, SubmitRoomPlan, UndoSubmitRoomPlan } from "../../infra/requests/WeddingProcessRequests";
import { SaveGraphicMenu } from "../../redux/wedding/wedding.actions";
import { DecorContainer, PageContainer, RoomPlanContentStyle } from "../decoration/DecorationStyles";
import CommentsDrawerFixed from "../../components/comments/CommentsDrawerFixed";
import { getPrintedConfirmation } from '../../infra/services/wedding/printedUtils';
import { hasNotes } from "../../infra/services/notes/FindNotes";
import NotesConfirmModal from '../../components/notesModal/NotesModal';
import { SaveNotes } from '../../redux/wedding/wedding.actions';
import ToolbarRoomPlanEditor from "./RoomPlanEditor";
import RoomPlanEditor from "./RoomPlanEditor";
import Alerts from "../../components/alert/Alert";
import { PrintedBarContent, PrintedMessage } from "../dashboard/ProcessStyles";
import FormValidator from "../../infra/services/validations/FormValidator";
import { inRoomPlanTest } from "../../infra/helpers/Helpers";
import TextAreaInput from "../../components/inputs/TextAreaInput";
import { CostExtraContent, CostExtraMessage, GuestMapContentStyle, GuestMapStats, NotesTabPane, NotesTabs, RoomEditor, RoomEditorContainer, RoomEditorContent, SeatName, SeatRow, StatsContentRow, TabName, TableGuestsHeader, TableGuestsModal, TableName, TableSearchCode, TableSeatCode, TablesSearch, Toolbar } from "./RoomPlanStyles";
import { GetRoomPlanConfigByPlace } from "../../infra/requests/RoomPlanConfigRequests";
import { GetTableTypeList } from "../../infra/requests/TableTypeRequests";
import { GetFoodRestrictionList } from "../../infra/requests/FoodRestrictionsRequests";
import NormalPlanIcon from "../../assets/icons/normalRoomPlan.svg";
import XLPlanIcon from "../../assets/icons/xlRoomPlan.svg";
import PencilIcon from '../../assets/icons/pencil.svg';
import PencilBlockedIcon from '../../assets/icons/pencil-blocked.svg';
import CostExtraIcon from '../../assets/icons/extra_cost.jpg';
import SelectInput from "../../components/inputs/SelectInput";
import XpertGoLeavePageBlocker from "../../weddingday/reservesAttending/XpertGoLeavePageBlocker";
import { getAnswerPrintedConfirmation } from '../../infra/services/wedding/printedUtils';
import { correctResponseValues } from "../ChoicesPage/components/OptionUtils";
import { FlattenToFormData } from "../../infra/services/formdata/TransformToFormData";
import SectionModal from "../ChoicesPage/SectionModal";
import { auth_token_key } from "../../infra/config/LocalStorageKeys";
import { checkExtraCostByTables, canChangeSeats, convertNumberToLetter, getSummaryFoodRestrictions, calculateExtraTables } from "./utilRoomPlan";
import ReactSVG from "react-svg";
import GuestTablesComponent from "../../components/guestMap/GuestTablesComponent";
import { getRoomPlanPrintedConfirmation } from '../../infra/services/wedding/printedUtils';
import ImportGuestModal from "./ImportGuestModal";
import { AuthGoogleDrive, UploadGuestFlyersToDrive, UploadRoomPlanToDrive } from "../../infra/requests/ReportsRequests";
import { Link, scroller } from "react-scroll";
import { checkCoupleTableAnswer, removeNotExistingOptions } from "../../infra/services/options/Options";
import { GuestAgeEnum, TablePurposeEnum } from "../../components/guestMap/utilGuest";
const { TabPane } = Tabs;

const REACT_APP_API = process.env.REACT_APP_API;

export const StatsEnum = {
  ADULT: 'ADULT',
  NEWBORN: 'NEWBORN',
  CHILD: 'CHILD',
  STAFF: 'STAFF',
  TABLES: 'TABLES',
  GUESTS: 'GUESTS',
  BABY: 'BABY',
}

const { confirm } = Modal;
class ManageWeddingGuestMapPage extends Component {
  timeout = 0;
  roomEditor = null;

  state = {
    loading: false,
    ready: false,

    sectionGuests: {},
    currentChapter: {},
    sectionDecor: {},

    content: {},

    tables: [],
    ourGuest: {},
    objectTypes: [],
    foodRestrictions: [],
    answerSeatId: false,

    notes: '',
    kitchenNotes: '',
    notesActiveTab: '1',
    invalidKitchenNotes: false,

    dirtyForm: false,
    submittedForm: false,
    saving: false,

    activating: false,
    submitting: false,
    validating: false,
    changingPlan: false,
    exporting: false,

    openImportModal: false,
    importingGuests: false,
    importModalTable: null,

    coupleTableQuestion: {},
    tableThemeQuestion: {},
    questionModal: null,
    showModal: false,
    savingModal: false,
  };

  componentDidMount = async () => {
    const { history } = this.props;
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    document.body.style.overflowY = "auto";

    if (!inRoomPlanTest()) return history.goBack();

    // this.roomEditor = new window.RoomEditor('editor', 'pt');

    this.getInformation();
  }

  componentWillUnmount() {
    if (this.roomEditor) {
      this.roomEditor.destroy();
    }
  }

  getInformation = async () => {
    const { structureInfo, wedding } = this.props;
    const {
      match: { params },
      dispatch
    } = this.props;
    this.setState({ loading: true });

    const filterObjects = {
      weddingPlace: wedding?.wedding?.wedding_place
    };
    const dataRoomEditor = {
      roomPlan: {
        imageSrc: null,
        x: 0,
        y: 0,
        scale: 0
      },
      tables: [],
      foodRestrictions: [],
    };

    const sectionGuests = structureInfo.find(s => s.tag === 'GUESTS');
    const currentChapter = sectionGuests.chapters.find(f => f?._id === '65b37c49b42ba97e4a0db4c9');
    const sectionDecor = structureInfo.find(s => s.tag === 'DECORATION');
    const decorQuestions = sectionDecor?.chapters.map(m => m?.questions).flat();
    const coupleTableQuestion = decorQuestions.find(f => f?.tableForRoomPlan);
    const tableThemeQuestion = decorQuestions.find(f => f?.isTableTheme);

    const { success, data } = await GetOurGuestsEditor(params.wedding);
    let tables = data?.tables || [];
    if (tables?.length > 0) {
      tables = tables.map(m => ({ ...m, tableType: m?.tableClass }));
    }
    let answerSeatId = data?.answerSeatId;

    // Set data for room editor
    dataRoomEditor.roomPlan = {
      imageSrc: `${process.env.REACT_APP_IMAGES_URL}${data?.ourGuest?.bgRoomPlan?._id}`,
      x: data?.ourGuest?.roomPlanPosition?.x || 0,
      y: data?.ourGuest?.roomPlanPosition?.y || 0,
      scale: data?.ourGuest?.roomPlanPosition?.scale || 0,
      horizontalCenterOfRoom: data?.ourGuest?.roomPlanHorizontalCenterOfRoom || 0,
      constraintPoints: data?.ourGuest?.roomPlanConstraintPoints || [],
      zones: data?.ourGuest?.roomPlanZones || [],
      spaceBetweenTables: data?.spaceBetweenTables || 0,
    };
    dataRoomEditor.tables = tables;

    this.setState({
      sectionGuests,
      currentChapter,
      sectionDecor,
      coupleTableQuestion,
      tableThemeQuestion,
      ourGuest: data?.ourGuest || {},
      notes: data?.ourGuest?.notes || '',
      kitchenNotes: data?.ourGuest?.kitchenNotes || '',
      answerSeatId,
      showModal: false
    });

    // Room plan configs
    if (filterObjects?.weddingPlace) {
      const resultRoomPlanConfigs = await GetRoomPlanConfigByPlace(filterObjects?.weddingPlace);
      if (resultRoomPlanConfigs?.success) {
        let roomPlans = resultRoomPlanConfigs?.data || [];
        roomPlans = roomPlans.filter(f => !f?.deleted || (f?.deleted && f?._id == data?.ourGuest?.roomPlanTemplate));
        this.setState({ roomPlanOptions: roomPlans });
      } else {
        return Alerts.new({
          type: 'error',
          title: 'Erro',
          text: 'Erro ao carregar planos!'
        });
      }
    }

    // Table types
    let objectTypes = [];
    const resultObjects = await GetTableTypeList(filterObjects);
    if (resultObjects?.success) {
      objectTypes = resultObjects?.data || [];
      const coupleTable = objectTypes?.filter(f => f?.coupleTable);
      const guestsTable = objectTypes?.filter(f => !f?.coupleTable && !f?.increasedTable);

      if (this.roomEditor) {
        this.roomEditor.activeCoupleTables(coupleTable.map(m => m?.tableClass));
        this.roomEditor.activeGuestTables(guestsTable.map(m => m?.tableClass));
      }
    } else {
      return Alerts.new({
        type: 'error',
        title: 'Erro',
        text: 'Erro ao carregar mesas!'
      });
    }

    // Food restrictions
    let foodRestrictions = [];
    const resultFoodRestrictions = await GetFoodRestrictionList();
    if (resultFoodRestrictions?.success) {
      foodRestrictions = resultFoodRestrictions?.data || [];
      dataRoomEditor.foodRestrictions = foodRestrictions;
    } else {
      return Alerts.new({
        type: 'error',
        title: 'Erro',
        text: 'Erro ao carregar restrições alimentares!'
      });
    }

    // console.warn('dataRoomEditor', dataRoomEditor);
    if (this.roomEditor) this.roomEditor.deserializeEditor(dataRoomEditor);

    this.setState({
      ready: true,
      loading: false,
      objectTypes,
      foodRestrictions,
      tables: tables,
    }, () => {
      setTimeout(() => {
        if (this.roomEditor) this.roomEditor.setMode("READ_ONLY");

        if (answerSeatId) {
          if (this.roomEditor) this.roomEditor.setGuestsMode('SEAT');
        } else {
          if (this.roomEditor) this.roomEditor.setGuestsMode('TABLE');
        }
      }, 1500);
    });
  };

  /** Stats */
  getFoodRestrictionsStats = () => {
    const { foodRestrictions, tables } = this.state;
    const seatsWithFoodRestrictions = tables?.length > 0
      ? tables?.filter(f => f?.active && !f?.removeBecauseOfOvalL).map(m => m?.seats).flat().filter(f => f?.guestName?.trim() != '' && f?.foodRestrictions?.length > 0)
      : [];
    const seatsWithFoodRestrictionsPopulated = seatsWithFoodRestrictions.map(m => ({
      ...m,
      foodRestrictions: foodRestrictions.filter(f => m.foodRestrictions.includes(f?._id))
    })).filter(f => f?.foodRestrictions?.length > 0);

    return seatsWithFoodRestrictionsPopulated?.length > 0
      ? getSummaryFoodRestrictions(seatsWithFoodRestrictionsPopulated)
      : `<div>Sem restrições alimentares</div>`;
  }

  getStats = (type) => {
    const { tables } = this.state;
    const seats = tables?.length > 0
      ? tables?.filter(f => f?.active && !f?.removeBecauseOfOvalL && f?.tablePurpose != TablePurposeEnum.STAFF).map(m => m?.seats).flat().filter(f => f?.guestName?.trim() != '')
      : [];

    let count = 0;
    switch (type) {
      case StatsEnum.ADULT:
        count = seats.filter(f => f?.guestAge == GuestAgeEnum.ADULT)?.length;
        break;
      case StatsEnum.NEWBORN:
        count = seats.filter(f => (f?.guestAge == GuestAgeEnum.NEWBORN || f?.guestAge == GuestAgeEnum.BABY))?.length;
        break;
      case StatsEnum.CHILD:
        count = seats.filter(f => f?.guestAge == GuestAgeEnum.CHILD)?.length;
        break;
      case StatsEnum.STAFF:
        const seatsStaff = tables?.find(f => f?.active && !f?.removeBecauseOfOvalL && f?.tablePurpose == TablePurposeEnum.STAFF)?.seats?.filter(f => f?.guestName?.trim() != '');
        count = seatsStaff?.length || 0;
        break;
      case StatsEnum.TABLES:
        count = tables.filter(f => f?.active && !f?.removeBecauseOfOvalL && f?.tablePurpose != TablePurposeEnum.STAFF)
          .filter(f => f?.seats?.length > 0 && f?.seats.find(f2 => f2?.guestName?.trim() != ''))
          .map(m => m?.value).reduce((a, b) => a + b, 0);
        break;
      case StatsEnum.GUESTS:
        count = seats?.length || 0;
        break;
      default:
        count = 0;
        break;
    }
    return count;
  }

  getPax = () => {
    const countAdults = this.getStats(StatsEnum.ADULT);
    const countChildren = this.getStats(StatsEnum.CHILD);
    const countStaff = this.getStats(StatsEnum.STAFF);
    return (countAdults + (countChildren / 2) + (countStaff / 2)) || 0;
  }

  getAvgPaxTables = () => {
    const totalSeatsFilled = this.getStats(StatsEnum.GUESTS);
    const totalTablesActive = this.getStats(StatsEnum.TABLES);
    return (totalSeatsFilled / totalTablesActive) || 0;
  }

  getChefFoodRestrictions = () => {
    const { foodRestrictions } = this.state;
    return `Restrições alimentares: ${foodRestrictions?.filter(f => f?.chefChoice && f?.acronym).map(m => m?.acronym['pt']).join(', ')}`;
  }

  /** Save guest map and room plan */
  handleNotesChange = (event) => {
    this.setState({ dirtyForm: true, notes: event?.target?.value });
  }

  handleKitchenNotesChange = (event) => {
    this.setState({ dirtyForm: true, kitchenNotes: event?.target?.value });
  }

  changeNotesActiveTab = (e) => {
    this.setState({ notesActiveTab: e });
  }

  confirmAfterSave = () => {
    const { history, match: { params } } = this.props;
    confirm({
      title: `Avançar para o plano de sala?`,
      okText: 'Avançar',
      cancelText: 'Manter',
      onOk: () => {
        history.push(`/wedding/${params.wedding}/roomPlan`);
      },
      onCancel: () => this.getInformation(),
    });
  }

  confirmOnSubmit = async (e, endAction = null) => {
    if (e) e.preventDefault();
    const { ourGuest, foodRestrictions, tables, kitchenNotes } = this.state;

    const requiredFood = foodRestrictions?.length > 0
      ? foodRestrictions.filter(f => f?.notesRequired).map(m => m?._id)
      : [];

    const selectedRestrict = tables?.length > 0
      ? tables?.map(m => m?.seats).flat().filter(f => f?.guestName?.trim() !== '' && f?.foodRestrictions?.length > 0).map(s => s?.foodRestrictions).flat()
      : [];

    if (selectedRestrict.filter(f => requiredFood.includes(f)).length > 0 && kitchenNotes?.trim() == '') {
      this.setState({
        notesActiveTab: '1',
        invalidKitchenNotes: true
      });
      return Alerts.new({
        type: 'warning',
        title: 'Atenção',
        text: 'Indique nas notas para a cozinha a informação relativa às restrições alimentares.'
      });
    } else {
      this.setState({ invalidKitchenNotes: false });
    }

    if (ourGuest?.validatedRoomPlan) {
      confirm({
        ...getRoomPlanPrintedConfirmation(),
        onOk: async () => { return await this.onSubmit(e, endAction); },
        onCancel: () => { return null; },
      });
    } else {
      return await this.onSubmit(e, endAction);
    }
  }

  onSubmit = async (e, endAction = null) => {
    if (e) e.preventDefault();
    const { match: { params }, dispatch } = this.props;
    const { tables, notes, kitchenNotes, foodRestrictions, changedPlan, roomPlanConfigId, ourGuest } = this.state;

    try {
      this.setState({ saving: true, loading: true, submittedForm: true });
      const data = {
        notes,
        kitchenNotes,
        tables,
        fromTables: true,
        roomPlanVersion: ourGuest?.roomPlanVersion,
      };
      if (changedPlan) {
        if (!data?.roomPlan) data['roomPlan'] = {};
        data.roomPlan['roomPlanTemplate'] = roomPlanConfigId;
        data.roomPlan['x'] = ourGuest?.roomPlanPosition?.x || 0;
        data.roomPlan['y'] = ourGuest?.roomPlanPosition?.y || 0;
        data.roomPlan['scale'] = ourGuest?.roomPlanPosition?.scale || 0;
        data.roomPlan['horizontalCenterOfRoom'] = ourGuest?.roomPlanPosition?.roomPlanHorizontalCenterOfRoom || 0;
        data.roomPlan['constraintPoints'] = ourGuest?.roomPlanConstraintPoints || [];
        data.roomPlan['zones'] = ourGuest?.roomPlanZones || [];
      }

      const result = await SaveOurGuestsEditor(params.wedding, data);

      if (result?.success) {
        Alerts.new({
          type: 'success',
          title: 'Successo',
          text: 'Distribuição de convidados guardado!'
        });

        if (result?.data?.answerRestriction) {
          dispatch(updateQuestionAction('FOOD', result?.data?.answerRestriction));
        }

        this.setState({
          saving: false,
          loading: false,
          dirtyForm: false,
          submittedForm: false,
          ourGuest: result?.data?.ourGuest || {},
          notes: result?.data?.ourGuest?.notes || '',
          kitchenNotes: result?.data?.ourGuest?.kitchenNotes || '',
          tables: result?.data?.tables || [],
          changedPlan: false,
          roomPlanConfigId: null
        }, () => {
          if (endAction == 'COUPLE') this.openModal();
          else if (endAction == 'SUBMIT_PLAN') this.submitRoomPlan();
          else if (endAction == 'VALIDATE_PLAN') this.validateRoomPlan();
          else this.confirmAfterSave();
        });
      } else {
        this.setState({
          saving: false,
          loading: false,
          submittedForm: false,
          showModal: false
        }, () => {
          if (result?.message == 'NOT_AUTHORIZED' || result?.message == 'OLD_ROOM_PLAN_VERSION') {
            this.getInformation();
          }
        });
      }
    } catch (e) {
      console.error(e);
    }
  };


  /** Type of Room plan */
  costumeSelectRoomPlan = (value) => {
    return <div className="svg-input">
      <ReactSVG src={NormalPlanIcon} className="svg-icon" />
      <span style={{ marginLeft: 5 }}>{value?.name || ''}</span>
    </div>
  }

  confirmChangeRoomPlan = async (value) => {
    const { roomPlanOptions } = this.state;

    const roomPlanTemplate = roomPlanOptions.find(f => f?._id == value);
    if (!roomPlanTemplate) return;

    confirm({
      title: `Pretende alterar para o plano ${roomPlanTemplate?.name}?`,
      content: (<div>
        <p>A alteração do plano de sala poderá implicar alterar a ordem e formato das mesas apresentadas no plano.</p>
      </div>
      ),
      okText: 'Alterar',
      cancelText: 'Cancelar',
      onOk: () => {
        this.changeRoomPlan(value);
      },
      onCancel: () => null,
    });
  }

  changeRoomPlan = async (value) => {
    const { match: { params } } = this.props;
    this.setState({ changingPlan: true, loading: true });
    const data = { roomPlanTemplate: value };
    const result = await ChangeRoomPlanTemplate(params.wedding, data);

    if (result?.success) {
      const dataRoomEditor = {
        roomPlan: {
          imageSrc: null,
          x: 0,
          y: 0,
          scale: 0
        },
        tables: [],
        foodRestrictions: [],
      };

      dataRoomEditor.roomPlan = {
        imageSrc: `${process.env.REACT_APP_IMAGES_URL}${result?.data?.ourGuest?.bgRoomPlan?._id}`,
        x: result?.data?.ourGuest?.roomPlanPosition?.x || 0,
        y: result?.data?.ourGuest?.roomPlanPosition?.y || 0,
        scale: result?.data?.ourGuest?.roomPlanPosition?.scale || 0,
        horizontalCenterOfRoom: result?.data?.ourGuest?.roomPlanHorizontalCenterOfRoom || 0,
        constraintPoints: result?.data?.ourGuest?.roomPlanConstraintPoints || [],
        zones: result?.data?.ourGuest?.roomPlanZones || [],
        spaceBetweenTables: result?.data?.spaceBetweenTables || 0,
      };
      let tables = result?.data?.tables || [];
      if (tables?.length > 0) {
        tables = tables.map(m => ({ ...m, tableType: m?.tableClass }));
      }
      dataRoomEditor.tables = tables;

      let answerSeatId = result?.data?.answerSeatId;

      console.warn('changedPlan dataRoomEditor', dataRoomEditor);
      if (this.roomEditor) this.roomEditor.deserializeEditor(dataRoomEditor);

      this.setState({
        changingPlan: false,
        loading: false,
        changedPlan: true,
        ourGuest: result?.data?.ourGuest || {},
        tables,
        answerSeatId,
        roomPlanConfigId: result?.data?.roomPlanConfigId,
        dirtyForm: true,
      });
    } else {
      this.setState({
        changingPlan: false,
        loading: false
      });
    }
  }

  /** Activate manually guest map and room plan */
  confirmActivateRoomPlan = () => {
    confirm({
      title: `Tem certeza que pretende ativar o plano sala?`,
      content: <div>
        <p>Ao ativar o plano de sala irá permitir ao casal e ao convidado (caso exista) editarem o plano de sala.</p>
      </div>,
      okType: 'primary',
      okText: 'Ativar',
      cancelText: 'Cancelar',
      onOk: () => { this.activateRoomPlan(); },
      onCancel: () => null,
    });
  }

  activateRoomPlan = async () => {
    const { match: { params } } = this.props;
    this.setState({ activating: true });
    const result = await ActivateRoomPlan(params.wedding);
    if (result?.success) {
      Alerts.new({
        type: 'success',
        title: 'Sucesso',
        text: `Lista de convidados ativa.`
      });
      this.setState({ activating: false }, () => this.getInformation());
    } else {
      this.setState({ activating: false });
    }
  };

  /** Submit guest map and room plan */
  checkSubmit = async () => {
    const { match: { params } } = this.props;
    const { ourGuest } = this.state;

    if (!ourGuest?.submittedRoomPlan) {
      const resultSubmit = await CheckSubmitRoomPlan(params.wedding);
      if (resultSubmit?.success) {
        // Show modal to answer table theme question
        if (resultSubmit?.data?.blockSubmit) {
          this.openModal(true);
        } else {
          this.confirmSubmit();
        }
      } else {
        return;
      }
    } else {
      this.confirmSubmit();
    }
  }

  confirmSubmit = () => {
    const { dirtyForm, submittedForm } = this.state;
    const { ourGuest } = this.state;

    if (dirtyForm && !submittedForm) {
      return this.confirmOnSubmit(null, 'SUBMIT_PLAN')
    }

    const title = ourGuest?.submittedRoomPlan
      ? 'Tem a certeza que pretende remover a comunicação da lista?'
      : `Tem certeza que pretende comunicar a lista?`;

    const content = ourGuest?.submittedRoomPlan
      ? <div>
        <p>Ao remover a comunicação da lista permitirá ao casal proceder a uma nova comunicação para o SLevada & QLCisnes</p>
      </div>
      : <div>
        <p>Ao comunicar a lista informará o SLevada & QLCisnes de que a vossa lista de convidados está pronta para encomendas. </p>
        <p> Será com base neste número mínimo de pax que todas as encomendas serão realizadas, não sendo possível a não contabilização de pax em falta a partir deste momento. </p>
      </div>;

    confirm({
      title,
      content,
      okType: 'primary',
      okText: ourGuest?.submittedRoomPlan ? 'Remover comunicação' : 'Comunicar lista',
      cancelText: 'Cancelar',
      onOk: () => this.submitRoomPlan(),
      onCancel: () => null,
    });
  }

  submitRoomPlan = async () => {
    const { match: { params } } = this.props;
    const { ourGuest } = this.state;

    this.setState({
      submiting: true,
      loading: true
    });
    // if (this.roomEditor) {
    //   const valid = this.roomEditor?.valid();
    //   if (valid?.status == 'INVALID') {
    //     Alerts.new({
    //       type: 'warning',
    //       title: 'Atenção',
    //       text: 'Plano de sala inválido! Verifique as mesas!'
    //     });
    //     this.setState({
    //       saving: false,
    //       submiting: false,
    //       loading: false,
    //     });
    //     return;
    //   }
    // const values = this.roomEditor.serializeEditor();
    // const resultSave = await SaveOurGuestsEditor(params.wedding, values);

    // if (resultSave?.success) {
    //   this.setState({
    //     saving: false,
    //     ourGuest: resultSave?.data?.ourGuest || {},
    //     tables: resultSave?.data?.tables || []
    //   });
    // } else {
    //   this.setState({
    //     submiting: false,
    //     loading: false
    //   });
    // }

    const resultSubmit = ourGuest?.submittedRoomPlan
      ? await UndoSubmitRoomPlan(params.wedding)
      : await SubmitRoomPlan(params.wedding);

    if (resultSubmit?.success) {
      Alerts.new({
        type: 'success',
        title: 'Sucesso',
        text: ourGuest?.submittedRoomPlan ? 'Removida a comunicação da lista!' : 'Lista comunicada como concluída!'
      });
      this.setState({
        submiting: false,
        loading: false,
      }, () => this.getInformation());
    } else {
      this.setState({
        submiting: false,
        loading: false,
      }, () => this.getInformation());
    }

  }

  /** Validate guest map and room plan */
  confirmValidatingRoomPlan = () => {
    const { dirtyForm, submittedForm } = this.state;
    const { ourGuest } = this.state;

    if (dirtyForm && !submittedForm) {
      return this.confirmOnSubmit(null, 'VALIDATE_PLAN');
    }

    const title = ourGuest?.validatedRoomPlan
      ? `Tem a certeza que pretende desbloquear o plano de sala?`
      : `Tem a certeza que pretende bloquear o plano de sala?`;
    const content = ourGuest?.validatedRoomPlan
      ? `A partir deste momento será desbloqueada a edição do plano de sala e a distribuição de convidados para o casal e/ou convidado`
      : `A partir deste momento será bloqueada a edição do plano de sala e a distribuição de convidados para o casal e/ou convidado.`;
    confirm({
      title,
      content: (<div>
        <p>{content}</p>
      </div>
      ),
      okType: ourGuest?.validatedRoomPlan ? 'danger' : 'primary',
      okText: ourGuest?.validatedRoomPlan ? 'Desbloquear' : 'Bloquear',
      cancelText: 'Cancelar',
      onOk: () => this.validateRoomPlan(),
      onCancel: () => null,
    });

  }

  validateRoomPlan = async () => {
    const { match: { params } } = this.props;
    const { ourGuest } = this.state;
    this.setState({ validating: true });

    const { success, data } = ourGuest?.validatedRoomPlan
      ? await DisapproveRoomPlan(params.wedding)
      : await ApproveRoomPlan(params.wedding);

    if (success) {
      Alerts.new({
        type: 'success',
        title: 'Sucesso',
        text: ourGuest?.validatedRoomPlan ? 'Desbloqueado a edição dos convidados!' : 'Bloqueada a edição dos convidados!'
      });
      this.setState({ validating: false }, () => this.getInformation());
    } else this.setState({ validating: false })
  }

  // Export Methods
  exportRoomPlan = async () => {
    const { wedding } = this.props;
    if (this.roomEditor) {
      this.setState({ exporting: true });
      // const result = DownloadGuestPlan(wedding.wedding._id);
      const authToken = localStorage.getItem(auth_token_key);
      let filename = 'Distribuição de Convidados e Plano de Sala';

      try {
        const image = await this.roomEditor.getImage();
        const result = await SaveRoomPlanImage(wedding.wedding._id, { image });

        if (result?.success) {
          fetch(
            `${REACT_APP_API}/reports/process/${wedding.wedding._id}/roomPlan`,
            {
              method: 'GET',
              headers: {
                Authorization: `Bearer ${authToken}`
              },
            }
          )
            .then(resp => {
              if (resp.status == 200) {
                const header = resp.headers.get('Content-Disposition');
                filename = header ? header.split('=')[1] : '';
                // Decode filename
                filename = decodeURI(filename)

                return resp.blob().then(blob => {
                  const url = window.URL.createObjectURL(blob);
                  const a = document.createElement('a');
                  a.style.display = 'none';
                  a.href = url;

                  // Correct name for file
                  let name = filename.replace(/#|"/g, '').replace(/_/g, ' ')?.trim().replace('.pdf', '');
                  name.concat('.pdf');

                  a.setAttribute('download', name);
                  document.body.appendChild(a);
                  a.click();
                  window.URL.revokeObjectURL(url);
                  this.setState({ exporting: false });
                });
              } else {
                resp.json().then(obj => {
                  this.setState({ exporting: false });
                  return Alerts.new({
                    type: 'error',
                    title: 'Erro de servidor',
                    text: obj.message
                  });
                });
              }
            })
            .catch(e => {
              this.setState({ exporting: false });
              console.error(e);
            });
        } else {
          this.setState({ exporting: false });
          return Alerts.new({
            type: 'error',
            title: 'Erro de servidor',
            text: result?.data || 'Tente novamente mais tarde.'
          });
        }
      } catch (error) {
        this.setState({ exporting: false });
        return Alerts.new({
          type: 'error',
          title: 'Erro',
          text: 'O editor ainda está a carregar. Aguarde e tente novamente.'
        });
      }

    } else console.error('editor not found');
  }

  uploadRoomPlanToDrive = async () => {
    const { wedding } = this.props;

    if (this.roomEditor) {
      this.setState({
        exporting: true,
        loading: true
      });

      const resultAuthDrive = await AuthGoogleDrive();
      // console.warn('resultAuthDrive', resultAuthDrive);

      if (!resultAuthDrive?.data?.active) {
        localStorage.setItem('googleReturnUrl', window.location.pathname);
        const newWindow = window.open(resultAuthDrive?.data.authUrl, '_self', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null;
        this.setState({
          exporting: false,
          loading: false
        });
        return;
      }

      try {
        const image = await this.roomEditor.getImage();
        const result = await SaveRoomPlanImage(wedding.wedding._id, { image });

        if (result?.success) {
          const resultUpload = await UploadRoomPlanToDrive(wedding.wedding._id, JSON.stringify({}));
          // console.warn('resultUpload', resultUpload);

          if (resultUpload?.success) {
            this.setState({
              exporting: false,
              loading: false
            });
            return Alerts.new({
              type: 'success',
              title: 'Sucesso',
              text: 'Upload com sucesso para o Google Drive!'
            });
          } else {
            this.setState({
              exporting: false,
              loading: false
            });
          }
        } else {
          this.setState({
            exporting: false,
            loading: false
          });
          if (result?.data != 'ROOM_PLAN_NOT_VALIDATED') {
            return Alerts.new({
              type: 'error',
              title: 'Erro de servidor',
              text: result?.data || 'Tente novamente mais tarde.'
            });
          }
        }
      } catch (error) {
        this.setState({
          exporting: false,
          loading: false
        });
        return Alerts.new({
          type: 'error',
          title: 'Erro',
          text: 'O editor ainda está a carregar. Aguarde e tente novamente.'
        });
      }
    } else {
      console.error('editor not found');
    }
  }

  exportGuest = async () => {
    const { wedding } = this.props;
    // const result = DownloadGuestPlan(wedding.wedding._id);
    this.setState({
      exporting: true,
      loading: true
    });
    const authToken = localStorage.getItem(auth_token_key);
    let filename = 'Distribuição de Convidados';

    fetch(
      `${REACT_APP_API}/reports/process/${wedding.wedding._id}/guestPlan?filter=${JSON.stringify({})}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }
    )
      .then(resp => {
        if (resp.status == 200) {
          const header = resp.headers.get('Content-Disposition');
          filename = header ? header.split('=')[1] : '';
          // Decode filename
          filename = decodeURI(filename)

          return resp.blob().then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;

            // Correct name for file
            let name = filename.replace(/#|"/g, '').replace(/_/g, ' ')?.trim().replace('.pdf', '');
            name.concat('.pdf');

            a.setAttribute('download', name);
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            this.setState({
              exporting: false,
              loading: false
            });
          });
        } else {
          resp.json().then(obj => {
            this.setState({
              exporting: false,
              loading: false
            });
            return Alerts.new({
              type: 'error',
              title: 'Erro de servidor',
              text: obj.message
            });
          });
        }
      })
      .catch(e => {
        console.error(e);
        this.setState({
          exporting: false,
          loading: false
        });
      });
  }

  exportFlyers = async () => {
    const { wedding } = this.props;
    this.setState({
      exporting: true,
      loading: true
    });
    // const result = DownloadGuestFlyers(wedding.wedding._id);
    const authToken = localStorage.getItem(auth_token_key);
    let filename = 'Folhetos de Mesa';

    fetch(
      `${REACT_APP_API}/reports/process/${wedding.wedding._id}/guestFlyers?filter=${JSON.stringify({})}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      }
    )
      .then(resp => {
        if (resp.status == 200) {
          const header = resp.headers.get('Content-Disposition');
          filename = header ? header.split('=')[1] : '';
          if (filename.split(';')?.length > 0) {
            filename = filename.split(';')[0];
          }
          // Decode filename
          filename = decodeURI(filename)

          return resp.blob().then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;

            // Correct name for file
            let name = filename.replace(/#|"/g, '').replace('.xls', '');
            name.concat('.xls');

            a.setAttribute('download', name);
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            this.setState({
              exporting: false,
              loading: false
            });
          });
        } else {
          resp.json().then(obj => {
            this.setState({
              exporting: false,
              loading: false
            });
            return Alerts.new({
              type: 'error',
              title: 'Erro de servidor',
              text: obj.message
            });
          });
        }
      })
      .catch(e => {
        console.error(e);
        this.setState({
          exporting: false,
          loading: false
        });
      });
  }

  // uploadFlyersToDrive = async () => {
  //   const { wedding } = this.props;
  //   this.setState({
  //     exporting: true,
  //     loading: true
  //   });

  //   const resultAuthDrive = await AuthGoogleDrive();
  //   // console.warn('resultAuthDrive', resultAuthDrive);

  //   if (!resultAuthDrive?.data?.active) {
  //     localStorage.setItem('googleReturnUrl', window.location.pathname);
  //     const newWindow = window.open(resultAuthDrive?.data.authUrl, '_self', 'noopener,noreferrer')
  //     if (newWindow) newWindow.opener = null;
  //     this.setState({
  //       exporting: false,
  //       loading: false
  //     });
  //     return;
  //   }

  //   const resultUpload = await UploadGuestFlyersToDrive(wedding.wedding._id, JSON.stringify({}));
  //   // console.warn('resultUpload', resultUpload);

  //   if (resultUpload?.success) {
  //     this.setState({
  //       exporting: false,
  //       loading: false
  //     });
  //     return Alerts.new({
  //       type: 'success',
  //       title: 'Sucesso',
  //       text: 'Upload com sucesso para o Google Drive!'
  //     });
  //   } else {
  //     this.setState({
  //       exporting: false,
  //       loading: false
  //     });
  //   }
  // }

  onSelectExportMenu = (value) => {
    const itemSelected = parseInt(value.key);

    // Export room plan
    if (itemSelected === 1) {
      if (this.roomEditor) this.exportRoomPlan();
      else return;
    }
    // Upload room plan
    else if (itemSelected === 2) {
      if (this.roomEditor) this.uploadRoomPlanToDrive();
      else return;
    }
    // Export guest map
    else if (itemSelected === 3) this.exportGuest();
    // Export flyers
    else if (itemSelected === 4) this.exportFlyers();
    // Upload flyers
    // else if (itemSelected === 5) this.uploadFlyersToDrive();
  }

  exportMenu = (
    <Menu onClick={this.onSelectExportMenu}>
      {this.roomEditor && <Menu.Item key={1}>
        <Icon type="download" />
        Dist. Convidados + Plano
      </Menu.Item>}
      {this.roomEditor && <Menu.Item key={2}>
        <Icon type="cloud-upload" />
        Dist. Convidados + Plano
      </Menu.Item>}
      <Menu.Item key={3}>
        <Icon type="download" />
        Dist. Convidados
      </Menu.Item>
      <Menu.Item key={4}>
        <Icon type="download" />
        Folhetos de Mesa
      </Menu.Item>
      {/* <Menu.Item key={5}>
        <Icon type="cloud-upload" />
        Upload Folhetos de Mesa
      </Menu.Item> */}
    </Menu>
  );

  // Couple table 
  openModal = (fromSubmit = false) => {
    const { dispatch } = this.props;
    const { coupleTableQuestion, tableThemeQuestion } = this.state;
    const { dirtyForm, submittedForm } = this.state;

    if (dirtyForm && !submittedForm) {
      return this.confirmOnSubmit(null, 'COUPLE');
    }

    dispatch(initialize('manage_answers_form', {}));

    const question = fromSubmit
      ? tableThemeQuestion
      : coupleTableQuestion;

    if (question?.answer) {
      dispatch(
        initialize('manage_answers_form', {
          ...question?.answer,
          question_obs: question?.answer?.observations !== ''
        })
      );
    }

    this.setState({
      showModal: true,
      questionModal: question,
    });
  };

  onModalSubmit = async (values) => {
    const { wedding } = this.props;
    if (wedding?.wedding?.printed) {
      confirm({
        ...getAnswerPrintedConfirmation(),
        onOk: () => this.confirmSubmitModal(values),
        onCancel: () => this.setState({ showModal: false }),
      });
    } else {
      this.confirmSubmitModal(values);
    }
  };

  confirmSubmitModal = async (values) => {
    const { wedding } = this.props;
    const { tables, questionModal } = this.state;

    if (questionModal?.isTableTheme) {
      this.saveModal(values);
    } else {
      const data = checkCoupleTableAnswer(values, questionModal, wedding.wedding.wedding_place);
      const resultCheck = await CheckUpdateCoupleTable(wedding.wedding._id, data);

      if (resultCheck?.success) {
        if (resultCheck?.data?.showWarning) {
          const ovalLWarning = resultCheck?.data?.showOvalLWarning
            ? resultCheck?.data?.ovalLWarning == 'TO_REMOVE_TABLES_BY_OVALL'
              ? `Serão removidas as mesas ${resultCheck?.data?.tablesToRemove} devido ao espaço ocupado pela nova mesa.`
              : resultCheck?.data?.ovalLWarning == 'TO_RESTORE_TABLES_BY_OVALL'
                ? `Serão recuperadas as mesas ${resultCheck?.data?.tablesToRemove} devido ao espaço ocupado pela nova mesa.`
                : null
            : null;

          confirm({
            title: 'Têm a certeza que pretende alterar a mesa do casal?',
            content: (<div>
              <p>A nova mesa não têm o mesmo número de lugares, por isso serão removidos os convidados.</p>
              {resultCheck?.data?.showOvalLWarning && ovalLWarning && <p><b>{ovalLWarning}</b></p>}
            </div>
            ),
            okText: 'Alterar mesa',
            cancelText: 'Cancelar',
            onOk: () => this.saveModal(values),
            onCancel: () => this.cancelModal(),
          });
        } else this.saveModal(values);
      } else {
        this.cancelModal();
      }
    }
  }

  cancelModal = () => {
    this.setState({
      questionModal: null,
      showModal: false,
    });
  }

  saveModal = async (values) => {
    const { wedding, dispatch } = this.props;
    const { questionModal, sectionDecor, objectTypes } = this.state;

    this.setState({ savingModal: true });

    values = removeNotExistingOptions(values, questionModal, wedding?.wedding?.wedding_place);
    values = correctResponseValues(values);
    const payload = FlattenToFormData({ ...values, question: questionModal._id });

    const { data, success } = await AnswerQuestion(wedding.wedding._id, payload);

    if (success) {
      dispatch(updateQuestionAction(sectionDecor.tag, data));
      this.setState({
        savingModal: false,
        showModal: false,
        questionModal: null
      }, () => {
        if (questionModal?.isTableTheme) {
          this.confirmSubmit();
        } else {
          this.getInformation();
        }
      });
    } else {
      this.setState({
        savingModal: false,
        showModal: false,
        questionModal: null
      }, () => this.getInformation());
    }
  };

  // Notes
  openNotes = () => {
    const { wedding, dispatch } = this.props;
    const { openDrawer } = this.state;
    if (!openDrawer) {
      const notes = wedding.notes.find(note => note.section === 'GUESTS');
      dispatch(initialize('wedding_notes_section_form', notes));
      this.setState({ openDrawer: true });
    }
  };

  closeNotes = () => {
    const { wedding, dispatch } = this.props;
    const notes = wedding.notes.find(note => note.section === 'GUESTS');
    dispatch(initialize('wedding_notes_section_form', notes));
    this.setState({ openDrawer: false, openNotesConfirm: false });
  };

  checkBeforeCloseNotes = (values) => {
    const { wedding, dispatch } = this.props;
    const notes = wedding.notes.find(note => note.section === 'GUESTS');

    //If theres any change to the notes, ask before closing if we want to save the changes
    if (values && (notes?.notes !== values.notes || notes?.private !== values.private)) {
      dispatch(initialize('notes_modal_confirm_form', values));
      this.setState({ openNotesConfirm: true });
    }
    //Otherwise, just the close the notes
    else {
      this.setState({ openDrawer: false });
    }
  }

  saveNotes = async values => {
    const { wedding } = this.props;

    if (wedding?.wedding?.printed) {
      confirm({
        ...getPrintedConfirmation(),
        onOk: () => { this.updateNotes(values); },
        onCancel: () => { this.closeNotes(); },
      });
    } else this.updateNotes(values);
  };

  updateNotes = async values => {
    const { wedding, saveNotesOnReducer, dispatch } = this.props;
    this.setState({ loadNotes: true });
    const { data } = await SaveSectionNotes(wedding.wedding._id, {
      ...values,
      section: 'GUESTS'
    });

    dispatch(initialize('wedding_notes_section_form', data));
    saveNotesOnReducer(data);
    this.setState({ loadNotes: false, openDrawer: false, openNotesConfirm: false });
  };

  handleOnCancel = async () => {
    try {
      const {
        match: { params },
        history
      } = this.props;
      return history.push(`/wedding/${params.wedding}/guests`);
    } catch (e) {
      console.error(e);
    }
  };

  // Tables
  reducerTables = (action) => {
    // console.log("reducerTables", action);
    let { tables } = this.state;

    switch (action.type) {
      case 'UPDATE_TABLE':
        const updatedTables = [...tables];
        const indexTable = tables?.findIndex(f => f?.code == action.code);
        if (indexTable > -1) {
          updatedTables[indexTable] = action.value;
          this.setState({ tables: updatedTables, dirtyForm: true });
        }
        return updatedTables;
      case 'UPDATE_TABLE_NAME':
        const updatedTablesName = [...tables];
        const indexTableName = tables?.findIndex(f => f?.code == action.code);
        if (indexTableName > -1) {
          updatedTablesName[indexTableName] = { ...tables[indexTableName], name: action.value };
          this.setState({ tables: updatedTablesName, dirtyForm: true });
        }
        return updatedTablesName;
      case 'UPDATE_TABLE_PURPOSE':
        const updatedTablesPurpose = [...tables];
        const indexTablePurpose = tables?.findIndex(f => f?.code == action.code);
        if (indexTablePurpose > -1) {
          updatedTablesPurpose[indexTablePurpose] = { ...tables[indexTablePurpose], tablePurpose: action.value };
          this.setState({ tables: updatedTablesPurpose, dirtyForm: true });
        }
        return updatedTablesPurpose;
      case 'UPDATE_SEATS_FRONT_COUPLE':
        const updatedSeatsFrontCouples = [...tables];
        const indexSeatFrontCouple = tables?.findIndex(f => f?.code == action.code);
        if (indexSeatFrontCouple > -1) {
          updatedSeatsFrontCouples[indexSeatFrontCouple] = { ...tables[indexSeatFrontCouple], seatsFrontCouple: action.value };
          this.setState({ tables: updatedSeatsFrontCouples, dirtyForm: true });
        }
        return updatedSeatsFrontCouples;
      case 'UPDATE_SEAT_NAME':
        const updatedTablesSeatName = [...tables];
        const indexTableSeatName = tables?.findIndex(f => f?.code == action.code);
        if (indexTableSeatName > -1) {
          const tableSeat = { ...tables[indexTableSeatName] };
          tableSeat.seats[action.seatIndex].guestName = action.value;
          updatedTablesSeatName[indexTableSeatName] = tableSeat;
          this.setState({ tables: updatedTablesSeatName, dirtyForm: true });
        }
        return updatedTablesSeatName;
      case 'ADD_TABLE_SEATS':
        const addTablesSeat = [...tables];
        const indexTableAddSeat = tables?.findIndex(f => f?.code == action.code);
        if (indexTableAddSeat > -1) {
          const tableAddSeat = { ...tables[indexTableAddSeat] };
          tableAddSeat.seats.push(action.value);
          addTablesSeat[indexTableAddSeat] = tableAddSeat;
          this.setState({ tables: addTablesSeat, dirtyForm: true });
        }
        return addTablesSeat;
      case 'UPDATE_SEATS':
        const updatedTablesSeatsAll = [...tables];
        const indexTableSeats = tables?.findIndex(f => f?.code == action.code);
        if (indexTableSeats > -1) {
          updatedTablesSeatsAll[indexTableSeats] = { ...tables[indexTableSeats], seats: action.value };
          this.setState({ tables: updatedTablesSeatsAll, dirtyForm: true });
        }
        return updatedTablesSeatsAll;
      case 'UPDATE_SEAT':
        const updatedTablesSeats = [...tables];
        const indexTableSeat = tables?.findIndex(f => f?.code == action.code);
        if (indexTableSeat > -1) {
          updatedTablesSeats[indexTableSeat].seats[action.seatIndex] = { ...action.value };
          this.setState({ tables: updatedTablesSeats, dirtyForm: true });
        }
        return updatedTablesSeats;
      case 'REMOVE_SEAT':
        const removedTablesSeats = [...tables];
        const indexTableRSeat = tables?.findIndex(f => f?.code == action.code);
        if (indexTableRSeat > -1) {
          removedTablesSeats[indexTableRSeat].seats.splice(action.seatIndex, 1);
          this.setState({ tables: removedTablesSeats, dirtyForm: true });
        }
        return removedTablesSeats;
      default:
        return tables;
    }
  }

  selectToChangeGuests = (code, table) => {
    const { tables } = this.state;

    const selectedToChange = tables?.find(f => f?.active && !f?.removeBecauseOfOvalL && f?.isSelectedToChange);
    if (selectedToChange) {
      const canChangeTables = canChangeSeats(selectedToChange, table);
      if (canChangeTables) {
        confirm({
          title: 'Têm a certeza que pretende trocar os convidados de mesa?',
          content: (
            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
              <TableGuestsModal>
                <TableGuestsHeader modal={true}>
                  <TableSeatCode seat={false} couple={false}>{convertNumberToLetter(selectedToChange?.code || 0) || ''}</TableSeatCode>
                  <TableName>{selectedToChange?.name}</TableName>
                </TableGuestsHeader>
                {selectedToChange?.seats?.map((m, index) => (
                  <SeatRow key={index} disabled={false} modal={true}>
                    <TableSeatCode modal={true} couple={false} seat={true}>{(m?.number || 0) + 1 || ''}</TableSeatCode>
                    <SeatName modal={true}>{m?.guestName}</SeatName>
                  </SeatRow>
                ))}
              </TableGuestsModal>
              <Icon type="swap" style={{ fontSize: 25, margin: 'auto 8px' }} />
              <TableGuestsModal>
                <TableGuestsHeader modal={true}>
                  <TableSeatCode seat={false} couple={false}>{convertNumberToLetter(table?.code || 0) || ''}</TableSeatCode>
                  <TableName>{table?.name}</TableName>
                </TableGuestsHeader>
                {table?.seats?.map((m, index) => (
                  <SeatRow key={index} disabled={false} modal={true}>
                    <TableSeatCode modal={true} couple={false} seat={true}>{(m?.number || 0) + 1 || ''}</TableSeatCode>
                    <SeatName modal={true}>{m?.guestName}</SeatName>
                  </SeatRow>
                ))}
              </TableGuestsModal>
            </div>
          ),
          className: 'change-guests-modal',
          okType: 'primary',
          okText: 'Trocar convidados',
          cancelText: 'Cancelar',
          onOk: () => this.changeGuests(selectedToChange, code),
          onCancel: () => this.cancelChangeGuests(),
        });
      } else {
        Alerts.new({
          type: 'error',
          title: 'Erro',
          text: 'Não existem lugares suficientes para trocar os convidados de mesa.'
        });
        this.cancelChangeGuests();
      }

    } else {
      const updatedTables = [...tables];
      const index = updatedTables.findIndex(f => f?.code == table?.code);
      const updatedTable = { ...tables[index] };
      updatedTable.isSelectedToChange = !table?.isSelectedToChange;
      this.reducerTables({
        type: 'UPDATE_TABLE',
        index,
        value: updatedTable
      });
      updatedTables.splice(index, 1, updatedTable);
      this.setState({ tables: updatedTables, dirtyForm: true });
    }
  }

  cancelChangeGuests = () => {
    const { tables } = this.state;
    let updatedTables = [...tables];
    const selectedTablesToChange = tables?.filter(f => f?.isSelectedToChange);

    for (let i = 0; i < selectedTablesToChange.length; i++) {
      const selectedTable = selectedTablesToChange[i];
      const index = tables.findIndex(f => f?.code == selectedTable?.code);
      if (index > -1) {
        const tableToUnselect = { ...selectedTable };
        tableToUnselect.isSelectedToChange = false;
        updatedTables.splice(index, 1, tableToUnselect);
      }
    }

    this.setState({ tables: updatedTables, dirtyForm: true });
  }

  changeGuests = (selectedTable, code) => {
    const { tables } = this.state;
    const updatedTables = [...tables];
    const indexTable = tables.findIndex(f => f?.code == code);
    const table = { ...tables[indexTable] };
    const indexSelected = tables.findIndex(f => f?.code == selectedTable?.code);
    const selectedTableToChange = { ...tables[indexSelected] };

    const auxTable = JSON.parse(JSON.stringify(table));

    for (let index = 0; index < table?.seats.length; index++) {
      const newSeat = selectedTableToChange?.seats?.[index]
        ? selectedTableToChange?.seats[index]
        : null;
      table.seats[index] = newSeat
        ? newSeat
        : {
          code: index,
          number: index,
          couple: false,
          guestName: '',
          guestAge: GuestAgeEnum.ADULT,
          foodRestrictions: []
        };
    }
    updatedTables.splice(indexTable, 1, { ...table, name: selectedTableToChange?.name });

    for (let index = 0; index < selectedTableToChange?.seats.length; index++) {
      const newSeat = auxTable?.seats?.[index]
        ? auxTable?.seats[index]
        : null;
      selectedTableToChange.seats[index] = newSeat
        ? newSeat
        : {
          code: index,
          number: index,
          couple: false,
          guestName: '',
          guestAge: GuestAgeEnum.ADULT,
          foodRestrictions: []
        };
    }
    updatedTables.splice(indexSelected, 1, { ...selectedTableToChange, name: auxTable?.name });

    this.setState({ tables: updatedTables, dirtyForm: true }, () => {
      this.cancelChangeGuests();
    });
  }

  activateTable = (code) => {
    const { tables } = this.state;
    let updatedTables = [...tables];
    const index = tables.findIndex(f => f?.code == code);
    const tableToActive = { ...tables[index] };
    tableToActive.active = !tableToActive.active;

    if (!tableToActive?.active) {
      tableToActive.name = '';
      tableToActive.seats = tableToActive?.seats?.map(m => ({
        number: m?.number,
        guestName: '',
        guestAge: GuestAgeEnum.ADULT,
        foodRestrictions: []
      }));
    }

    updatedTables.splice(index, 1, tableToActive);

    // Only show cost extra warning when activating table
    if (tableToActive?.active) {
      const extraTables = checkExtraCostByTables(updatedTables);
      if (extraTables > 0) {
        Alerts.new({
          type: 'warning',
          title: 'Atenção',
          text: 'A mesa ativada poderá implicar um custo extra.'
        });
      }
    }

    if (this.roomEditor) {
      if (tableToActive?.active) this.roomEditor.enableTableByCode(tableToActive.code)
      else this.roomEditor.disableTableByCode(tableToActive.code);
    }

    this.setState({ tables: updatedTables, dirtyForm: true });
  }

  removeTable = (code) => {
    const { tables } = this.state;
    let removedTables = [...tables];
    removedTables = removedTables.filter(f => f?.code != code);
    removedTables = [...removedTables];
    this.setState({ tables: removedTables, dirtyForm: true });
  }

  changeTablePurpose = (purpose, code) => {
    const { tables } = this.state;

    if (purpose == TablePurposeEnum.STAFF) {
      const index = tables?.findIndex(f => f?.code == code);
      if (index < -1) return;
      const table = tables[index];
      const existStaff = tables.find(f => f?.tablePurpose == TablePurposeEnum.STAFF && f?.code !== table?.code) ? true : false;;
      if (existStaff) {
        Alerts.new({
          type: 'warning',
          title: 'Atenção',
          text: 'Apenas pode existir 1 mesa de staff!'
        });
      }

      this.reducerTables({
        type: 'UPDATE_TABLE_PURPOSE',
        code,
        value: tables[index]?.tablePurpose || TablePurposeEnum.GUEST
      });
    }
  }

  openImportModal = (code) => {
    this.setState({
      showImportModal: true,
      importModalTable: {
        code,
        names: ''
      }
    });
  }

  submitImportModal = (value) => {
    let { tables } = this.state;
    this.setState({ importingGuests: true });

    const indexTable = tables?.findIndex(f => f?.code == value.code);
    if (indexTable > -1) {
      const updatedTables = [...tables];
      const tableToUpdate = tables[indexTable];

      const namesToImport = value.names.split(/\r?\n/) || [];

      if (tableToUpdate?.tablePurpose != TablePurposeEnum.STAFF) {
        let seatsToCheck = [...tableToUpdate?.seats];
        if (!tableToUpdate?.seatsFrontCouple) {
          seatsToCheck = seatsToCheck.filter(f => !f?.frontCouple);
        }

        if (seatsToCheck?.length < namesToImport?.length) {
          Alerts.new({
            type: 'error',
            title: 'Erro',
            text: 'Não existem lugares suficientes!'
          });
          this.setState({
            showImportModal: false,
            importingGuests: false,
            importModalTable: null
          });
          return;
        } else {
          tableToUpdate.seats = tableToUpdate.seats.map(m => ({ ...m, guestName: '' }));
          for (let index = 0; index < namesToImport.length; index++) {
            const name = namesToImport[index];
            const emptySeatIndex = tableToUpdate?.seatsFrontCouple
              ? tableToUpdate?.seats.findIndex(f => f?.guestName.trim() == '')
              : tableToUpdate?.seats?.findIndex(f => !f?.frontCouple && f?.guestName.trim() == '');
            if (emptySeatIndex > -1) {
              tableToUpdate.seats[emptySeatIndex].guestName = name;
              tableToUpdate.seats[emptySeatIndex].guestAge = GuestAgeEnum.ADULT;
              tableToUpdate.seats[emptySeatIndex].foodRestrictions = [];
            }
          }
        }
      } else {
        tableToUpdate.seats = tableToUpdate.seats.map(m => ({ ...m, guestName: '' }));
        for (let index = 0; index < namesToImport.length; index++) {
          const name = namesToImport[index];
          const emptySeatIndex = tableToUpdate?.seats.findIndex(f => f?.guestName.trim() == '');
          if (emptySeatIndex > -1) {
            tableToUpdate.seats[emptySeatIndex].guestName = name;
            tableToUpdate.seats[emptySeatIndex].guestAge = GuestAgeEnum.ADULT;
            tableToUpdate.seats[emptySeatIndex].foodRestrictions = [];
          } else {
            tableToUpdate.push({
              number: tableToUpdate?.seats?.length,
              couple: false,
              guestName: name,
              guestAge: GuestAgeEnum.ADULT,
              foodRestrictions: []
            });
          }
        }
      }

      updatedTables[indexTable] = { ...tableToUpdate };
      this.setState({
        tables: updatedTables,
        dirtyForm: true,
        showImportModal: false,
        importingGuests: false,
        importModalTable: null
      }, () => {
        return Alerts.new({
          type: 'success',
          title: 'Sucesso',
          text: 'Convidados importados com sucesso!'
        });
      });
    } else {
      this.setState({
        showImportModal: false,
        importingGuests: false,
        importModalTable: null
      });
      return;
    }
  }

  // Room plan
  goToRoomPlan = () => {
    const { match: { params }, history } = this.props;
    history.push(`/wedding/${params.wedding}/roomPlan`);
  };

  render() {
    const { wedding, tablesForm } = this.props;
    const { openDrawer, loadNotes, openNotesConfirm } = this.state;
    const { ourGuest, foodRestrictions, tables, objectTypes, roomPlanOptions } = this.state;
    const { currentChapter } = this.state;
    const { notesActiveTab, notes, kitchenNotes, invalidKitchenNotes } = this.state;
    const { exporting, changingPlan, activating, validating, submitting } = this.state;
    const { showModal, questionModal, savingModal } = this.state;
    const { loading, ready, saving, dirtyForm, submittedForm } = this.state;
    const { changedPlan } = this.state;
    const { showImportModal, importingGuests, importModalTable } = this.state;
    const countBtns = wedding?.wedding?.printed ? 3 : 2;
    const hasExtraCost = checkExtraCostByTables(tables) > 0 ? true : false;
    const extraTables = calculateExtraTables(tables);
    // loading || exporting || changingPlan || activating || validating || submitting || saving

    // if (!ready) return <SpinLoading />;
    return (
      <Fragment>
        <XpertGoLeavePageBlocker
          when={dirtyForm && !submittedForm}
          message={'Pretende sair sem guardar as alterações?'}
        />
        <HeaderContainer>
          <HeaderTitle buttons={countBtns}>
            <PageTitle>{currentChapter?.name?.pt || 'Distribuição de convidados'}</PageTitle>
            {wedding?.wedding?.printed && <PrintedMessage>Processo impresso</PrintedMessage>}
          </HeaderTitle>
          <HeaderButtonsContainer buttons={countBtns}>
            {/* {wedding?.wedding?.printed && <PrintedBarContent small={true} btn={true}>
              <div className="row-btn">
                <Icon type="warning" />
                Processo impresso
              </div>
            </PrintedBarContent>} */}
            {ourGuest?.validatedRoomPlan && <PrintedBarContent small={true} btn={true}>
              <div className="row-btn">
                <Icon type="warning" />
                Lista de convidados encerrada
              </div>
            </PrintedBarContent>}
            <BaseButton
              type={'default'}
              icon={'read'}
              text={'Notas'}
              onClick={this.openNotes}
              notes={hasNotes(wedding?.notes, 'GUESTS')}
            />
            <BaseButton
              type="default"
              icon="arrow-left"
              text="Voltar"
              onClick={this.handleOnCancel}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        {/* {(wedding?.wedding?.printed || true) && <PrintedBar small={true} />} */}
        <DecorContainer containerId="guest-map" printed={false}>
          <BackTop />
          <GuestMapContentStyle printed={ourGuest?.validatedRoomPlan} notesOpen={openDrawer}>
            {loading && <SpinLoading />}
            <BaseForm onSubmit={(e) => this.confirmOnSubmit(e)} style={{ display: loading ? 'none' : 'block' }}>
              <Row gutter={[12, 12]} style={{ display: 'flex', alignContent: 'stretch', flexWrap: 'wrap' }}>
                <Col xs={24} md={24} lg={12} xl={12} xxl={12}>
                  <Toolbar>
                    <div className='toolbar-input'>
                      <Dropdown disabled={loading || changingPlan || activating || validating || submitting || saving}
                        overlay={this.exportMenu}>
                        <Button type='default'>
                          {exporting ? <Icon type="loading" /> : <Icon type="file-protect" />}
                          Exportar
                          <Icon type="down" />
                        </Button>
                      </Dropdown>
                    </div>
                    <div className='toolbar-input' style={{ width: 170 }}>
                      <SelectInput
                        // label="Tamanho:"
                        horizontal={true}
                        allowClear={false}
                        placeholder="Escolher tamanho"
                        data={roomPlanOptions}
                        disabled={loading || exporting || changingPlan || activating || validating || submitting || saving}
                        input={{
                          value: ourGuest?.roomPlanTemplate,
                          onChange: value => this.confirmChangeRoomPlan(value)
                        }}
                        costumeLabel={this.costumeSelectRoomPlan}
                        meta={{ valid: true }}
                      />
                    </div>
                  </Toolbar>
                </Col>
                <Col xs={24} md={24} lg={12} xl={12} xxl={12}>
                  <Toolbar right={true}>
                    <div className='toolbar-input'>
                      <BaseButton
                        type={'default'}
                        text={'Plano de Sala'}
                        onClick={() => this.goToRoomPlan()}
                      />
                    </div>
                    {!ourGuest?.roomPlanActive && <div className='toolbar-input'>
                      <BaseButton
                        type={'default'}
                        icon={'eye'}
                        text={'Ativar'}
                        loading={activating}
                        disabled={!ourGuest || loading || exporting || changingPlan || validating || submitting || saving}
                        onClick={() => this.confirmActivateRoomPlan()}
                      />
                    </div>}
                    <div className='toolbar-input'>
                      <BaseButton
                        type={'default'}
                        icon={'mail'}
                        text={ourGuest?.submittedRoomPlan ? 'Remover comunicar lista' : 'Comunicar lista'}
                        loading={submitting}
                        disabled={loading || exporting || changingPlan || activating || validating || saving}
                        onClick={this.checkSubmit}
                      />
                    </div>
                    <div className='toolbar-input'>
                      {/* <BaseButton
                        type={ourGuest?.validatedRoomPlan ? 'danger' : 'success'}
                        icon={ourGuest?.validatedRoomPlan ? 'close' : 'check'}
                        filled={true}
                        text={ourGuest?.validatedRoomPlan ? 'Reprovar' : 'Aprovar'}
                        loading={validating}
                        disabled={loading || saving || exporting}
                        onClick={this.confirmValidatingRoomPlan}
                      /> */}
                      <BaseButton
                        type={ourGuest?.validatedRoomPlan ? 'danger' : 'default'}
                        disabled={loading || exporting || changingPlan || activating || submitting || saving}
                        onClick={this.confirmValidatingRoomPlan}
                        ghost={ourGuest?.validatedRoomPlan ? true : false}
                        text={<div className="svg-input">
                          {validating ? <Icon type="loading" /> : <ReactSVG className="svg-icon" src={ourGuest?.validatedRoomPlan ? PencilBlockedIcon : PencilIcon} />}
                          <span style={{ marginLeft: 4 }}>{ourGuest?.validatedRoomPlan ? 'Desbloquear' : 'Bloquear'}</span>
                        </div>}
                      />
                    </div>
                    <div className='toolbar-input'>
                      <BaseButton
                        htmlType="submit"
                        type={'primary'}
                        icon={'save'}
                        text={'Guardar'}
                        loading={saving}
                        disabled={loading || exporting || changingPlan || activating || validating || submitting}
                      />
                    </div>
                    {/* <div className='toolbar-input'>
                  <Tooltip title="Reprovar plano">
                    <BaseButton
                      type={'danger'}
                      icon={'close'}
                      filled={true}
                      // text={'Reprovar'}
                      loading={disapproving}
                      disabled={!ourGuest?.submittedRoomPlan || loading || saving || submiting || approving || exporting}
                      onClick={this.disapproveRoomPlan}
                    />
                  </Tooltip>
                </div> */}
                  </Toolbar>
                </Col>
                <Col xs={24}>
                  <Tooltip title={this.getChefFoodRestrictions()}>
                    <Icon type="info-circle" style={{ marginRight: 5 }} />
                    As alterações de ingredientes em caso de restrições alimentares ficarão a cargo do chefe.
                  </Tooltip>
                </Col>
                <Col xs={24} sm={24} md={5} lg={5} xl={5} style={{ minHeight: '100%', display: 'flex', flexDirection: 'column' }}>
                  <RoomEditorContent noBorder={true}>
                    <Tabs type="card" onChange={this.changeNotesActiveTab} activeKey={notesActiveTab}>
                      <TabPane tab={
                        <TabName hasNotes={kitchenNotes?.trim() !== ''} selected={notesActiveTab == 1}>Notas Cozinha</TabName>
                      } key="1">
                        <TextAreaInput
                          // label={'Notas'}
                          placeholder={'Escreva aqui as suas notas para a cozinha'}
                          guestMap={true}
                          fillError={true}
                          noLabel={true}
                          minRows={6}
                          meta={{ invalid: invalidKitchenNotes, submitFailed: invalidKitchenNotes }}
                          input={{
                            value: kitchenNotes,
                            onChange: this.handleKitchenNotesChange
                          }}
                        />
                      </TabPane>
                      <TabPane tab={
                        <TabName hasNotes={notes?.trim() !== ''} selected={notesActiveTab == 2}>Notas</TabName>
                      } key="2">
                        <TextAreaInput
                          // label={'Notas'}
                          placeholder={'Escreva aqui as suas notas sobre os convidados'}
                          guestMap={true}
                          noLabel={true}
                          minRows={6}
                          meta={{ invalid: false, submitFailed: false }}
                          input={{
                            value: notes,
                            onChange: this.handleNotesChange
                          }}
                        />
                      </TabPane>
                    </Tabs>
                  </RoomEditorContent>
                </Col>
                <Col xs={24} sm={24} md={5} lg={5} xl={5} style={{ minHeight: '100%', display: 'flex', flexDirection: 'column' }}>
                  <RoomEditorContent>
                    <h3 className="title">Contagem automática</h3>
                    <span className="text">Lista de restrições alimentares</span>
                    <div className="info-content" dangerouslySetInnerHTML={{
                      __html: this.getFoodRestrictionsStats(),
                    }}>
                    </div>
                  </RoomEditorContent>
                </Col>
                <Col xs={24} sm={24} md={5} lg={5} xl={5} style={{ minHeight: '100%', display: 'flex' }}>
                  <RoomEditorContent noBorder={true}>
                    <Tabs type="card" defaultActiveKey="1">
                      <TabPane tab="Contagem automática" key="1">
                        <GuestMapStats inTabs={true} avgError={(this.getAvgPaxTables() < 10) ? true : false}>
                          {/* <h3 className="title">Contagem automática</h3> */}
                          <span className="text">A contagem mínima enviada até 15 dias antes será a contagem a ter em consideração.</span>
                          <StatsContentRow global={true}>
                            <div className='stats-description'>Maior de 8 anos</div>
                            <div className='stats-value'>{this.getStats(StatsEnum.ADULT)}</div>
                          </StatsContentRow>
                          <StatsContentRow global={true}>
                            <div className='stats-description'>Dos 3 aos 7 anos</div>
                            <div className='stats-value'>{this.getStats(StatsEnum.CHILD)}</div>
                          </StatsContentRow>
                          <StatsContentRow global={true}>
                            <div className='stats-description'>Dos 0 a 2 anos</div>
                            <div className='stats-value'>{this.getStats(StatsEnum.NEWBORN)}</div>
                          </StatsContentRow>
                          <StatsContentRow global={true}>
                            <div className='stats-description'>Staff</div>
                            <div className='stats-value'>{this.getStats(StatsEnum.STAFF)}</div>
                          </StatsContentRow>
                          <StatsContentRow global={true}>
                            <div className='stats-description'>Total mesas</div>
                            <div className='stats-value'>{this.getStats(StatsEnum.TABLES)}</div>
                          </StatsContentRow>
                          <StatsContentRow global={true}>
                            <div className='stats-description'>Total convidados</div>
                            <div className='stats-value'>{this.getStats(StatsEnum.GUESTS)}</div>
                          </StatsContentRow>
                          <StatsContentRow global={true}>
                            <div className='stats-description'>Média pessoas/mesa</div>
                            <div className='stats-value avg-value'>{this.getAvgPaxTables()?.toFixed(2)}</div>
                          </StatsContentRow>
                          <StatsContentRow global={true}>
                            <div className='stats-description'>PAX total</div>
                            <div className='stats-value total-pax'>{this.getPax()?.toFixed(2)}</div>
                          </StatsContentRow>
                        </GuestMapStats>
                      </TabPane>
                      <TabPane tab="Contagem referência" key="2">
                        <GuestMapStats inTabs={true} avgError={(((ourGuest?.stats?.guests || 0) + (ourGuest?.stats?.children || 0) + (ourGuest?.stats?.babies || 0) / (ourGuest?.stats?.tables || 0) || 0) < 10) ? true : false}>
                          {/* <h3 className="title">Contagem para referência</h3> */}
                          {/* <span className="text">A contagem a ter em consideração</span> */}
                          {ourGuest?.stats
                            ? <React.Fragment>
                              <StatsContentRow global={true}>
                                <div className='stats-description'>Maior de 8 anos</div>
                                <div className='stats-value'>{ourGuest?.stats?.guests || 0}</div>
                              </StatsContentRow>
                              <StatsContentRow global={true}>
                                <div className='stats-description'>Dos 3 aos 7 anos</div>
                                <div className='stats-value'>{ourGuest?.stats?.children || 0}</div>
                              </StatsContentRow>
                              <StatsContentRow global={true}>
                                <div className='stats-description'>Dos 0 a 2 anos</div>
                                <div className='stats-value'>{ourGuest?.stats?.babies || 0}</div>
                              </StatsContentRow>
                              <StatsContentRow global={true}>
                                <div className='stats-description'>Staff</div>
                                <div className='stats-value'>{ourGuest?.stats?.staff || 0}</div>
                              </StatsContentRow>
                              <StatsContentRow global={true}>
                                <div className='stats-description'>Total mesas</div>
                                <div className='stats-value'>{ourGuest?.stats?.tables || 0}</div>
                              </StatsContentRow>
                              <StatsContentRow global={true}>
                                <div className='stats-description'>Total convidados</div>
                                <div className='stats-value'>{(ourGuest?.stats?.guests || 0) + (ourGuest?.stats?.children || 0) + (ourGuest?.stats?.babies || 0)}</div>
                              </StatsContentRow>
                              <StatsContentRow global={true}>
                                <div className='stats-description'>Média pessoas/mesa</div>
                                <div className='stats-value avg-value'>{(((ourGuest?.stats?.guests || 0) + (ourGuest?.stats?.children || 0) + (ourGuest?.stats?.babies || 0)) / (ourGuest?.stats?.tables || 0) || 0)?.toFixed(2)}</div>
                              </StatsContentRow>
                              <StatsContentRow global={true}>
                                <div className='stats-description'>PAX total</div>
                                <div className='stats-value total-pax'>{ourGuest?.stats?.pax || 0}</div>
                              </StatsContentRow>
                            </React.Fragment>
                            : <span>Sem dados para referência</span>}
                        </GuestMapStats>
                      </TabPane>
                    </Tabs>
                  </RoomEditorContent>
                </Col>
                <Col xs={24} sm={24} md={4} lg={4} xl={4} style={{ minHeight: '100%', display: 'flex' }}>
                  {hasExtraCost && <CostExtraMessage>
                    <img src={CostExtraIcon} alt="icon" />
                    <div className="content">
                      <p className="title">Média de pessoas por mesa inferior a 10</p>
                      {/* <p>Isto irá implicar um custo extra por cada mesa adicionada depois do total de 14 mesas ser ultrapassado.</p> */}
                      <p><b>Custo extra a ser aplicado:</b> <Tag color="gold">{extraTables} mesas</Tag></p>
                    </div>
                  </CostExtraMessage>}
                  {/* <RoomEditorContent>
                    <h3 className="title">Plano de Sala</h3>
                    <span className="text">Posição das mesas no plano de sala.</span>
                    <RoomEditorContainer guestMap={true}>
                      {(loading || !ready) && <div className='backdrop'>
                        <SpinLoading style={{ position: 'absolute', minHeight: 30 }} />
                      </div>}
                      <RoomEditor guestMap={true} id='editor'></RoomEditor>
                    </RoomEditorContainer>
                  </RoomEditorContent> */}
                </Col>
                <Col xs={24} sm={24} md={5} lg={5} xl={5} style={{ minHeight: '100%', display: 'flex' }}>
                  <RoomEditorContent>
                    <h3 className="title">Mesas do plano</h3>
                    <div className="tables-search-content">
                      {tables.filter(f => !f?.removeBecauseOfOvalL)?.map((m, index) => (
                        <Link key={index} to={`anchor-${m?.code}`} isDynamic={true} spy={true} smooth={'easeInOutQuint'} offset={-140}>
                          <TableSearchCode active={m?.active}>{convertNumberToLetter(m?.code)}</TableSearchCode>
                        </Link>
                      ))}
                    </div>
                  </RoomEditorContent>
                </Col>
                <Col xs={24}>
                  <GuestTablesComponent
                    wedding={wedding}
                    tables={tables}
                    objectTypes={objectTypes}
                    foodRestrictions={foodRestrictions}
                    parentActiveTable={this.activateTable}
                    parentDeleteTable={this.removeTable}
                    editTableCouple={this.openModal}
                    updateTable={(action) => this.reducerTables(action)}
                    changeTablePurpose={this.changeTablePurpose}
                    parentSelectToChangeGuests={this.selectToChangeGuests}
                    parentCancelChangeGuests={this.cancelChangeGuests}
                    parentOpenImportModal={this.openImportModal} />
                </Col>
              </Row>
            </BaseForm>
          </GuestMapContentStyle>

          <CommentsDrawerFixed
            open={openDrawer}
            loading={loadNotes}
            onClose={this.checkBeforeCloseNotes}
            saveNotes={this.saveNotes}
            printed={wedding?.wedding?.printed}
          />
        </DecorContainer>
        <NotesConfirmModal
          open={openNotesConfirm}
          loading={loadNotes}
          onSubmit={this.saveNotes}
          closeModal={() => { this.setState({ openNotesConfirm: false }) }}
          exitWithoutSaving={this.closeNotes}
        />
        {showModal &&
          <SectionModal
            open={showModal}
            loading={savingModal}
            onSubmit={this.onModalSubmit}
            closeModal={this.cancelModal}
            question={questionModal}
          />
        }

        {showImportModal &&
          <ImportGuestModal
            isOpen={showImportModal}
            loading={importingGuests}
            initialValues={importModalTable}
            onSubmit={this.submitImportModal}
            onClose={() => this.setState({ showImportModal: false, importModalTable: null })}
          />}
      </Fragment>
    );
  }
}


const mapStateToProps = state => ({
  structureInfo: state.wedding.structure,
  wedding: state.wedding,
  notes: state?.wedding?.notes,
});

const mapActionToProps = dispatch =>
  bindActionCreators({ saveNotesOnReducer: SaveNotes, updateQuestionAction, dispatch }, dispatch);

export default withLocalize(connect(mapStateToProps, mapActionToProps)(ManageWeddingGuestMapPage));
