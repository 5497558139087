import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import TranslateValue from '../../../infra/services/translations/TranslateValue';
import CurrencyComponent from '../../../components/currency/CurrencyComponent';
import { ResponsePill, ExtraPill } from '../SectionStyles';
import { checkExtraCost, checkSidedishesOriginal, getEndSidedishesName, getFoodExtraCost, getPlateSidedishesName } from '../../../infra/services/food/SidedishesUtils';
import { isString } from './OptionUtils';
import { Icon } from 'antd';

const foodType = type => {
  switch (type) {
    case 12:
      return 'entrees';
    case 13:
      return 'meat';
    case 14:
      return 'fish';
    case 15:
      return 'dessert';
    case 16:
      return 'cake';
    case 17:
      return 'supper';

    default:
      return 'entrees';
  }
};

const FoodOption = ({ question, response, food = [], upgrade }) => {
  const foodWithSidedishes = question && question.type && (question.type === 13 || question.type === 14) ? true : false;

  const foodList = food[foodType(question.type)];
  const sidedishes = food['sidedishes'];

  const options = response && Array.isArray(response)
    ? foodWithSidedishes
      ? response.map(resp => resp && resp.id
        ? ({
          food: foodList.find(food => resp.id ? food._id === resp.id : food._id === resp),
          sidedishes: resp.sidedishes && resp.sidedishes.length > 0 ? resp.sidedishes.map(s => sidedishes.find(side => side._id === s)) : []
        })
        : foodList.find(food => resp.id ? food._id === resp.id : food._id === resp))
      : response.map(resp => foodList.find(food => food && food._id && resp && resp.id ? food._id === resp.id : food._id === resp))
    : [];

  const hasSidedishes = (option) => {
    if (foodWithSidedishes) {
      return option && ((option.food && option.food.sidedishes && option.food.sidedishes.length > 0)
        || (option.sidedishes && option.sidedishes.length > 0)) ? true : false;
    } else return false;
  }

  const hasSidedishesOriginal = (option) => {
    if (foodWithSidedishes) {
      const foodSidedishes = option.food && option.food.sidedishes && option.food.sidedishes.length > 0
        ? option.food.sidedishes.map(m => m?._id)
        : [];
      const answerSidedishes = option.sidedishes && option.sidedishes.length > 0
        ? option.sidedishes.map(m => m?._id)
        : [];
      return checkSidedishesOriginal(foodSidedishes, answerSidedishes);
    } else return false;
  }

  const getPlateSidedishes = (option) => {
    if (foodWithSidedishes) {
      const foodSidedishes = option.food && option.food.sidedishes && option.food.sidedishes.length > 0
        ? option.food.sidedishes
        : [];
      const answerSidedishes = option.sidedishes && option.sidedishes.length > 0
        ? option.sidedishes
        : [];
      const plateSidedishes = hasSidedishesOriginal(option)
        ? foodSidedishes
        : answerSidedishes;
      return getPlateSidedishesName(plateSidedishes, sidedishes);
    } else return '';
  }

  const getSidedishes = (option) => {
    if (foodWithSidedishes) {
      return getEndSidedishesName(option.sidedishes ? option.sidedishes : []);
    } else return '';
  }

  const hasExtraCost = (option) => {
    if (foodWithSidedishes) {
      return checkExtraCost(option.food, option.sidedishes);
    } else return getCost(option) !== null ? true : false;
  }

  const getExtraCost = (option) => {
    if (foodWithSidedishes) {
      return getFoodExtraCost(option.food, option.sidedishes);
    } else return getCost(option);
  }

  const getCost = (option) => {
    let cost = null;
    const isPremiumFood = upgrade?.hasPremiumOptions && option?.isPremiumFood ? true : false;

    if (response && Array.isArray(response)) {
      let found = response.find(x => x && x.id && option?._id ? x.id === option._id : x === option._id);

      if (found && found.cost && found.cost !== undefined) {
        cost = isString(found.cost) ? parseFloat(found.cost) : found.cost;
      } else {
        // cost = isPremiumFood
        //   ? option.costPriceExtra
        //   : option.extra_cost
        //     ? option.extra_cost
        //     : null;
        cost = option.extra_cost
          ? option.extra_cost
          : null;
      }
    } else {
      // cost = isPremiumFood
      //   ? option.costPriceExtra
      //   : option.extra_cost
      //     ? option.extra_cost
      //     : null;
      cost = option.extra_cost
        ? option.extra_cost
        : null;
    }

    return cost;
  }

  if (options) {
    return options.map((option, index) =>
      ((foodWithSidedishes && option?.food) || (!foodWithSidedishes && option)) ? (
        <div key={index} style={{ display: 'flex' }}>
          <ResponsePill single={!hasExtraCost(option)}>
            <span style={{
              color: (option.food ? '"#8e8e8e' : 'inherit'),
              fontWeight: (option.food && hasSidedishesOriginal(option) ? 'lighter' : 'bold')
            }}>
              {option.food ? 'Prato Original: ' : null}
              {option.food ? TranslateValue(option.food.name)?.trim() : TranslateValue(option.name)?.trim()}
              {hasSidedishes(option) ? getPlateSidedishes(option) : null}
            </span>
            {hasSidedishesOriginal(option)
              ? <span>
                Prato Final:&nbsp;
                {option.food ? TranslateValue(option.food.name)?.trim() : TranslateValue(option.name)?.trim()}{getSidedishes(option)}
              </span>
              : null}
          </ResponsePill>
          {hasExtraCost(option) && (
            <ExtraPill last title={
              `${option?.isPremiumFood ? '- Aplicável no upgrade "Prime"' : ''}${option?.isPremiumFood && option?.isTrendFood ? '\n' : ''}${option?.isTrendFood ? '- Aplicável no upgrade "Trend"' : ''}`
            }>
              {(option?.isPremiumFood || option?.isTrendFood) && <Icon type={'sketch'} style={{ marginRight: 5, fontSize: 20 }} />}
              <CurrencyComponent value={getExtraCost(option) === 0 ? '0.00' : getExtraCost(option)} />
              /pax
            </ExtraPill>
          )}
          {/* {option.extra_cost && (
            <ExtraPill last>
              <CurrencyComponent value={option.extra_cost} />
              /pax
            </ExtraPill>
          )} */}
        </div>
      ) : (
        <ResponsePill key={index}>
          O prato selecionado já não se encontra disponível
        </ResponsePill>
      )
    );
  }

  return <ResponsePill>Não existe resposta</ResponsePill>;
};

const mapStateToProps = state => ({
  food: state.info.food
});

export default connect(mapStateToProps)(FoodOption);
