import styled from 'styled-components';
import {
  Input,
  Select,
  InputNumber,
  Checkbox,
  DatePicker,
  Icon,
  Collapse,
  Cascader,
  TimePicker
} from 'antd';

const { TextArea } = Input;
const { Panel } = Collapse;
const Option = Select.Option;
const { RangePicker, MonthPicker } = DatePicker;

const defineBorderColor = props => {
  if (props.error) return props.theme.inputErrorColor;
  if (props.active) return props.theme.primaryColor;
  return props.theme.inputBorderColor;
};

export const InputDiv = styled.div`
  display: ${({ horizontal }) => horizontal ? 'flex' : 'inline-block'};
  ${({ horizontal }) => horizontal ? 'flex-direction: row;' : ''}
  width:  ${({ horizontal, zoom }) => horizontal || zoom ? 'auto' : '100%'};
  margin-top: ${p => (p.first || p?.horizontal ? '0px' : '10px')};
  ${({ horizontal }) => (horizontal) ? 'align-items: center; margin-right: 8px;' : ''}
  ${p => p?.restrictionOrder ? `height: 100%;` : null}
`;

export const ChangeSizerInputDiv = styled.div`
  display: inline-block;
  width: 100px;
  margin-left: 5px;
  margin-top: ${p => (p.first ? '0px' : '10px')};
`;

export const SimpleCheckboxDiv = styled.div`
  padding-right: 30px;
`;

export const SampleButtonSpace = styled.div`
  display: inline-block;
  width: 100%;
  height: 32px;
`;

export const SampleInputSpace = styled.div`
  width: 100%;
  margin-top: ${p => (p.first ? '0px' : '10px')};
  height: 64px;
`;

export const InputLabelDiv = styled.div`
  display: inline-block;
  font-size: ${({ small }) => (small ? '12px' : '16px')};
  width: ${({ horizontal }) => horizontal ? 'auto' : '100%'};
  ${({ error }) => error && 'color: red'};
  margin-top: ${({ sublabel }) => (sublabel ? '10px' : 0)};
  ${({ horizontal }) => horizontal ? `
  margin-right: 5px;
  white-space: nowrap;` : ''}
`;

export const SimpleCheckboxLabel = styled.div`
  display: inline-block;
  font-size: 22px;
  padding-right: 10px;
  font-weight: bold;
`;

export const InputGroupField = styled(Input.Group)`
  width: 100%;
  display: flex !important;
  align-items: center;
  align-content: center;

  &&& {
    margin-bottom: ${({ language }) => (language ? 3 : 0)}px;
  }

  .ant-select, .ant-cascader-picker {
    width: 100% !important;
  }

  &&&.ant-input-affix-wrapper .ant-input-prefix {
    ${({ language, theme }) =>
    language
      ? `
    width: 60px;
    height: 100%;
    padding-right: 5px;
    border-right: 1px solid ${theme.inputBorderColor};
    `
      : ''};
  }
  &&&.ant-input-affix-wrapper .ant-input:not(:first-child) {
    padding-left: ${({ language }) => (language ? 80 : 30)}px;
  }

  .ant-btn {
    height: 32px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &:focus-within,
  &:hover {
    .ant-input-prefix,
    .ant-input-suffix {
      color: ${p => p.theme.inputFocusColor};
    }
    .ant-input {
      border: 1px solid ${p => p.theme.inputFocusColor};
    }
  }

  ${p =>
    p.error &&
    `
      border: 1px solid ${p.theme.inputErrorColor} !important;
      .ant-input-prefix, .ant-input-suffix{
        color: ${p.theme.inputErrorColor};
      }
  `}
`;

export const WaitingIcon = styled.img`
  width: 16px;
`;

export const InputField = styled(Input)`
  width: 100%;
  &&& {
    margin-bottom: ${({ language }) => (language ? 3 : 0)}px;
  }
  ${p => p?.restrictionOrder ? 'height: 100%' : null}

  &&&.ant-input-affix-wrapper .ant-input-prefix {
    ${({ language, theme }) =>
    language
      ? `
    width: 60px;
    height: 100%;
    padding-right: 5px;
    border-right: 1px solid ${theme.inputBorderColor};
    `
      : ''};
  }
  &&&.ant-input-affix-wrapper .ant-input:not(:first-child) {
    padding-left: ${({ language }) => (language ? 80 : 30)}px;
  }

  ${({ preview, disabled }) => preview && disabled
    ? `&&&.ant-input-affix-wrapper .ant-input[disabled] {
      color: rgba(0, 0, 0, 0.5) !important;
    }`
    : null}

  &:focus-within,
  &:hover {
    .ant-input-prefix,
    .ant-input-suffix {
      color: ${p => p.theme.inputFocusColor};
    }
    .ant-input {
      border: 1px solid ${p => p.theme.inputFocusColor};
    }
  }

  ${p =>
    p.error &&
    `
      border: 1px solid ${p.theme.inputErrorColor} !important;
      border-radius: 4px;
      .ant-input-prefix, .ant-input-suffix{
        color: ${p.theme.inputErrorColor};
      }
  `}
`;

export const StyledSelectInput = styled(Select)`
  width:  ${({ horizontal }) => horizontal ? '200px' : '100%'};

  ${p =>
    p.noBorder &&
    `.ant-select-selection {
        border: unset !important;
        box-shadow: none !important;
      }
  `}

  &:focus-within,
  &:hover {
    .ant-select-selection {
      border: 1px solid ${p => p.theme.inputFocusColor};
    }

    svg, svg path {
      color: ${p => p.theme.primaryColor};
      fill: ${p => p.theme.primaryColor};
      fill-opacity: 1;
    }
  }

  ${p =>
    p.error &&
    `.ant-select-selection{
        border: 1px solid ${p.theme.inputErrorColor} !important;
      }
  `}
`;

export const StyledSelectDiv = styled.div`
  display: ${({ horizontal }) => horizontal ? 'flex' : 'inline-block'};
  ${({ horizontal }) => horizontal ? 'flex-direction: row;' : ''}
  width:  ${({ horizontal, zoom }) => horizontal || zoom ? 'auto' : '100%'};
  margin-top: ${p => (p.first || p?.horizontal ? '0px' : '10px')};
  ${({ horizontal }) => (horizontal) ? 'align-items: center; margin-right: 8px;' : ''}
  position: relative;

  .ant-select-selection__clear,
  .ant-select-arrow {
    user-select: none;
  }

  &:hover .ant-select-selection__clear {
    opacity: 1;
  }

  .ant-select-selection__clear:hover {
    color: rgba(0, 0, 0, 0.45);
    opacity: 1;
  }

  .ant-select-arrow:hover {
    cursor: pointer;
  }
`;

export const MultipleSelectDiv = styled.div`
  position: relative;
  max-width: 100%;
  margin-top: 0px;

  .ant-select-arrow {
    user-select: none;
  }

  &:hover .ant-select-selection__clear {
    opacity: 1;
  }

  .ant-select-selection__clear:hover {
    color: rgba(0, 0, 0, 0.45);
    opacity: 1;
  }

  .ant-select-arrow {
    top: 45% !important;
  }

  .ant-select-arrow:hover {
    cursor: pointer;
  }
`;

export const StyledManualSelectInput = styled.div`
  display: flex;
  justify-content: space-between;
  height: 32px;
  box-sizing: border-box;
  background-color: ${p => p?.disabled ? '#f5f5f5' : '#fff'};
  border: 1px solid ${p => p?.theme?.inputBorderColor};
  border-top-width: 1.02px;
  border-radius: 4px;
  outline: none;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  color: ${p => p?.disabled ? 'rgba(0, 0, 0, 0.25)' : p?.filled ? 'black' : 'rgba(0, 0, 0, 0.65)'};
  opacity: 1;

  &:hover {
    cursor: ${p => p?.disabled ? 'not-allowed' : 'pointer'};
    border-color: ${p => p?.disabed ? '#d9d9d9' : p?.theme?.primaryColor};
  }
  
  div.select-input {
    padding: 0 24px 0 11px;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: auto 0;
    text-align: left;
  }
`;

export const MultipleStyledSelectInput = styled.div`
  display: flex;
  justify-content: space-between;
  height: 32px;
  box-sizing: border-box;
  background-color: ${p => p?.disabled ? '#f5f5f5' : '#fff'};
  border: 1px solid ${p => p?.theme?.inputBorderColor};
  border-top-width: 1.02px;
  border-radius: 4px;
  outline: none;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  color: ${p => p?.disabled ? 'rgba(0, 0, 0, 0.25)' : p?.filled ? p?.theme?.primaryColor : 'rgba(0, 0, 0, 0.65)'};
  font-weight: ${p => p?.filled ? 600 : 'inherit'};
  opacity: 1;

  &:hover {
    cursor: ${p => p?.disabled ? 'not-allowed' : 'pointer'};
    border-color: ${p => p?.disabed ? '#d9d9d9' : p?.theme?.primaryColor};
  }
  
  div.select-input {
    padding: 0 24px 0 11px;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: auto 0;
    text-align: left;
  }
`;

export const SelectOptions = styled.ul`
  padding-inline-start: 0;
  background-color: white;
  position: absolute;
  z-index: 30;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  height: ${p => p?.age ? 'auto' : '150px'};
  width: 100%;
  overflow: auto;
  border: 1px solid ${p => p.theme.inputBorderColor};
 `;

export const SelectOption = styled.li`
    cursor: ${({ disabled }) => disabled ? 'blocked' : 'pointer'};
    text-align: left;
    display: inline-flex;
    align-items: center;
    padding: 6px 11px;
    width: 100%;

    &:hover {
      background-color: #fcfbed;
    }

    label {
      display: inline-flex;
      align-items: center;
    }

    span {
      ${p => !p?.selectAge ? `
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      ` : null};
      line-height: 16px;
    }
 `;

export const SelectOptionUpgradeSelection = styled.li`
    cursor: ${({ disabled }) => disabled ? 'blocked' : 'pointer'};
    text-align: left;
    display: inline-flex;
    align-items: center;
    padding: 6px 11px;
    width: 100%;
    background: ${({ upgradeSelection }) => (upgradeSelection ? 'rgb(211, 223, 222)' : 'white')};

    &:hover {
      background: ${({ upgradeSelection }) => (upgradeSelection ? 'rgb(211, 223, 222)' : 'white')};
    }

    label {
      display: inline-flex;
      align-items: center;
    }

    span {
      ${p => !p?.selectAge ? `
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      ` : null};
      line-height: 16px;
    }
 `;

export const TextAreaInputField = styled(TextArea)`
  width: 100%;
  ${p => p?.guestmap && `
  resize: none;
  max-height: 175px !important;
  `}
  ${p => p?.restrictionOrder ? `
  height: 100% !important;
  margin-bottom: 0 !important;
  ` : null}

  &:focus-within,
  &:hover {
    border: 1px solid ${p => p.theme.inputFocusColor};
  }

  ${({ preview, disabled }) => preview && disabled
    ? `&&&.ant-input[disabled] {
    color: rgba(0, 0, 0, 0.5) !important;
  }`
    : null}

  ${p =>
    p.error &&
    `border: 1px solid ${p.theme.inputErrorColor} !important;
`}

  ${p =>
    p.fillError &&
    `background-color: #fff5f6a6 !important;
`}
`;

export const InputNumberField = styled(InputNumber)`
  width: 100% !important;

  &:focus-within,
  &:hover {
    .ant-input-prefix,
    .ant-input-suffix {
      color: ${p => p.theme.inputFocusColor};
    }
    .ant-input-number-input {
      border: 1px solid ${p => p.theme.inputFocusColor};
    }
  }

  ${p =>
    p.error &&
    `.ant-input-number-input{
        border: 1px solid ${p.theme.inputErrorColor};
      }
  `}
`;

export const CheckboxField = styled(Checkbox)`
&.ant-checkbox-wrapper {
    margin: ${({ label }) => (label ? '5px 0 0 0' : '0')};
 }
`;

export const SimpleCheckboxField = styled(Checkbox)`
  display: inline-block;
  
  &.ant-checkbox-wrapper {
    font-size: 22px;

    & .ant-checkbox {
      & form input[type='checkbox'] {
        width: 22px;
        height: 22px;
      }

      & .ant-checkbox-inner {
        width: 22px;
        height: 22px;

        &:after {
          width: 7px;
          height: 12px;
        }
      }
    }
  }
`;

export const LanguagePill = styled.div`
  display: inline-block;
  margin: 10px 10px 0 0;
  padding: 6px 10px;
  border: 1px solid ${props => defineBorderColor(props)};
  color: ${({ active }) => (active ? 'white' : 'rgba(0, 0, 0, 0.65)')};
  background-color: ${({ active, theme }) =>
    active ? theme.primaryColor : 'transparent'};
  cursor: pointer;
  border-radius: 3px;
`;

export const Flag = styled.img`
  width: 20px;
  margin-right: 10px;
`;

export const DateInputField = styled(DatePicker)`
  display: inline-block;
  width: 100%;

  &:focus-within,
  &:hover {
    .ant-calendar-picker-icon {
      color: ${({ theme }) => theme.inputFocusColor};
    }
    .ant-input {
      border: 1px solid ${({ theme }) => theme.inputFocusColor};
    }
  }

  ${({ error, theme }) =>
    error &&
    `.ant-calendar-picker-input{
        border: 1px solid ${theme.inputErrorColor};
      }
      
      .ant-calendar-picker-icon{
        color: ${theme.inputErrorColor};
      }
  `}
`;

export const TimeInputField = styled(TimePicker)`
  display: inline-block;
  width: 100% !important;

  &:focus-within,
  &:hover {
    .ant-time-picker-icon {
      color: ${({ theme }) => theme.inputFocusColor};
    }
    .ant-input {
      border: 1px solid ${({ theme }) => theme.inputFocusColor};
    }
  }

  ${({ error, theme }) =>
    error &&
    `.ant-time-picker-input {
        border: 1px solid ${theme.inputErrorColor};
      }
      
      .ant-time-picker-icon {
        color: ${theme.inputErrorColor};
      }
  `}
`;

export const DateRangeInputField = styled(RangePicker)`
  display: inline-block;
  width: 100%;

  &:focus-within,
  &:hover {
    .ant-calendar-picker-icon {
      color: ${({ theme }) => theme.inputFocusColor};
    }
    .ant-input {
      border: 1px solid ${({ theme }) => theme.inputFocusColor};
    }
  }

  ${({ error, theme }) =>
    error &&
    `.ant-calendar-picker-input{
        border: 1px solid ${theme.inputErrorColor};
      }
      
      .ant-calendar-picker-icon{
        color: ${theme.inputErrorColor};
      }
  `}
`;

export const MonthInputField = styled(MonthPicker)`
  display: inline-block;
  width: 100%;

  &:focus-within,
  &:hover {
    .ant-calendar-picker-icon {
      color: ${({ theme }) => theme.inputFocusColor};
    }
    .ant-input {
      border: 1px solid ${({ theme }) => theme.inputFocusColor};
    }
  }

  ${({ error, theme }) =>
    error &&
    `.ant-calendar-picker-input{
        border: 1px solid ${theme.inputErrorColor};
      }
      
      .ant-calendar-picker-icon{
        color: ${theme.inputErrorColor};
      }
  `}
`;

export const RatingInputBox = styled.div`
  display: inline-block;
  width: 100%;
`;

export const RateImage = styled.img`
  margin: 5px;
  width: 30px;
  cursor: pointer;
`;

export const ImageInputSection = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  min-height: ${({ auto }) => (auto ? 0 : 200)}px;
  justify-content: center;
  align-items: center;
  border: 1px solid ${props => defineBorderColor(props)};
  cursor: pointer;
  ${({ filled }) => (filled ? 'border: none' : '')}
`;

export const ImageMessage = styled.div`
  color: ${({ error, theme }) =>
    error ? theme.inputErrorColor : 'rgba(0, 0, 0, 0.65)'};
  font-size: 18px;
  margin-top: 30px;
`;

export const ImageIcon = styled(Icon)`
  font-size: 36px;
  color: ${({ error, theme }) =>
    error ? theme.inputErrorColor : 'rgba(0, 0, 0, 0.65)'};
`;

export const LanguagesComponent = styled.div`
  display: inline-block;
  width: 100%;
  margin: 10px 0;
`;

export const AddLangButton = styled.div`
  display: inline-block;
  width: 300px;
  margin-top: 10px;
`;

export const DeleteLangIcon = styled.div`
  display: inline-block;
  position: relative;
`;

export const FormCollapse = styled(Collapse)`
  &&.ant-collapse-header {
    color: red;
  }
`;

export const FormPanel = styled(Panel)`
  &&& {
    .ant-collapse-header {
      color: ${({ error }) => (error ? 'red !important' : '')};
    }
    .ant-collapse-content-box {
      padding: 5px;
    }
  }
`;

export const ImageSlideComponent = styled.div`
  display: inline-block;
  width: 100%;
  margin-top: ${p => p?.gallery ? '0px' : '20px'};

  .ant-carousel {
    margin-top: 10px;
    .slick-slide {
      position: relative;
    }
  }
`;

export const ImageSlideButton = styled.div`
  display: inline-block;
  width: 100%;
  cursor: pointer;
  margin-top: ${p => p?.gallery ? '0px' : '12px'};
`;

export const RemoveImage = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  display: flex;
  background: rgba(0, 0, 0, 0.4);
  justify-content: center;
  align-items: center;
  font-size: 20px;
  cursor: pointer;
  color: white;
`;

export const FavoriteImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  display: flex;
  background: rgba(0, 0, 0, 0.4);
  justify-content: center;
  align-items: center;
  font-size: 20px;
  cursor: pointer;
  color: white;
`;

export const OrderImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  display: flex;
  background: rgba(0, 0, 0, 0.7);
  justify-content: center;
  align-items: center;
  font-size: 20px;
  cursor: pointer;
  color: white;
  border-bottom-right-radius: 12px;
`;

export const AddIcon = styled(Icon)`
  position: absolute;
  top: calc(50% - 25px);
  left: calc(50% - 25px);
  font-size: 50px;
`;

export const EmptySlide = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 66%;
  border: 1px solid
    ${({ showError, theme }) =>
    showError ? theme.inputErrorColor : theme.inputBorderColor};

  ${AddIcon} {
    color: ${({ showError, theme }) =>
    showError ? theme.inputErrorColor : theme.inputBorderColor};
  }
`;

export const MapSearchInput = styled(Input)`
  &&& {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 350px;
  }
`;

export const PreviewVideo = styled.div`
  display: inline-block;
  width: 100%;
  height: 400px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
`;

export const FileSection = styled.div`
  margin-top: 3px;
  background-color: ${p => p.theme.secondaryColor};
  padding: 5px;
  text-transform: uppercase;
  position: relative;

  & i {
    position: absolute;
    right: 1%;
    top: 25%;
    cursor: pointer;
  }
`;

export const FileDropSection = styled.div`
  display: flex;
  width: 100%;
  color: ${({ theme }) => theme.inputBorderColor};
  border: 1px solid ${({ theme, error }) => error ? theme.inputErrorColor : theme.inputBorderColor};
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  padding: 30px;
  flex-direction: column;
  margin: 30px 0;
  cursor: pointer;
  font-size: 18px;

  i {
    font-size: 50px;
  }
`;

export const CurrencyInputStyle = styled.input`
  display: inline-block;
  width: 100%;
  border: 1px solid
    ${({ showError, theme }) =>
    showError ? theme.inputErrorColor : theme.inputBorderColor};
  background: transparent;
  box-sizing: border-box;
  margin: 0;
  height: 32px;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5;
  border-radius: 4px;
  transition: all 0.3s;

  &:focus {
    border: 1px solid ${({ theme }) => theme.inputFocusColor};
    outline: 0;
    box-shadow: 0 0 0 2px rgba(188, 146, 36, 0.2);
  }

  &:hover {
    border: 1px solid ${({ theme }) => theme.inputFocusColor};
  }

  &::placeholder {
    color: #cfcfcf;
  }
`;

export const ColourInput = styled(InputField)`
  float: left !important;
  width: calc(100% - 40px) !important;
`;

export const ColourBox = styled.div`
  display: inline-block;
  width: 32px;
  height: 32px;
  margin-left: ${({ table }) => table ? '0' : '8px'};
  float: right;
  background-color: ${({ colour }) => colour};
  display: inline-flex;
  border: 1px solid rgb(217, 217, 217);
  box-shadow: unset;
  box-sizing: border-box;
  border-radius: 4px;
  float: ${({ table }) => table ? 'left' : 'right'};
`;

export const ContentColourBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  margin-left: ${({ table }) => table ? '0' : '8px'};
  background-color: ${({ colour }) => colour};
  border: 1px solid rgb(217, 217, 217);
  box-shadow: unset;
  box-sizing: border-box;
  border-radius: 4px;
  text-align: center;
  color: #000;
  font-size: 15px;
`;

export const ColorBox = styled.div`
  padding: 5px;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 0 0 1px rgba(0,0,0,.1);
  display: inline-block;
  width: 36px;
  height: 18px;
  background: ${({ color }) => color};
`;

export const RoundColorBox = styled.div`
  border-radius: 50%;
  color: #fff !important;
  width: 36px;
  height: 18px;
  font-size: 11px;
  background-color: ${({ color }) => color};
`;

export const StyledCascadeInput = styled(Cascader)`
  width: 100%;

  &:focus-within,
  &:hover {
    .ant-select-selection {
      border: 1px solid ${p => p.theme.inputFocusColor};
    }
  }

  ${p =>
    p.error &&
    `.ant-select-selection{
        border: 1px solid ${p.theme.inputErrorColor} !important;
      }
     
  `}
`;

export const DynamicImage = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

export const FilesGallerySection = styled.div`
  display: inline-block;
  width: 100%;
  max-width: 500px;
`;

/******************************* CUSTOM SELECT INPUT "OUR DAY" *******************************/

export const OptionContainer = styled(Option)``;

export const OptionTextOurDay = styled.span`
  color: ${p => p.requiredOrderModule ? '#d91e18' : 'rgba(0, 0, 0, 0.65)'};
  font-size: 14px;
  font-weight: ${p => p.required || p.requiredOrderModule ? '700' : '400'};
  line-height: 22px;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const CustomSelectLabel = styled.div`
  font-size: 16px;
  line-height: 29px;
  text-align: left;
`;

export const SelectDiv = styled.div`
  position: relative;
  max-width: 100%;
  margin-top: 0px;
`;

/******************************* TIME INPUT *******************************/

export const TimeInputSection = styled.div`
  display: inline-block;
  width: 100%;
  position: relative;
  margin-bottom: ${p => p.weddingOrder ? '0px' : '30px'};
  background: transparent;
`;

export const TimeInputLabel = styled.div`
  font-size: 16px;
  line-height: 29px;
  text-align: left;
`;

export const TimeInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: ${p => p.weddingOrder ? 'auto' : '140px'};
  height: ${p => p.weddingOrder ? '32px' : '35px'};
  background-color: #ffffff;
  border: 1px solid ${p => (p.error ? p.theme.inputErrorColor : '#cacaca')};
  border-radius: ${p => p.weddingOrder ? '0px' : '4px'};
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px;
`;

export const TimeInput = styled.input`
  width: 100%;
  border: 0;
  text-align: center;
  color: rgba(0, 0, 0, 0.65);
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  opacity: ${p => (p.filled ? '1' : '0.2')};

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }

  &:focus {
    outline: 0;
  }
`;

export const ArrowsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 100%;
  border-left: 1px solid #cacaca;
`;

export const ArrowUp = styled.img`
  transform: rotateZ(180deg);
  padding-top: 4px;
  width: 14px;
  height: 14px;
  cursor: pointer;
`;

export const ArrowDown = styled.img`
  padding-top: 4px;
  width: 14px;
  height: 14px;
  cursor: pointer;
`;

/******************************* QUANTITY INPUT *******************************/

export const CostContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: ${({ selected }) => (selected ? '5px' : '0px')};
`;

export const QuantityContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const ButtonSubtract = styled.span`
  color: rgba(0, 0, 0, 0.65);
  font-weight: bolder;
  font-size: 16px;
  line-height: 24px;
  padding: 0px 5px;
  border-top-left-radius: 18px;
  border-bottom-left-radius: 18px;
  cursor: pointer;
  user-select: none;
`;

export const QuantityInput = styled(Input)`
  max-width: 30px;
  
  &.ant-input {
    border: 0;
    border-radius: 0px;
    height: 24px;
    width: 30px;
    color: rgba(0, 0, 0, 0.65);
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    padding: 0px 1px;

    &:focus {
      box-shadow: none;
    }
  }
`;

export const ButtonAdd = styled.span`
  color: rgba(0, 0, 0, 0.65);
  font-weight: bolder;
  font-size: 16px;
  line-height: 24px;
  padding: 0px 5px;
  border-top-right-radius: 18px;
  border-bottom-right-radius: 18px;
  cursor: pointer;
  user-select: none;
`;