import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { Field, reduxForm, initialize, FieldArray, formValueSelector, change } from 'redux-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import TitleInput from '../../components/inputs/TitleInput';
import LanguagesInput from '../../components/inputs/LanguagesInput';
import ImageInput from '../../components/inputs/ImageInput';
import DateInput from '../../components/inputs/DateInput';
import ImageGalleryInput from '../../components/inputs/ImageGalleryInput';
import FilesGallery from '../../components/inputs/FilesGallery';
import SelectInput from '../../components/inputs/SelectInput';
import BaseButton from '../../components/buttons/BaseButton';
import {
  HeaderContainer,
  PageTitle,
  FormContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  BaseForm,
  SpinLoading,
  SectionTitle
} from '../../styles/BasicStyles';
import FormValidator from '../../infra/services/validations/FormValidator';
import { FlattenToFormData } from '../../infra/services/formdata/TransformToFormData';
import { DeleteImageFromOptionGallery, GetOption, SaveImage, SaveImageToOptionGallery, UpdateCoverImageToGallery, UpdateGalleryImageToCoverImage, UpdateImageOrderOnGallery, UpdateOption } from '../../infra/requests/OptionsRequests';
import CheckboxInput from '../../components/inputs/CheckboxInput';
import { GetAllSupplierCategories } from '../../infra/requests/SupplierRequests';
import { GetTableTypeList } from '../../infra/requests/TableTypeRequests';
import { GetFoodRestrictionList } from '../../infra/requests/FoodRestrictionsRequests';
import { CostTypeOptions } from '../../infra/services/options/Options';
import CurrencyInput from '../../components/inputs/CurrencyInput';
import ImageOrderModal from '../../components/images/ImageOrderModal';

const Locals = [
  { _id: 1, name: 'Solar da Levada' },
  { _id: 2, name: 'Quinta Lago dos Cisnes' }
];

const validations = values => {
  const validations = {
    title: 'required|languages',
    type: 'required'
  };

  if (values?.cost) {
    validations['cost_price'] = 'minNumber:0';
    validations['cost_type'] = 'required';
  }

  if (values?.tableForRoomPlan == true) {
    validations['tableType'] = 'required';
  }

  const errors = FormValidator.make(validations)(values);
  return errors;
};

class ManageOptionPage extends Component {
  state = {
    loading: true,
    tableTypes: [],
    foodRestrictions: [],
    option: null,
    supplierCategories: [],

    showOrderModal: false,
    savingOrder: false,
  };

  componentDidMount = () => {
    this.getOption();
  };

  getOption = async () => {
    const {
      match: { params },
      dispatch
    } = this.props;

    const { data } = await GetOption(params.id);

    const supplierCategories = await GetAllSupplierCategories();

    const optionForm = { ...data };
    if (optionForm?.question?._id) {
      optionForm['question'] = data?.question?._id;
    };
    dispatch(initialize('manage_option_form', optionForm));
    this.setState({
      loading: false,
      option: data,
      supplierCategories: supplierCategories?.data || []
    }, () => {
      if (data?.question?.tableForRoomPlan) {
        change('tableForRoomPlan', true);
        this.getTableTypes();
      } else if (data?.question?.isFoodRestriction) {
        this.getFoodRestrictions();
      }
    });

  }

  onChangeLocal = () => {
    const { option } = this.state;
    if (option?.question?.tableForRoomPlan) {
      this.getTableTypes();
    }
  }

  getTableTypes = async () => {
    const { local, tableType } = this.props;
    const { option } = this.state;

    const result = await GetTableTypeList({});
    let data = result?.success
      ? (result?.data || [])
      : [];

    if (data?.length > 0) {
      data = data.map(m => ({
        ...m,
        name: `${m?.designation?.pt} (${m?.weddingPlace == 3 ? 'Ambos' : m?.weddingPlace == 2 ? 'QLC' : 'SLE'})`
      }));
    }

    if (option?.question?.isCoupleTable) {
      data = data.filter(f => f?.coupleTable);
    } else {
      data = data.filter(f => !f?.coupleTable);
    }

    if (local && local !== '') {
      data = data.filter(f => (f?.weddingPlace == local || f?.weddingPlace == 3));
      if (tableType && !data.find(f => f?._id == tableType)) {
        change('tableType', null);
      }
    }

    this.setState({ tableTypes: data });
  }

  getFoodRestrictions = async () => {
    const result = await GetFoodRestrictionList();
    let data = result?.success ? (result?.data || []) : [];
    data = data.map(m => ({ ...m, label: `${m?.acronym?.pt || ''} - ${m?.subtitle?.pt || ''}` }));
    this.setState({ foodRestrictions: data });
  }

  onSubmit = async values => {
    const {
      match: { params },
      history
    } = this.props;

    try {
      this.setState({ loading: true });
      if (values.image && !values.image.blob) {
        delete values.image;
      }
      const payload = FlattenToFormData(values);
      const { success } = await UpdateOption(params.id, payload);
      if (success) {
        this.handleOnCancel();
      }
      return this.setState({ loading: false });
    } catch (e) {
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const { history } = this.props;
    const { option } = this.state;
    const questionId = option?.question?._id || option?.question;
    if (questionId) {
      return history.push(`/question/${questionId}`);
    } else {
      return history.goBack();
    }
  };

  addImageToGallery = async image => {
    const {
      match: { params }
    } = this.props;

    const payload = new FormData();
    payload.append('image', image?.blob);

    return await SaveImageToOptionGallery(params.id, payload);
  };

  deleteImageOfGallery = async image => {
    const {
      match: { params }
    } = this.props;

    return await DeleteImageFromOptionGallery(params.id, image);
  }

  changeCoverImageToGallery = async () => {
    const {
      match: { params },
      dispatch,
      optionForm
    } = this.props;

    const payload = new FormData();
    if (optionForm?.image?.blob) {
      payload.append('image', optionForm?.image.blob);
    }

    const result = await UpdateCoverImageToGallery(params.id, payload);
    if (result?.success) {
      dispatch(initialize('manage_option_form', {
        ...optionForm,
        image: result?.data?.image || null,
        gallery: result?.data?.gallery || []
      }));
    }
  };

  changeGalleryImageToCover = async image => {
    const {
      match: { params },
      dispatch,
      optionForm
    } = this.props;

    const result = await UpdateGalleryImageToCoverImage(params.id, image);
    if (result?.success) {
      dispatch(initialize('manage_option_form', {
        ...optionForm,
        image: result?.data?.image || null,
        gallery: result?.data?.gallery || []
      }));
    }
  };

  showOrderImageModal = () => {
    const { dispatch, optionForm } = this.props;
    dispatch(initialize('mng_order_images', { images: [...optionForm?.gallery] }, false));
    this.setState({ showOrderModal: true });
  }

  onSubmitOrder = async (values) => {
    const {
      match: { params },
      dispatch,
      optionForm
    } = this.props;

    try {
      this.setState({ savingOrder: true });

      if (values.images.length > 0) {
        const res = await UpdateImageOrderOnGallery(params.id, { gallery: values?.images });

        if (res?.success) {
          dispatch(initialize('manage_option_form', {
            ...optionForm,
            gallery: res?.data?.gallery || []
          }));
        }
      }

      this.setState({ savingOrder: false });
      this.closeOrderModal();
    } catch (e) {
      this.setState({ savingOrder: false });
    }
  };

  closeOrderModal = () => {
    this.setState({ showOrderModal: false });
  }

  render() {
    const { handleSubmit } = this.props;
    const { hasCost, optionForm } = this.props;
    const { loading } = this.state;
    const { option, supplierCategories, tableTypes, foodRestrictions } = this.state;
    const { showOrderModal, savingOrder } = this.state;

    if (loading) return <SpinLoading />;
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={2}>
            <PageTitle>Editar opção</PageTitle>
          </HeaderTitle>
          <HeaderButtonsContainer buttons={2}>
            <BaseButton
              type="primary"
              icon="save"
              text="Gravar"
              onClick={handleSubmit(this.onSubmit)}
            />
            <BaseButton
              type="default"
              icon="close"
              text="Cancelar"
              onClick={this.handleOnCancel}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <FormContainer>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Row>
              <Col xs={10} offset={7}>
                <Row gutter={[12, 12]}>

                  <Col>
                    <Field
                      name="image"
                      label="Imagem de capa"
                      component={ImageInput}
                      ratio={0.6}
                      selectToGallery={true}
                      handleSelectToCoverImage={this.changeCoverImageToGallery}
                      disabled={optionForm?.gallery?.length > 4 ? true : false}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24}>
                    <Field
                      component={TitleInput}
                      name={'title'}
                      type="text"
                      label={'Titulo'}
                      placeholder={'Titulo da opção'}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24}>
                    <Field
                      component={LanguagesInput}
                      name={'description'}
                      type="text"
                      label={'Descrição'}
                      placeholder={'Descrição da opção'}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} style={{ padding: 0 }}>
                    <Col xs={24} sm={24} md={8} lg={8}>
                      <Field
                        component={CheckboxInput}
                        name={'cost'}
                        type="text"
                        label={'Tem custo acrescido?'}
                        positiveLabel={'Sim'}
                      />
                    </Col>
                    {hasCost && (
                      <React.Fragment>
                        <Col xs={24} sm={24} md={8} lg={8}>
                          <Field
                            component={SelectInput}
                            name={'cost_type'}
                            label={'Tipo de custo'}
                            placeholder={'Escolha o tipo de custo'}
                            type="select"
                            data={CostTypeOptions}
                          />
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={8}>
                          <Field
                            component={CurrencyInput}
                            name={'cost_price'}
                            label={'Preço'}
                            placeholder={'Insira o preço para o tipo de custo'}
                          />
                        </Col>
                      </React.Fragment>
                    )}
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} style={{ padding: 0 }}>
                    <Col xs={24} sm={24} md={8} lg={8}>
                      <Field
                        component={CheckboxInput}
                        name={'quantity'}
                        type="text"
                        label={'Sujeito a disponibilidade?'}
                        positiveLabel={'Sim'}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8}>
                      <Field
                        component={CheckboxInput}
                        name={'onlyOneAvailable'}
                        type="text"
                        label={'Limitado por local e data?'}
                        positiveLabel={'Sim, limitado a 1 local e data'}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8}>
                      <Field
                        component={CheckboxInput}
                        name={'isPremiumOption'}
                        type="text"
                        label={'Opção do Serviço Premium?'}
                        positiveLabel={'Sim'}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8}>
                      <Field
                        component={CheckboxInput}
                        name={'isTrendOption'}
                        type="text"
                        label={'Opção do Serviço Trend?'}
                        positiveLabel={'Sim'}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8}>
                      <Field
                        component={CheckboxInput}
                        name={'isBreakfast'}
                        type="text"
                        label={'Opção de pequeno-almoço?'}
                        positiveLabel={'Sim'}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8}>
                      <Field
                        component={CheckboxInput}
                        name={'hasHost'}
                        label={'Requisitar hospedeira?'}
                        positiveLabel={'Sim'}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8}>
                      <Field
                        component={CheckboxInput}
                        name={'isSeatNumber'}
                        label={'Identificador de mesa?'}
                        positiveLabel={'Sim'}
                      />
                    </Col>
                    {option?.question?.isCoupleTable && <Col xs={24} sm={24} md={8} lg={8}>
                      <Field
                        component={CheckboxInput}
                        name={'blockForCouple'}
                        label={'Bloquear para os noivos?'}
                        positiveLabel={'Sim'}
                      />
                    </Col>}
                    <Col xs={24} sm={24} md={8} lg={8}>
                      <Field
                        component={CheckboxInput}
                        name={'distinguishBudget'}
                        label={'Distinguir no orçamento?'}
                        positiveLabel={'Sim'}
                      />
                    </Col>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <Field
                      component={SelectInput}
                      name={'local'}
                      label={'Local'}
                      allowClear
                      placeholder={'Restrito apenas a um local'}
                      type="select"
                      data={Locals}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12}>
                    <Field
                      component={SelectInput}
                      name={'supplier_category'}
                      label={'Categoria de Encomenda'}
                      placeholder={'Escolha uma categoria'}
                      type="select"
                      data={supplierCategories}
                      allowClear
                      mode='multiple'
                    />
                  </Col>
                  {option?.question?.tableForRoomPlan && <Col xs={24} sm={24} md={12} lg={12}>
                    <Field
                      component={SelectInput}
                      name={'tableType'}
                      label={'Tipo de mesa'}
                      allowClear
                      placeholder={'Selecione o tipo'}
                      type="select"
                      data={tableTypes}
                    />
                  </Col>}
                  {option?.question?.isFoodRestriction && <Col xs={24} sm={24} md={24} lg={24}>
                    <Field
                      component={SelectInput}
                      name={'foodRestrictions'}
                      mode='multiple'
                      label={'Restrições alimentares'}
                      allowClear
                      placeholder={'Selecione as restrições'}
                      type="select"
                      data={foodRestrictions}
                      dataLabel='label'
                    />
                  </Col>}
                </Row>
              </Col>
              <Col xs={20} offset={2}>
                <SectionTitle withButton subsection>Galeria de Imagens
                  <BaseButton type="default"
                    text="Editar ordem"
                    icon="retweet"
                    disabled={optionForm?.gallery?.length > 0 ? false : true}
                    onClick={this.showOrderImageModal} />
                </SectionTitle>
                <FieldArray
                  name="gallery"
                  selectToCoverImage={true}
                  allowDelete={true}
                  disabled={optionForm?.gallery?.length >= 4 ? true : false}
                  component={ImageGalleryInput}
                  handleAdd={this.addImageToGallery}
                  handleDelete={this.deleteImageOfGallery}
                  handleSelectToCover={this.changeGalleryImageToCover}
                />
              </Col>
              {/* <Col xs={20} offset={2}>
                <SectionTitle subsection>Ficheiros</SectionTitle>
                <FieldArray
                  name="attachments"
                  component={FilesGallery}
                  handleAdd={this.saveNewFile}
                  handleDelete={this.deleteFile}
                />
              </Col> */}

            </Row>
          </BaseForm>

          {showOrderModal && <ImageOrderModal
            open={showOrderModal}
            loading={savingOrder}
            onSubmit={this.onSubmitOrder}
            onClose={this.closeOrderModal}
          />}

        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageOptionPage = reduxForm({
  form: 'manage_option_form',
  validate: validations
})(ManageOptionPage);

const selector = formValueSelector('manage_option_form');

const mapStateToProps = state => ({
  optionForm: {
    question: selector(state, 'question'),
    image: selector(state, 'image'),
    title: selector(state, 'title'),
    description: selector(state, 'description'),
    cost: selector(state, 'cost'),
    cost_type: selector(state, 'cost_type'),
    cost_price: selector(state, 'cost_price'),
    quantity: selector(state, 'quantity'),
    onlyOneAvailable: selector(state, 'onlyOneAvailable'),
    isPremiumOption: selector(state, 'isPremiumOption'),
    isTrendOption: selector(state, 'isTrendOption'),
    isBreakfast: selector(state, 'isBreakfast'),
    hasHost: selector(state, 'hasHost'),
    isSeatNumber: selector(state, 'isSeatNumber'),
    blockForCouple: selector(state, 'blockForCouple'),
    distinguishBudget: selector(state, 'distinguishBudget'),
    local: selector(state, 'local'),
    supplier_category: selector(state, 'supplier_category'),
    tableType: selector(state, 'tableType'),
    foodRestrictions: selector(state, 'foodRestrictions'),
    gallery: selector(state, 'gallery'),
  },
  hasCost: selector(state, 'cost'),
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ change }, dispatch);
}

export default withLocalize(connect(mapStateToProps, mapDispatchToProps)(ManageOptionPage));
