import React, { Component } from 'react';
import { Tabs, Row, Col } from 'antd';
import {
    PageContainer,
    PageTitle,
    HeaderContainer,
    HeaderTitle,
} from '../../styles/BasicStyles';
import { withLocalize } from 'react-localize-redux';
import CategoryTable from './CategoryTable';
import PriceUnitTable from './PriceUnitTable';
import EntityTable from './settingsPayments/EntityTable';
import InvoicingTypeTable from './settingsPayments/InvoicingTypeTable';
import PaymentMethodTable from './settingsPayments/PaymentMethodTable';
import CalendarTable from './CalendarTable';
import ColorSystemTable from './ColorSystemTable';
import EmailTable from './EmailTable';
import PaymentPhasesTable from './settingsPayments/PaymentPhasesTable';
import GraphicsTable from './GraphicsTable';
import AttendingTable from './AttendingTable';
import RoomPlanSetting from './settingsRoomPlan/RoomPlanSetting';
import { inRoomPlanTest } from '../../infra/helpers/Helpers';
import PaymentAccountsTable from './settingsPayments/PaymentAccountsTable';
import EmployeeTable from './ScoreTable';
import ScoreTable from './ScoreTable';

class SettingsPage extends Component {
    state = {
        pageSize: 5,
        loading: false,
        total: 0
    };

    componentDidMount = async () => {

    };

    onChangeTab = (activeKey) => {
        // console.warn('activeKey', activeKey);
        this.props.history.push('/settings/' + activeKey);
    }

    render() {
        const tabKey = this.props.match.params.tabKey ? this.props.match.params.tabKey : 1;
        const activeTabKey = tabKey.toString();

        const { TabPane } = Tabs;

        return (
            <React.Fragment>
                <HeaderContainer>
                    <HeaderTitle buttons={0}>
                        <PageTitle>Configurações </PageTitle>
                        Configurações do Sistema
                    </HeaderTitle>
                </HeaderContainer>

                <PageContainer buttons={0}>
                    <Tabs style={{ textAlign: 'start' }} defaultActiveKey={activeTabKey} onChange={(activeKey) => this.onChangeTab(activeKey)}>
                        <TabPane tab="Categoria" key="1">
                            <CategoryTable />
                            <PriceUnitTable />
                        </TabPane>

                        <TabPane tab="Calendário" key="2">
                            <CalendarTable />
                            <ColorSystemTable />
                        </TabPane>

                        <TabPane tab="Pagamentos" key="3">
                            <Row gutter={[12, 12]}>
                                <Col lg={24} md={24} sm={24} xs={24}>
                                    <PaymentPhasesTable />
                                </Col>

                                <Col lg={24} md={24} sm={24} xs={24}>
                                    <PaymentAccountsTable />
                                </Col>

                                <Col lg={12} md={12} sm={24} xs={24}>
                                    <EntityTable />
                                </Col>

                                <Col lg={12} md={12} sm={24} xs={24}>
                                    <InvoicingTypeTable />
                                </Col>

                                <Col lg={12} md={12} sm={24} xs={24}>
                                    <PaymentMethodTable />
                                </Col>
                            </Row>
                        </TabPane>

                        <TabPane tab="Emails" key="4">
                            <EmailTable></EmailTable>
                        </TabPane>

                        <TabPane tab="Grafismos" key="5">
                            <GraphicsTable></GraphicsTable>
                        </TabPane>

                        <TabPane tab="Atendimentos" key="6">
                            <AttendingTable></AttendingTable>
                        </TabPane>

                        {inRoomPlanTest() && <TabPane tab="Planos de Sala" key="7">
                            <RoomPlanSetting></RoomPlanSetting>
                        </TabPane>}

                        <TabPane tab="Pontuação" key="8">
                            <ScoreTable></ScoreTable>
                        </TabPane>
                    </Tabs>
                </PageContainer>
            </React.Fragment>
        );
    }
}

export default withLocalize(SettingsPage);