import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, initialize, FieldArray, formValueSelector } from 'redux-form';
import {
  UpdateFood,
  CreateFood,
  GetFood,
  GetFoodByCategory,
  UpdateSidedishe,
  CreateSidedishe
} from '../../infra/requests/FoodRequests';
import BaseButton from '../../components/buttons/BaseButton';
import {
  HeaderContainer,
  PageTitle,
  FormContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  BaseForm,
  SpinLoading,
  SectionTitle,
  ErrorText
} from '../../styles/BasicStyles';
import FormValidator from '../../infra/services/validations/FormValidator';
import { GetFoodType } from '../../infra/services/food/FoodCategories';
import ImageInput from '../../components/inputs/ImageInput';
import IngredientsComponent from '../../components/ingretients/IngredientsComponent';
import CurrencyInput from '../../components/inputs/CurrencyInput';
import LanguagesInput from '../../components/inputs/LanguagesInput';
import TitleInput from '../../components/inputs/TitleInput';
import { FlattenToFormData } from '../../infra/services/formdata/TransformToFormData';
import SelectInput from '../../components/inputs/SelectInput';
import { Col, Collapse, Icon, Row, Tooltip } from 'antd';
import TextInput from '../../components/inputs/TextInput';
import Table from '../../components/table/Table';
import TranslateValue from '../../infra/services/translations/TranslateValue';
import { CollapseContainer, PanelContainer } from '../../components/budget/BudgetStyles';
import { withLocalize } from 'react-localize-redux';
import { InputLabelDiv } from '../../components/inputs/InputStyles';
import Alerts from '../../components/alert/Alert';
import FoodWarningModal from './FoodWarningModal';
import CheckboxInput from '../../components/inputs/CheckboxInput';
const { Panel } = Collapse;

export const SidedishesTypes = [
  { _id: 'fish', name: 'Peixe' },
  { _id: 'meat', name: 'Carne' },
  { _id: 'both', name: 'Ambas' },
];

const validations = values => {
  // const errors = FormValidator.make({
  //   name: 'required|languages'
  // })(values);
  let errors = {};

  if (values.category === 'sidedishes') {
    errors.sidedisheType = 'ERROR';
    errors = FormValidator.make({
      name: 'required|languages',
      sidedisheType: 'required'
    })(values);
  } else {
    errors = FormValidator.make({
      name: 'required|languages'
    })(values);
  }

  // Check if it was selected more than 2 sidedishes
  // if ((values.category === 'meat' || values.category === 'fish')
  //   && values.sidedishes && values.sidedishes.length > 2) {
  //   errors.sidedishes = 'ERROR';
  // }

  if (values.ingredients && values.ingredients.length) {
    errors.ingredients = [];
    values.ingredients.forEach(ingredient => {
      const error = FormValidator.make({
        quantity: 'required',
        type: 'required'
      })(ingredient);
      errors.ingredients.push(error);
    });
  }
  return errors;
};

class ManageFoodPage extends Component {
  state = {
    isNew: true,
    loading: true,
    organizers: [],
    sidedishes: [],
    columnsIngredients: [
      {
        title: 'Ingrediente',
        render: data => data.ingredient?.name
      },
      {
        title: 'Quantidade',
        dataIndex: 'quantity',
      },
      {
        title: 'Tipo',
        dataIndex: 'type',
      },
    ],
    showSidedisheDetail: false,
    showWarningModal: false,
    warningData: null,
  };

  componentDidMount = async () => {
    const {
      match: { params },
    } = this.props;

    this.getFoodIndo(params.id, params.category);
  };

  componentWillReceiveProps = (nextProps) => {
    const {
      match: { params },
    } = this.props;

    const nextPropsId = nextProps.match.params.id;
    const nextPropsCategory = nextProps.match.params.category;
    if (params.id !== nextPropsId) this.getFoodIndo(nextPropsId, nextPropsCategory);
  }

  getFoodIndo = async (id, category) => {
    const { dispatch } = this.props;

    let sidedishes = [];
    if (category === 'fish' || category === 'meat') {
      const resultSidedishe = await GetFoodByCategory('sidedishes');
      sidedishes = resultSidedishe.success && resultSidedishe.data
        ? resultSidedishe.data.map(m => ({ ...m, label: m.name.pt })) : [];
      this.setState({ sidedishes });
    }

    if (id) {
      const { data } = await GetFood(id);
      dispatch(initialize('manage_food_form', data));
      this.setState({ isNew: false, loading: false });
    } else {
      dispatch(initialize('manage_food_form', { category: category }));
      this.setState({
        isNew: true,
        loading: false
      });
    }
  }

  onSubmit = async values => {
    const {
      match: { params },
      history
    } = this.props;
    const { showWarningModal, warningData } = this.state;

    try {
      this.setState({ loading: true });
      const isSidedishe = params.category === 'sidedishes';

      const payload = FlattenToFormData({
        ...values,
        category: params.category
      });

      const { success } = isSidedishe
        ? params.id
          ? await UpdateSidedishe(params.id, payload)
          : await CreateSidedishe(payload)
        : params.id
          ? await UpdateFood(params.id, payload)
          : await CreateFood(payload);

      // If food was saved and showWarningModal then navigate to sidedishe
      if (success && showWarningModal) {
        const sidedisheId = warningData?._id;
        this.setState({
          loading: false,
          showWarningModal: false,
          warningData: null,
        }, () => this.goToSidedishe(sidedisheId));
      }
      // If food was saved then goBack
      else if (success && !showWarningModal) {
        // history.push(`/food/${params.category}`)
        this.setState({ loading: false }, () => history.goBack());
      }
      // If food wasn't saved then loading false
      else return this.setState({ loading: false });
    } catch (e) {
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const {
      history,
      match: { params }
    } = this.props;

    let returnUrl = `/food/${params.category}`;
    const internalCategories = params.category === 'internal_buffets' || params.category === 'internal_appetizers';
    if (internalCategories) {
      returnUrl = '/food';
    }

    return internalCategories ? history.push(returnUrl) : history.goBack();
  };

  getTableIngredients = (data) => {
    const { columnsIngredients, loading } = this.state;
    return <Table
      columns={columnsIngredients}
      loading={loading}
      rows={data.ingredients}
      showHeader={true}
      hasPagination={false}
      emptyIcon="shopping-cart"
      emptyText={'Não existem ingredientes!'}
      rowKey={'_id'}
    />
  }

  getSidedisheInfo = (row) => {
    const { dirty } = this.props;
    return <Tooltip title='Aceder à Guarnição'>
      <Icon
        type="info-circle"
        onClick={event => {
          // If you don't want click extra trigger collapse, you can prevent this:
          event.stopPropagation();
          // Check if form is dirty
          if (dirty) {
            this.setState({
              showWarningModal: true,
              warningData: row
            });
          } else this.goToSidedishe(row._id);
        }}
      />
    </Tooltip>
  };

  goToSidedishe = (id) => {
    const { history } = this.props;
    history.push(`/food/sidedishes/${id}`);
  }

  render() {
    const {
      match: { params },
      handleSubmit,
      foodForm
    } = this.props;
    const { loading, isNew, sidedishes } = this.state;
    const { showSidedisheDetail } = this.state;
    const { showWarningModal, warningData } = this.state;
    const isSidedishe = params.category === 'sidedishes';
    const title = isSidedishe
      ? (isNew ? 'Nova Guarnição' : 'Editar Guarnição')
      : (isNew ? 'Novo prato' : 'Editar prato');
    const formHasSidedishes = this.props.foodForm && this.props.foodForm.sidedishes
      && Array.isArray(this.props.foodForm.sidedishes)
      && this.props.foodForm.sidedishes.length > 0 ? true : false;

    if (loading) return <SpinLoading />;
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={2}>
            <PageTitle>{GetFoodType(params.category)}</PageTitle>
            {title}
          </HeaderTitle>
          <HeaderButtonsContainer buttons={2}>
            <BaseButton
              type="primary"
              icon="save"
              text="Gravar"
              onClick={handleSubmit(this.onSubmit)}
            />
            <BaseButton
              type="default"
              icon="close"
              text="Cancelar"
              onClick={this.handleOnCancel}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <FormContainer singleColumn>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            {!isSidedishe && <Field
              component={ImageInput}
              name="image"
              label={'Imagem'}
              width="100%"
              ratio={0.6}
            />}
            <Field
              component={TitleInput}
              name={'name'}
              type="text"
              label={isSidedishe ? 'Nome da Guarnição' : 'Nome'}
              placeholder={isSidedishe ? 'Nome da guarnição' : 'Nome do prato'}
            />
            {!isSidedishe && <Field
              component={LanguagesInput}
              name={'description'}
              type="text"
              label={'Descrição'}
              placeholder={'Descrição do prato'}
            />}
            <Field
              component={CurrencyInput}
              name={'extra_cost'}
              defaultValue="0.00"
              label={'Preço extra'}
              placeholder={'Insira o preço extra por PAX'}
            />

            {!isSidedishe && <Field
              component={CheckboxInput}
              name={'isPremiumFood'}
              label={'Prato do Serviço Premium?'}
              positiveLabel={'Sim'}
            // disabled={true}
            />}

            {!isSidedishe && <Field
              component={CheckboxInput}
              name={'isTrendFood'}
              label={'Prato do Serviço Trend?'}
              positiveLabel={'Sim'}
            // disabled={true}
            />}

            {/* Sidedishes Section */}
            {isSidedishe && <Field
              component={SelectInput}
              data={SidedishesTypes}
              name={'sidedisheType'}
              label={'Tipo de Guarnição'}
              placeholder={'Selecione o tipo de guarnição'}
            />}

            {(params.category === 'meat' || params.category === 'fish') &&
              <Row gutter={[12, 12]}>
                <Col xs={24}>
                  <SectionTitle subsection>Gestão de Guarnições</SectionTitle>
                </Col>
                <Col xs={24} style={{ paddingBottom: 0 }}>
                  <Field
                    component={SelectInput}
                    name={'sidedishes'}
                    label={'Guarnições'}
                    placeholder={'Selecione as guarnições'}
                    allowClear={true}
                    data={sidedishes.filter(f => f.sidedisheType === params.category || f.sidedisheType === 'both')}
                    dataLabel={'label'}
                    mode={'multiple'}
                  />
                </Col>
                {/* {foodForm.sidedishes && foodForm.sidedishes.length > 2 &&
                  <Col xs={24} style={{ paddingTop: 0 }}>
                    <ErrorText>Só pode selecionar no máximo 2 guarnições.</ErrorText>
                  </Col>} */}
                {formHasSidedishes &&
                  <Col xs={24} style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                    <BaseButton
                      type={'primary'}
                      icon={showSidedisheDetail ? 'minus' : 'plus'}
                      text={showSidedisheDetail ? 'Fechar Detalhe' : 'Ver Detalhe'}
                      onClick={() => this.setState({ showSidedisheDetail: !showSidedisheDetail })}
                    />
                  </Col>
                }
                {showSidedisheDetail && formHasSidedishes &&
                  <Col xs={24}>
                    <InputLabelDiv>Lista de Guarnições</InputLabelDiv>
                    <Collapse expandIconPosition={'left'}
                    >
                      {this.props.foodForm.sidedishes
                        .map(m => sidedishes.find(f => f._id === m))
                        .map((row) => (
                          <Panel
                            header={TranslateValue(row.name)}
                            key={row._id}
                            extra={this.getSidedisheInfo(row)}>
                            {this.getTableIngredients(row)}
                          </Panel>
                        ))}
                    </Collapse>
                  </Col>
                }
              </Row>
            }

            {/* Ingredients Section */}
            <FieldArray component={IngredientsComponent} name="ingredients" />
          </BaseForm>
        </FormContainer>

        <FoodWarningModal
          openModal={showWarningModal}
          initialValues={warningData}
          loading={loading}
          closeModal={() => {
            const sidedisheId = warningData?._id;
            this.setState({ showWarningModal: false, warningData: null }, () => this.goToSidedishe(sidedisheId))
          }}
          onSubmit={handleSubmit(this.onSubmit)}
        />
      </React.Fragment>
    );
  }
}

const selector = formValueSelector('manage_food_form');

ManageFoodPage = reduxForm({
  form: 'manage_food_form',
  validate: validations,
})(ManageFoodPage);

const mapStateToProps = state => ({
  foodForm: {
    image: selector(state, 'image'),
    name: selector(state, 'name'),
    description: selector(state, 'description'),
    extra_cost: selector(state, 'extra_cost'),
    isPremiumFood: selector(state, 'isPremiumFood'),
    isTrendFood: selector(state, 'isTrendFood'),
    sidedishes: selector(state, 'sidedishes'),
    ingredients: selector(state, 'ingredients'),
  }
});

export default withLocalize(connect(mapStateToProps)(ManageFoodPage));
