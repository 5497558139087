export const getAvgScoreStyle = (score) => {
    if (score >= 0 && score <= 0.9) {
        return '#f77d6d';
    } else if (score >= 1 && score <= 2.9) {
        return '#f7ad6d';
    } else if (score >= 3 && score <= 3.9) {
        return '#f7f56d';
    } else if (score >= 4 && score <= 5) {
        return '#6df77d';
    } else {
        return '#fff';
    }
};

export const getScoresSelector = () => {
    return [
        { _id: 0, name: 0 },
        { _id: 1, name: 1 },
        { _id: 2, name: 2 },
        { _id: 3, name: 3 },
        { _id: 4, name: 4 },
        { _id: 5, name: 5 }
    ]
}

export const updateEmployeesScoreColors = (employees) => {
    for (const e of employees) {
        e.scoreColor = getAvgScoreStyle(e?.avgScore);
    }
    return employees;
}

export const reorderEmployeesList = (employees) => {
    return employees.sort((a, b) => {
        if (a?.interest !== b?.interest) return b?.interest - a?.interest;
        if (a?.avgScore !== b?.avgScore) return b?.avgScore - a?.avgScore;
        return a?.name.localeCompare(b?.name);
    });
}