import React, { useState, useEffect, Fragment, useReducer, useCallback } from 'react';
import { Row, Col, Icon, Tooltip, Modal, Menu, Dropdown, Input } from 'antd';
import { SeatRow, StatsContent, StatsContentRow, StatsTotal, TableCategory, TableGuests, TableGuestsHeader, TableGuestsOptions, TableGuestsStats, TableGuestsType, TableName, TablePurpose, TableSeatCode } from '../../process/guests/RoomPlanStyles';
import Alerts from '../alert/Alert';
import BaseButton from '../buttons/BaseButton';
import RadioButtons from '../inputs/RadioButtons';
import { convertNumberToLetter, onGuestUpdate } from '../../process/guests/utilRoomPlan';
import GuestSeatComponent from './GuestSeatComponent';
import { InputLabelDiv } from '../inputs/InputStyles';
import CoupleIcon from '../../assets/icons/ic_about.svg';
import StaffIcon from '../../assets/icons/ic_staff.svg';
import ReactSVG from 'react-svg';
import { Element } from 'react-scroll';
import OrderSeatsModal from './OrderSeats';
import { GuestAgeEnum, TablePurposeEnum } from './utilGuest';

const { confirm } = Modal;

const GuestTableComponent = ({
  wedding,
  index,
  table,
  resizable,
  increasable,
  objectTypes,
  foodRestrictions,
  parentActiveTable,
  parentDeleteTable,
  parentEditTableCouple,
  parentChangeTablePurpose,
  parentUpdateTable,
  parentSelectToChangeGuests,
  parentUnselectChange,
  parentOpenImportModal,
  dishes
}) => {
  const [tableObj, setTable] = useState(table);
  const [name, setName] = useState(table?.name);
  const [code, setCode] = useState(table?.code);
  const [active, setActive] = useState(table?.active);
  const [tableClass, setTableClass] = useState(table?.tableClass);
  const [tableType, setTableType] = useState(table?.tableType);
  const [tablePurpose, setTablePurpose] = useState(table?.tablePurpose);
  const [seats, setSeats] = useState(table?.seats);
  const [seatsFrontCouple, setSeatsFrontCouple] = useState(table?.seatsFrontCouple);
  const [isSelectedToChange, setSelectedToChange] = useState(table?.isSelectedToChange);
  const [seatStats, setSeatStats] = useState({ ADULT: 0, CHILD: 0, BABY: 0, NEWBORN: 0 });

  const [canResize, setCanResize] = useState(resizable);
  const [canIncrease, setCanIncrease] = useState(increasable);

  const [resizing, toggleResize] = useState(false);
  const [addingSeat, toggleAddingSeat] = useState(false);
  const [showOrderModal, toggleOrderModal] = useState(false);
  const [savingOrder, toggleSavingOrder] = useState(false);

  useEffect(() => {
    setTable(table);
    setName(table?.name);
    setCode(table?.code);
    setActive(table?.active);
    setTableClass(table?.tableClass);
    setTableType(table?.tableType);
    setTablePurpose(table?.tablePurpose);
    setSelectedToChange(table?.isSelectedToChange);
    setSeats(table?.seats);
    setSeatsFrontCouple(table?.seatsFrontCouple);
  }, [table]);

  useEffect(() => {
    setSeatStats({
      ADULT: seats?.filter(f => f?.guestName?.trim() != '' && f?.guestAge == GuestAgeEnum.ADULT)?.length || 0,
      CHILD: seats?.filter(f => f?.guestName?.trim() != '' && f?.guestAge == GuestAgeEnum.CHILD)?.length || 0,
      BABY: seats?.filter(f => f?.guestName?.trim() != '' && f?.guestAge == GuestAgeEnum.BABY)?.length || 0,
      NEWBORN: seats?.filter(f => f?.guestName?.trim() != '' && f?.guestAge == GuestAgeEnum.NEWBORN)?.length || 0,
    });
  }, [seats]);

  useEffect(() => {
    setCanResize(resizable);
  }, [resizable]);

  useEffect(() => {
    setCanIncrease(increasable);
  }, [increasable]);

  const checkCoupleTable = () => {
    return tablePurpose == TablePurposeEnum.COUPLE;
  }

  const checkStaffTable = () => {
    return tablePurpose == TablePurposeEnum.STAFF;
  }

  const checkGuestTable = () => {
    return tablePurpose == TablePurposeEnum.GUEST || tablePurpose == TablePurposeEnum.CHILDREN;
  }

  const activateTable = () => {
    setActive(true);
    parentActiveTable(code);
  }

  const confirmInactiveTable = () => {
    if (tablePurpose == TablePurposeEnum.COUPLE) {
      return Alerts.new({
        type: 'warning',
        title: 'Atenção',
        text: 'Não pode desativar essa mesa!'
      });
    } else {
      confirm({
        title: 'Têm a certeza que pretende desativar a mesa?',
        content: (<div>
          <p>Ao desativar a mesa, serão removidos os convidados existentes.</p>
        </div>
        ),
        okType: 'danger',
        okText: 'Desativar',
        cancelText: 'Cancelar',
        onOk: () => {
          setActive(false);
          parentActiveTable(code)
        },
        onCancel: () => null,
      });
    }
  }

  const confirmRemoveTable = () => {
    if (tablePurpose == TablePurposeEnum.COUPLE || tablePurpose == TablePurposeEnum.STAFF) {
      return Alerts.new({
        type: 'warning',
        title: 'Atenção',
        text: 'Não pode remover essa mesa!'
      });
    } else {
      confirm({
        title: 'Têm a certeza que pretende remover a mesa?',
        content: (<div>
          <p>Ao remover a mesa, serão removidos os convidados existentes.</p>
        </div>
        ),
        okType: 'danger',
        okText: 'Remover',
        cancelText: 'Cancelar',
        onOk: () => parentDeleteTable(code),
        onCancel: () => null,
      });
    }
  }

  const handleMenuClick = (e) => {
    const key = parseInt(e.key);
    if (key == 0 && checkCoupleTable()) parentEditTableCouple();
    else if (key == 1) activateTable()
    else if (key == 2) parentOpenImportModal(code);
    else if (key == 3) toggleOrderModal(true);
    // else if (key == 2) increaseTable();
    // else if (key == 3) reduceTable();
    // else if (key == 4) selectToChangeGuests();
    else if (key == 5) confirmInactiveTable();
    else if (key == 6) confirmRemoveTable();
  }

  const createMenu = () => {
    return <Menu onClick={(e) => handleMenuClick(e)}>
      {checkCoupleTable(table) && <Menu.Item key="0">
        Editar
      </Menu.Item>}
      {!checkCoupleTable(table) && !active && <Menu.Item key="1">
        Ativar
      </Menu.Item>}
      {active && <Menu.Item key="2">
        Importar
      </Menu.Item>}
      {active && <Menu.Item key="3">
        Ordenar
      </Menu.Item>}
      {/* {canResize && canIncrease && <Menu.Item key="2">
        Aumentar
      </Menu.Item>}
      {canResize && !canIncrease && <Menu.Item key="3">
        Reduzir
      </Menu.Item>} */}
      {/* {checkGuestTable() && !table?.isSelectedToChange && <Menu.Item key="4">
        Trocar Convidados
      </Menu.Item>} */}
      {!checkCoupleTable(table) && active && <Menu.Item key="5">
        Desativar
      </Menu.Item>}
      {checkGuestTable(table) && <Menu.Item key="6">
        Remover
      </Menu.Item>}
    </Menu >
  };

  const changeSeatsFrontCouple = (event) => {
    if (event?.target?.value == false) {
      const seatsFront = seats.filter(f => f?.frontCouple);
      if (seatsFront.find(f => f?.guestName?.trim() != '')) {
        return Alerts.new({
          type: 'warning',
          title: 'Atenção',
          text: 'Ação inválida! Os lugares em frente ao casal encontram-se preenchidos.'
        });
      }
    }

    setSeatsFrontCouple(event?.target?.value);
    parentUpdateTable({
      type: 'UPDATE_SEATS_FRONT_COUPLE',
      code,
      value: event.target?.value
    });
  }

  const changePurpose = (event) => {
    setTablePurpose(event?.target?.value);
    if (event?.target?.value == TablePurposeEnum.STAFF) {
      parentChangeTablePurpose(event?.target?.value);
    } else {
      parentUpdateTable({
        type: 'UPDATE_TABLE_PURPOSE',
        code,
        value: event.target?.value
      });
    }
  }

  const addSeat = () => {
    const updatedSeats = [...seats];
    toggleAddingSeat(true);
    const seat = {
      number: seats?.length,
      couple: false,
      guestName: '',
      guestAge: GuestAgeEnum.ADULT,
      foodRestrictions: []
    };
    updatedSeats.push(seat);
    setSeats(updatedSeats);
    parentUpdateTable({
      type: 'ADD_TABLE_SEATS',
      code,
      value: seat
    });
    toggleAddingSeat(false);
  };

  const showChangeGuestsButton = () => {
    return !isSelectedToChange
      ? <BaseButton
        type='default'
        text={'Trocar convidados'}
        disabled={!active}
        onClick={() => parentSelectToChangeGuests()} />
      : <BaseButton
        type='danger'
        text={'Cancelar trocar convidados'}
        disabled={!active}
        onClick={() => unselectToChangeGuests()} />;
  }

  const unselectToChangeGuests = () => {
    setSelectedToChange(!isSelectedToChange);
    parentUnselectChange();
  }

  const showResizeButton = () => {
    return canIncrease
      ? <BaseButton
        type='default'
        text={'Aumentar mesa'}
        disabled={!active}
        loading={resizing}
        onClick={() => increaseTable()} />
      : <BaseButton
        type='default'
        text={'Reduzir mesa'}
        disabled={!active}
        loading={resizing}
        onClick={() => reduceTable()} />;
  }

  const increaseTable = () => {
    const updatedSeats = [...seats];
    let updatedTable = { ...table };

    const objectType = objectTypes.find(f => f?.tableClass == 'RectangularLTable');
    if (objectType) {
      toggleResize(true);
      updatedTable.tableClass = objectType?.tableClass;
      updatedTable.tableType = objectType?.tableClass;
      const missingSeats = Math.abs(objectType?.maxSeats - updatedSeats?.length);
      for (let i = 0; i < missingSeats; i++) {
        const seat = {
          number: updatedSeats?.length,
          couple: false,
          guestName: '',
          guestAge: GuestAgeEnum.ADULT,
          foodRestrictions: []
        };
        updatedSeats.push(seat);
      }

      updatedTable = { ...updatedTable, seats: updatedSeats };
      setTable(updatedTable);
      parentUpdateTable({
        type: 'UPDATE_TABLE',
        code,
        value: updatedTable
      });
      toggleResize(false);
    } else {
      return Alerts.new({
        type: 'error',
        title: 'Erro',
        text: 'Não foi possível realizar a operação!'
      });
    }
  }

  const reduceTable = () => {
    let updatedSeats = [...seats];
    let updatedTable = { ...table };
    const objectType = objectTypes.find(f => f?.tableClass == 'RectangularTable');
    if (objectType) {

      const filledSeats = updatedSeats?.filter(f => f?.guestName?.trim() != '').length || 0;
      if (filledSeats > objectType?.maxSeats) {
        return Alerts.new({
          type: 'error',
          title: 'Erro',
          text: 'Não é possível reduzir a mesa! Convidados a mais.'
        });
      } else {
        toggleResize(true);
        updatedTable.tableClass = objectType?.tableClass;
        updatedTable.tableType = objectType?.tableClass;
        const seatsToRemove = Math.abs(objectType?.maxSeats - updatedSeats?.length);
        for (let i = 0; i < seatsToRemove; i++) {
          const indexToDelete = updatedSeats.findIndex(f => f?.guestName?.trim() == '');
          updatedSeats.splice(indexToDelete, 1);
        }

        updatedSeats = updatedSeats.map((m, index) => ({ ...m, number: index }));
        updatedTable = { ...updatedTable, seats: updatedSeats }
        setTable(updatedTable);
        parentUpdateTable({
          type: 'UPDATE_TABLE',
          code,
          value: updatedTable
        });
        toggleResize(false);
      }
    } else {
      return Alerts.new({
        type: 'error',
        title: 'Erro',
        text: 'Não foi possível realizar a operação!'
      });
    }
  }

  const onSubmitOrder = async values => {
    toggleSavingOrder(true);

    if (values.length > 0) {
      let orderedList = [];
      Object.keys(values).forEach(function (key) {
        let obj = { ...values[key], number: key };
        orderedList.push(obj);
      });

      if (orderedList && Array.isArray(orderedList) && orderedList.length > 0) {
        let updatedSeats = [...orderedList];
        updatedSeats = updatedSeats.map((seat, index) => ({
          ...seat,
          number: index
        }))
        setSeats(updatedSeats);
        parentUpdateTable({
          type: 'UPDATE_SEATS',
          code,
          value: updatedSeats
        });
      }
    }
    toggleSavingOrder(false);
    toggleOrderModal(false);
  }

  const cancelOrderModal = () => {
    toggleOrderModal(false);
  }

  const handleNameChange = (e) => {
    if (!active || checkStaffTable()) {
      return;
    }
    setName(e.target.value);
    parentUpdateTable({
      type: 'UPDATE_TABLE_NAME',
      code,
      value: e.target.value,
    });
  }

  const handleUpdateSeat = (type, seatIndex, updatedSeat) => {
    let updatedSeats = [...seats];
    updatedSeats.splice(seatIndex, 1, updatedSeat);

    updatedSeats = onGuestUpdate(wedding?.wedding, dishes, updatedSeats);    

    setSeats(updatedSeats);
    parentUpdateTable({
      type,
      code,
      seatIndex,
      value: updatedSeat,
    });
  }

  const handleRemoveSeat = (seatIndex) => {
    let updatedSeats = [...seats];
    updatedSeats.splice(seatIndex, 1);
    updatedSeats = updatedSeats.map((seat, index) => ({
      ...seat,
      number: index
    }))
    setSeats(updatedSeats);
    parentUpdateTable({
      type: 'UPDATE_SEATS',
      code,
      value: updatedSeats
    });
  }


  return (
    <Col key={`anchor-${code}`} xs={24} sm={24} md={12} lg={8} xl={8} xxl={6} style={{ minHeight: '100%', display: 'flex' }}>
      <Element
        name={`anchor-${code}`}
        key={`anchor-${code}`}
        id={`anchor-${code}`}
        style={{ width: '100%', height: '100%' }}
      >
        <TableGuests disabled={!active} staff={checkStaffTable()} couple={checkCoupleTable()}>

          {/* <TableGuestsType>
              <div className='guest-table-content'>
                <div className={`guest-table ${getTableClassType(index)}`}></div>
                <div>{getTableType(index)}</div>
              </div>
            </TableGuestsType> */}

          {(checkCoupleTable() || checkStaffTable()) &&
            <TableCategory staff={checkStaffTable()} couple={checkCoupleTable()}>
              <ReactSVG
                className='category-icon'
                src={checkStaffTable() ? StaffIcon : CoupleIcon} />
              <span>{checkStaffTable() ? 'Mesa de staff' : 'Mesa do casal'}</span>
            </TableCategory>
          }

          <TableGuestsHeader>
            <TableSeatCode seat={false} couple={checkCoupleTable()}>{convertNumberToLetter(code || 0) || ''}</TableSeatCode>
            <TableName staff={checkStaffTable()} couple={checkCoupleTable()}>
              <Input
                placeholder={'Tema da mesa'}
                type="text"
                translate="no"
                disabled={!active || checkStaffTable()}
                value={name}
                onChange={(e) => handleNameChange(e)} />
            </TableName>
            <Dropdown overlay={() => createMenu()} trigger={['click']}>
              <i style={{ width: 31, textAlign: 'center', fontSize: 20 }} className="fa-solid fa-ellipsis-vertical"></i>
            </Dropdown>
          </TableGuestsHeader>

          {checkCoupleTable() && seats?.length >= 8
            ? <TablePurpose disabled={!active}>
              <InputLabelDiv horizontal={true}>Convidados em frente dos noivos:</InputLabelDiv>
              <RadioButtons
                // label={'Convidados em frente dos noivos:'}
                meta={{ invalid: false, submitFailed: false }}
                first={true}
                options={[
                  { label: 'Sim', value: true },
                  { label: 'Não', value: false },
                ]}
                disabled={!active}
                input={{
                  value: seatsFrontCouple,
                  onChange: (e) => changeSeatsFrontCouple(e)
                }}
              />
            </TablePurpose>
            : !checkCoupleTable()
              ? <TablePurpose disabled={!active}>
                <InputLabelDiv horizontal={true}>Mesas para:</InputLabelDiv>
                <RadioButtons
                  // label={'Mesas para:'}
                  meta={{ invalid: false, submitFailed: false }}
                  first={true}
                  options={[
                    { label: 'Convidados', value: TablePurposeEnum.GUEST },
                    { label: 'Crianças', value: TablePurposeEnum.CHILDREN },
                    { label: 'Staff', value: TablePurposeEnum.STAFF },
                  ]}
                  disabled={!active}
                  input={{
                    value: tablePurpose,
                    onChange: (e) => changePurpose(e)
                  }}
                />
              </TablePurpose>
              : null}

          {seats?.map((seat, sindex) => (
            <GuestSeatComponent
              dishes={dishes}
              wedding={wedding}
              key={sindex}
              index={sindex}
              seat={seat}
              table={tableObj}
              foodRestrictions={foodRestrictions}
              parentUpdateSeat={handleUpdateSeat}
              parentRemoveSeat={handleRemoveSeat}
              parentUpdateTable={parentUpdateTable}
            />
          ))}

          <TableGuestsOptions>
            {checkStaffTable() && <BaseButton
              type='default'
              icon={'plus'}
              loading={addingSeat}
              text={'Adicionar lugar'}
              disabled={!active}
              onClick={() => addSeat()} />}
            {checkGuestTable() && active && showChangeGuestsButton()}
            {canResize && showResizeButton()}
          </TableGuestsOptions>
          <TableGuestsStats disabled={!active}>
            <StatsContent>
              <StatsContentRow>
                <div className='stats-description'>Maior de 8 anos</div>
                <div className='stats-value'>{seatStats?.ADULT || 0}</div>
              </StatsContentRow>
              <StatsContentRow>
                <div className='stats-description'>3 aos 7 anos</div>
                <div className='stats-value'>{seatStats?.CHILD || 0}</div>
              </StatsContentRow>
              <StatsContentRow>
                <div className='stats-description'>6 meses a 2 anos</div>
                <div className='stats-value'>{seatStats?.BABY || 0}</div>
              </StatsContentRow>
              <StatsContentRow>
                <div className='stats-description'>0 a 5 meses</div>
                <div className='stats-value'>{seatStats?.NEWBORN || 0}</div>
              </StatsContentRow>
            </StatsContent>
            <StatsTotal couple={checkCoupleTable()}>
              <div className='pax'>{(seatStats?.ADULT || 0) + (seatStats?.CHILD || 0)}</div>
              {(seatStats?.BABY || 0) > 0 &&
                <div className='stroller'>{seatStats?.BABY || 0} Cadeira Bebé</div>}
              {(seatStats?.NEWBORN || 0) > 0 &&
                <div className='stroller'>{seatStats?.NEWBORN || 0} Carrinho</div>}
            </StatsTotal>
          </TableGuestsStats>
        </TableGuests>
      </Element>

      {showOrderModal &&
        <OrderSeatsModal
          open={showOrderModal}
          loading={savingOrder}
          onSubmit={onSubmitOrder}
          closeModal={cancelOrderModal}
          initialValues={seats}
          isStaff={checkStaffTable()}
          hasSeatsFrontCouple={seatsFrontCouple}
        />}
    </Col>
  );
};


export default React.memo(GuestTableComponent, (props, nextProps) => {
  // console.log("GuestTableComponent " + props.table.name);
  // console.log("GuestTableComponent " + props.table.code);

  if (props.table == nextProps.table
    && props.table.seats.filter(s => s.guestName?.trim() != '').length == nextProps.table.seats.filter(s => s.guestName?.trim() != '').length
    && props.table.seatsFrontCouple == nextProps.table.seatsFrontCouple
    && props.resizable == nextProps.resizable
    && props.increasable == nextProps.increasable
  ) {
    // console.log('no rerender');
    return true;
  }

  //re-render
  // console.log('re-render table', nextProps.table?.name);
  return false;
});
