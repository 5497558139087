import client from '../config/AxiosConfig';

export const getWeddingInfo = async id =>
  client.get(`/process/${id}/structure`);

export const GetOurStyle = async id => client.get(`/process/${id}/style`);

export const SaveStyleImage = async (id, data) =>
  client.put(`/process/${id}/style`, data);

export const DeleteStyleImage = async (id, image) =>
  client.delete(`/process/${id}/style/${image}`);

export const GetOurStaff = async id => client.get(`/process/${id}/staff`);

export const SaveStaff = async (id, data) =>
  client.put(`/process/${id}/staff`, data);

export const GetOurGuests = async id => client.get(`/process/${id}/guests`);

export const SaveGuests = async (id, data) =>
  client.put(`/process/${id}/guests`, data);

export const GetOurGuestsEditor = async (id) =>
  client.get(`/process/${id}/roomPlanEditor`);

export const GetCoupleTableEditor = async (id) =>
  client.get(`/process/${id}/coupleTableEditor`);

export const CheckUpdateCoupleTable = async (id, data) =>
  client.put(`/process/${id}/checkUpdateCoupleTable`, data);

export const ActivateRoomPlan = async (id) =>
  client.put(`/process/${id}/activateRoomPlan`, {});

// export const SetRoomPlanTemplate = async (id, data) =>
//   client.put(`/process/${id}/setRoomPlanTemplate`, data);

export const ChangeRoomPlanTemplate = async (id, data) =>
  client.put(`/process/${id}/changeRoomPlanTemplate`, data);

export const SaveRoomPlanImage = async (id, data) =>
  client.put(`/process/${id}/roomPlanImage`, data);

export const SaveOurGuestsEditor = async (id, data) =>
  client.put(`/process/${id}/roomPlanEditor`, data);

export const CheckSubmitRoomPlan = async (id) =>
  client.put(`/process/${id}/checkSubmitRoomPlan`);

export const SubmitRoomPlan = async (id) =>
  client.put(`/process/${id}/submitRoomPlan`);

export const UndoSubmitRoomPlan = async (id) =>
  client.put(`/process/${id}/undoSubmitRoomPlan`);

export const ApproveRoomPlan = async (id) =>
  client.put(`/process/${id}/approveRoomPlan`);

export const DisapproveRoomPlan = async (id) =>
  client.put(`/process/${id}/disapproveRoomPlan`);

export const SaveFloorPlan = async (id, data) =>
  client.put(`/process/${id}/floor-plan`, data);

export const GetBudget = async id => client.get(`/process/${id}/budget`);

export const CreateBudget = async data => client.post('/budget', data);

export const UpdateBudget = async (id, data) =>
  client.put(`/budget/${id}`, data);

export const RemoveBudget = async id => client.delete(`/budget/${id}`);

export const NotifyConfirmedPayment = async id => client.get(`/budget/${id}/notifyConfirmedPayment`);

export const NotifyCoupleAboutCompletedPaymentPhase = async (weddingId, order) => client.get(`/weddings/${weddingId}/notifyCoupleAboutCompletedPaymentPhase/${order}`);

export const SaveSectionNotes = async (id, data) =>
  client.put(`/process/${id}/notes`, data);

export const AnswerQuestion = async (id, data) =>
  client.put(`/process/${id}/answer`, data);

export const CanLoadCoximLacoChairs = async id =>
  client.get(`/process/${id}/answer/coxim-laco`);

export const DeleteAnswerFile = async (id, question) =>
  client.delete(`/process/${id}/answer/file/${question}`);

export const UpdateWeddingAbout = async (id, data) =>
  client.put(`/process/${id}/about`, data);

export const UpdateWeddingPhoto = async (id, data) =>
  client.put(`/process/${id}/about/photo`, data);

export const UpdateWeddingBillings = async (id, data) =>
  client.put(`/process/${id}/about/billings`, data);

export const GetGraphicMenu = async (id) =>
  client.get(`/process/${id}/graphic-menu`);

export const UpdateGraphicMenu = async (id, data) =>
  client.put(`/process/${id}/graphic-menu`, data);

export const MngUpgrade = async (id, data) =>
  client.post(`/process/${id}/upgrade`, data);

export const AddUpgrade = async (id, data) =>
  client.put(`/process/${id}/upgrade`, data);

export const RemoveUpgrade = async (id) =>
  client.delete(`/process/${id}/upgrade`);

export const UpdateWeddingPrinted = async (id, data) =>
  client.put(`/process/${id}/printed`, data);

export const UpdatePrivateNotes = async (id, data) =>
  client.put(`/process/${id}/private-notes`, data);

export const UpdateWeddingDetails = async (id, data) =>
  client.put(`/process/${id}/details`, data);

export const AddCoupleIdFiles = async (id, data) =>
  client.post(`/process/${id}/addCoupleIdFiles`, data);

export const RemoveCoupleIdFiles = async (id, fileId) =>
  client.delete(`/process/${id}/removeCoupleIdFiles/${fileId}`);

export const CountTotalMeatAndFishMainDishes = async (id) =>
  client.get(`/process/${id}/meatFishDishCount`);

export const MainDishesAnswers = async (id) =>
  client.get(`/process/answer/${id}/mainDishesAnswers`);