import React, {Component} from 'react';
import {Row, Col} from 'antd';
import {Field, reduxForm, initialize, FieldArray} from 'redux-form';
import {withLocalize} from 'react-localize-redux';
import {
  FormContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  BaseForm,
  SpinLoading,
  SectionTitle
} from '../../../styles/BasicStyles';
import BaseButton from '../../../components/buttons/BaseButton';
import LanguagesInput from '../../../components/inputs/LanguagesInput';
import FormValidator from '../../../infra/services/validations/FormValidator';
import {
  GetSection,
  UpdateSection
} from '../../../infra/requests/SectionRequests';
import SectionNames from '../../../infra/services/sections/SectionNames';
import ChaptersComponent from '../../../components/chapters/ChaptersComponent';
import ManageRoomPlans from '../../../components/roomPlans/ManageRoomPlans';

const validations = FormValidator.make({
  description: 'required|languages'
});

class GuestsSection extends Component {
  state = {
    loading: true
  };

  componentDidMount() {
    this.loadSection();
  }

  loadSection = async () => {
    const {dispatch} = this.props;
    const {data} = await GetSection('GUESTS');
    dispatch(initialize('manage_guests_form', data));
    this.setState({loading: false});
  };

  onSubmit = async values => {
    const {history} = this.props;
    this.setState({loading: true});
    await UpdateSection('GUESTS', values);
    history.push('/sections');
  };

  render() {
    const {history, handleSubmit} = this.props;
    const {loading} = this.state;
    if (loading) return <SpinLoading />;
    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={2}>
            <PageTitle>{SectionNames.GUESTS}</PageTitle>
          </HeaderTitle>
          <HeaderButtonsContainer buttons={2}>
            <BaseButton
              type="primary"
              icon="save"
              text="Gravar"
              onClick={handleSubmit(this.onSubmit)}
            />
            <BaseButton
              type="default"
              icon="close"
              text="Cancelar"
              onClick={() => history.push('/sections')}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>

        <FormContainer>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Row>
              <Col xs={10} offset={7}>
                <Field
                  component={LanguagesInput}
                  name={'description'}
                  type="text"
                  label={'Descrição'}
                  placeholder={'Descrição da secção'}
                />
              </Col>
            </Row>
            <Row>
              {/* <Col xs={24}>
                <SectionTitle>Planos de Sala</SectionTitle>
                <ManageRoomPlans />
              </Col> */}
              <Col xs={24}>
                <SectionTitle subsection>Capítulos</SectionTitle>
                <FieldArray
                  disableSection
                  name="chapters"
                  tag="GUESTS"
                  url="guests"
                  formName="manage_guests_form"
                  component={ChaptersComponent}
                />
              </Col>
            </Row>
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

GuestsSection = reduxForm({
  form: 'manage_guests_form',
  validate: validations
})(GuestsSection);

export default withLocalize(GuestsSection);
