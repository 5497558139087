import React from "react";
import { isString } from "../../process/ChoicesPage/components/OptionUtils";
import CurrencyComponent from "../currency/CurrencyComponent";
import CurrencyInput from "./CurrencyInput";
import {
  QuantityContainer,
  ButtonSubtract,
  ButtonAdd,
  QuantityInput,
  CostContainer
} from "./InputStyles";

const numberRegex = /^[0-9\b]+$/;
const floatNumberRegex = /^[0-9]+(\.[0-9]+)?$/;

const Cost = ({ selected, option, input, upgrade }) => {
  const isPremiumOption = upgrade?.hasPremiumOptions && option?.isPremiumOption ? true : false;
  const isTrendOption = upgrade?.hasPremiumOptions && option?.isTrendOption ? true : false;

  const changeCost = (type, cost = null) => {
    const list = [...input.value];
    const index = list.findIndex(x => x.id === option._id);
    const response = list.find(x => x.id === option._id);

    if (index >= 0) {
      //Subtract
      if (type === 1) {
        list[index] = {
          'id': option._id,
          'qtd': response.qtd,
          'cost': parseFloat(list[index].cost) - 1 <= 0 ? 1 : parseFloat(list[index].cost) - 1
        };
      }
      //Add
      else if (type === 2) {
        list[index] = {
          'id': option._id,
          'qtd': response.qtd,
          'cost': parseFloat(list[index].cost) + 1
        };
      }
      //Input value directly
      else if (type === 0) {
        if (cost && floatNumberRegex.test(cost)) {
          list[index] = {
            'id': option._id,
            'qtd': response.qtd,
            'cost': parseFloat(cost)
          };
        }
        else if (cost === '') {
          list[index] = {
            'id': option._id,
            'qtd': response.qtd,
            'cost': 0 //isPremiumOption ? option.costPriceExtra : option.cost_price
          };
        }
      }

      input.onChange(list);
    }
  }

  const getCost = () => {
    let cost = 0;

    if (input?.value) {
      let found = input?.value.find(x => x?.id && option?._id && x?.id === option?._id);

      if (found && found.cost !== undefined) {
        cost = isString(found.cost) ? parseFloat(found.cost) : found.cost;
      } else {
        // cost = isPremiumOption
        //   ? option.costPriceExtra
        //   : option.cost_price;
        cost = option.cost_price;
      }
    } else {
      // cost = isPremiumOption
      //   ? option.costPriceExtra
      //   : option.cost_price;
      cost = option.cost_price;
    }

    return cost;
  }

  return (
    <CostContainer selected={selected} onClick={(e) => e.stopPropagation()}>
      {selected ?
        <React.Fragment>
          {/* <ButtonSubtract onClick={() => changeCost(optionID, 1)}>-</ButtonSubtract> */}
          <div style={{ width: 80 }}>
            <CurrencyInput
              input={{
                value: getCost(),
                onChange: (value) => changeCost(0, value)
              }}
              meta={{ invalid: false, submitFailed: false }}
              first={true}
            />
          </div>
          {/* <ButtonAdd onClick={() => changeCost(optionID, 2)}>+</ButtonAdd> */}
        </React.Fragment>
        : <CurrencyComponent value={getCost()} />}
    </CostContainer>
  );
};

export default Cost;