import React, { Component } from 'react';
import moment from 'moment';
import { TimePicker, Col, Row, Checkbox, Input, Icon, Tooltip, Result } from 'antd';
import {
  SpinLoading,
  SectionTitle,
} from '../../styles/BasicStyles';
import SelectInput from '../../components/inputs/SelectInput';
import NumberInput from '../../components/inputs/NumberInput';
import BaseButton from '../../components/buttons/BaseButton';
import { GetEmployeesAvailableBySubcategory } from '../../infra/requests/EmployeeRequests';
import { GetRowsEmpCharge, GetWeddingTeamByWeddingAndSubcategory } from '../../infra/requests/WeddingTeamRequests';
import { GetSubcategoriesByType } from '../../infra/requests/SubcategoryRequests';
import { GetWeddingInterestByWeddingAndSubcategory } from '../../infra/requests/WeddingInterestRequests';
import { getEmployeesOrdered, getStartTimeBySubcategory } from '../../infra/services/wedding/weddingUtils';
import { GetTeamBookingByWedding, GetTeamBookingByWeddingAndSubcategory } from '../../infra/requests/TeamBookingRequests';
import { GetEmployeePriceByEmployeeAndSubcategory } from '../../infra/requests/EmployeePriceRequests';
import { isCurrentUserAdmin, isCurrentUserChief, isCurrentUserHumanResources } from '../../infra/helpers/Helpers';
import TextInput from '../../components/inputs/TextInput';
import { CheckboxDiv, DivSelectInput, IconWarning, RowInCharge } from './WeddingTeamStyles';
import { InputDiv, InputLabelDiv } from '../../components/inputs/InputStyles';
import EmployeeSelectInput from '../../components/inputs/EmployeeSelectInput';
import { getAvgScoreStyle, getScoresSelector, reorderEmployeesList } from './employeeScoreUtil';

const newWeddingTeam = {
  _id: 'new#FFSDD',
  id: 'new#FFSDD',
  value: 0,
  bonus: 0,
  total: 0,
  startTime: moment(),
  endTime: null,
  presence: false,
  notes: '',
  employee: null,
  wedding: null,
  subcategory: null,
  published: false
};

class AddEditEmployeesInCharge extends Component {
  state = {
    loading: true,
    currentUser: null,
    wedding: null,
    ourDay: [],
    scheduler: null,
    pax: null,
    teamBooking: null,
    teamComplete: null,
    subcategories: null,
    teamAction: { addEdit: [], delete: [] },
    deleteBooking: [],
    updateBooking: [],

    rowsCoordinators: [],
    rowsChiefs: [],
    rowsHosts: [],
    // rowsTreeHouseHosts: [],

    coordinators: [],
    chiefs: [],
    hosts: [],
    // treeHouseHosts: [],
    listEmployeesOriginal: {
      coordinators: [],
      chiefs: [],
      hosts: [],
      // treeHouseHosts: []
    },

    payments: [],
    published: false,
    totalRows: 0
  };

  componentDidMount = async () => {
    //to test only
    //const now = moment.utc(this.props.selectedWedding.date, 'YYYY-MM-DDTHH:mm:ssZ')
    const now = moment.utc();
    const weddingDate = moment.utc(this.props.selectedWedding.date);
    const startWeddingDate = moment.utc(weddingDate).startOf('day');
    const endWeddingDate = moment.utc(startWeddingDate.format('YYYY-MM-DD') + ' 13:00:00', 'YYYY-MM-DD HH:mm:ss');
    endWeddingDate.add(1, 'day');

    this.setState({
      currentUser: this.props.currentUser,
      wedding: this.props.selectedWedding,
      ourDay: this.props.ourDay,
      scheduler: this.props.scheduler,
      pax: this.props.pax,
      teamBooking: this.props.teamBooking,
      teamComplete: this.props.teamComplete,
      payments: this.props.payments,
      presenceAllowed: isCurrentUserAdmin() ? true : now.isSameOrAfter(startWeddingDate),
      published: this.props.published,
      subcategories: this.props.subcategories
    }, () => {
      this.getRows();
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.selectedWedding !== this.props.selectedWedding) {
      //to test only
      // const now = moment.utc(this.props.selectedWedding.date, 'YYYY-MM-DDTHH:mm:ssZ')
      const now = moment.utc();
      const weddingDate = moment.utc(this.props.selectedWedding.date);
      const startWeddingDate = moment.utc(weddingDate).startOf('day');
      const endWeddingDate = moment.utc(startWeddingDate.format('YYYY-MM-DD') + ' 13:00:00', 'YYYY-MM-DD HH:mm:ss');
      endWeddingDate.add(1, 'day');

      this.setState({
        currentUser: this.props.currentUser,
        wedding: this.props.selectedWedding,
        ourDay: this.props.ourDay,
        scheduler: this.props.scheduler,
        pax: this.props.pax,
        teamBooking: this.props.teamBooking,
        teamComplete: this.props.teamComplete,
        payments: this.props.payments,
        presenceAllowed: isCurrentUserAdmin() ? true : now.isSameOrAfter(startWeddingDate),
        published: this.props.published,
        subcategories: this.props.subcategories
      });
    }

    if (prevProps.payments !== this.props.payments) {
      this.setState({
        payments: this.props.payments
      });
    }

    if (prevProps.published !== this.props.published) {
      this.setState({
        published: this.props.published
      });
    }
  }

  /**
   * @description Get rows coordinator, chief, host and treeHouseHost
   */
  getRows = async () => {
    let { wedding, totalRows, published, subcategories, teamBooking, teamComplete } = this.state;
    let { coordinators, chiefs, hosts, listEmployeesOriginal } = this.state;
    // let { treeHouseHosts } = this.state;

    const data = {
      teamComplete,
      teamBooking,
      subcategories,
      published
    };
    const result = await GetRowsEmpCharge(wedding?._id, data);    

    totalRows = result.success && result.data ? result.data.totalRows : 0;
    const rowsCoordinators = result.success && result.data ? result.data.rowsCoordinators : [];
    const rowsChiefs = result.success && result.data ? result.data.rowsChiefs : [];
    const rowsHosts = result.success && result.data ? result.data.rowsHosts : [];
    // const rowsTreeHouseHosts = result.success && result.data ? result.data.rowsTreeHouseHosts : [];

    coordinators = result.success && result.data ? result.data.coordinators : [];
    chiefs = result.success && result.data ? result.data.chiefs : [];
    hosts = result.success && result.data ? result.data.hosts : [];
    // treeHouseHosts = result.success && result.data ? result.data.treeHouseHosts : [];
    listEmployeesOriginal = result.success && result.data ? result.data.listEmployeesOriginal : [];

    for (const e of coordinators) {
      e.scoreColor = getAvgScoreStyle(e?.avgScore);
    }

    for (const e of chiefs) {
      e.scoreColor = getAvgScoreStyle(e?.avgScore);
    }

    for (const e of hosts) {
      e.scoreColor = getAvgScoreStyle(e?.avgScore);
    }

    this.setState({
      rowsCoordinators,
      rowsChiefs,
      rowsHosts,
      // rowsTreeHouseHosts,
      totalRows,
      coordinators,
      chiefs,
      hosts,
      // treeHouseHosts,
      listEmployeesOriginal,
      loading: false
    });
    this.props.updateRowsBooking(totalRows);
    this.props.updateRowsInCharge([...rowsCoordinators, ...rowsChiefs, ...rowsHosts]);
    // this.props.updateRowsInCharge([...rowsCoordinators, ...rowsChiefs, ...rowsHosts, ...rowsTreeHouseHosts]);
  }

  updateEmployeesList = (event, dataOriginal, checkOriginal) => {
    const { listEmployeesOriginal } = this.state;
    let { coordinators, chiefs, hosts, treeHouseHosts } = this.state;

    if (checkOriginal) {
      if (dataOriginal?.weddingTeam?.employee && dataOriginal?.weddingTeam?.employee?.id !== event) {
        const indexCoord = listEmployeesOriginal.coordinators.findIndex(f => f.id === dataOriginal.weddingTeam.employee.id);
        if (indexCoord > -1 && !coordinators.find(f => f.id === dataOriginal.weddingTeam.employee.id)) {
          coordinators.push(dataOriginal.weddingTeam.employee);
        }

        const indexChief = listEmployeesOriginal.chiefs.findIndex(f => f.id === dataOriginal.weddingTeam.employee.id);
        if (indexChief > -1 && !chiefs.find(f => f.id === dataOriginal.weddingTeam.employee.id)) {
          chiefs.push(dataOriginal.weddingTeam.employee);
        }

        const indexHost = listEmployeesOriginal.hosts.findIndex(f => f.id === dataOriginal.weddingTeam.employee.id);
        if (indexHost > -1 && !hosts.find(f => f.id === dataOriginal.weddingTeam.employee.id)) {
          hosts.push(dataOriginal.weddingTeam.employee);
        }

        // const indexTreeHouseHost = listEmployeesOriginal.treeHouseHosts.findIndex(f => f.id === dataOriginal.weddingTeam.employee.id);
        // if (indexTreeHouseHost > -1 && !treeHouseHosts.find(f => f.id === dataOriginal.weddingTeam.employee.id)) {
        //   treeHouseHosts.push(dataOriginal.weddingTeam.employee);
        // }
      }
    } else {
      if (event !== '') {
        coordinators = coordinators.filter(f => f?.id !== event);
        chiefs = chiefs.filter(f => f?.id !== event);
        hosts = hosts.filter(f => f?.id !== event);
        // treeHouseHosts = treeHouseHosts.filter(f => f?.id !== event);
      }
    }
    // this.setState({ coordinators, chiefs, hosts, treeHouseHosts });
    this.setState({ coordinators, chiefs, hosts });
  }

  checkPaymentDone = (data) => {
    const { payments } = this.state;
    return isCurrentUserHumanResources()
      ? (payments?.length > 0 ? true : false)
      : payments?.find(f => f?.employee?._id == data.weddingTeam.employee?._id && f?.weddingTeam == data?.weddingTeam?._id)
        ? true
        : false;
  }

  /**
   * @description Set coordinator rows
   * @param {*} data 
   * @returns HTML element with employee in charge form
   */
  setRowsCoordinator = (data, index) => {
    const { subcategories, payments, teamBooking, published, scheduler, listEmployeesOriginal } = this.state;
    const { rowsChiefs, rowsHosts } = this.state;
    // const {rowsTreeHouseHosts} = this.state;

    const labelEmployee = `${subcategories.coordinator.name} ${data.weddingTeam && data.weddingTeam.setting ? `(${data.weddingTeam.setting.name})` : ''}`;
    const paymentDone = this.checkPaymentDone(data);

    reorderEmployeesList(this.state.coordinators);

    return (<RowInCharge first={true} key={index} gutter={[12, 12]}>
      <Col xl={5} lg={5} md={7} xs={24} sm={isCurrentUserHumanResources() ? 8 : 10} style={{ display: 'inline-flex' }}>
        <DivSelectInput showWarning={(data?.isUnavailable || data?.weddingTeam?.employee?.deleted)}>
          <EmployeeSelectInput
            allowClear
            first={true}
            label={labelEmployee}
            placeholder={"Selecione o " + subcategories.coordinator.name}
            disabled={paymentDone} // || published
            data={this.state.coordinators}
            input={{
              value: data.weddingTeam.employee?.name,
              onChange: async event => {
                let { coordinators, rowsCoordinators, teamAction } = this.state;
                const dataOriginal = JSON.parse(JSON.stringify(data));

                this.updateEmployeesList(event, dataOriginal, true);

                if (event === '') {
                  data.weddingTeam.employee = null;
                  data.weddingTeam.confirmed = false;
                  data.weddingTeam.presence = false;
                  data.weddingTeam.value = 0.00;
                  data.weddingTeam.bonus = 0.00;
                  data.price = null;
                } else {
                  const employee = coordinators.find(f => f.id === event);
                  const resPrice = employee ? await GetEmployeePriceByEmployeeAndSubcategory(employee.id, data.weddingTeam.subcategory.id) : null;
                  const employeePrice = resPrice && resPrice.success && resPrice.data ? resPrice.data : null;

                  data.weddingTeam.employee = employee;
                  data.weddingTeam.value = employeePrice ? employeePrice.value : 0;
                  data.weddingTeam.setting = employeePrice ? employeePrice.setting : null;
                  data.price = employeePrice;
                }

                this.updateEmployeesList(event, dataOriginal, false);

                rowsCoordinators[index] = data;

                if (event === '') {
                  teamAction.addEdit = teamAction.addEdit.filter(f => f.id !== dataOriginal.weddingTeam.id);

                  const indexDelete = teamAction.delete.findIndex(f => f.id === dataOriginal.weddingTeam.id);
                  if (indexDelete === -1 && !dataOriginal.weddingTeam.id.includes('new#FFSDD')) teamAction.delete.push(dataOriginal.weddingTeam);
                } else {
                  const indexAddEdit = teamAction.addEdit.findIndex(f => f.id === data.weddingTeam.id);
                  if (indexAddEdit === -1) teamAction.addEdit.push(data.weddingTeam);
                  else teamAction.addEdit[indexAddEdit] = data.weddingTeam;

                  teamAction.delete = teamAction.delete.filter(f => f.id !== data.weddingTeam.id);
                }
                this.setState({ rowsCoordinators, teamAction });
                this.props.updateTeam({ addEdit: teamAction.addEdit, delete: teamAction.delete });
                this.props.updateRowsInCharge([...rowsCoordinators, ...rowsChiefs, ...rowsHosts]);
                // this.props.updateRowsInCharge([...rowsCoordinators, ...rowsChiefs, ...rowsHosts, ...rowsTreeHouseHosts]);
              }
            }}
            meta={{ valid: true }}
          />
        </DivSelectInput>
        {data?.isUnavailable && <Tooltip title="Funcionário Indisponível">
          <IconWarning type="warning" warning={true} />
        </Tooltip>}
        {data?.weddingTeam?.employee?.deleted && <Tooltip title="Funcionário inativo">
          <IconWarning type="user-delete" error={true} />
        </Tooltip>}
      </Col>
      <Col xl={2} lg={3} md={3} xs={8} sm={4}>
        <InputDiv first={true}>
          <InputLabelDiv>Entrada</InputLabelDiv>
          <TimePicker style={{ width: '100%' }}
            type="time"
            format='HH:mm'
            disabled={paymentDone} // || published
            value={data?.weddingTeam?.startTime ? moment(data.weddingTeam.startTime).local() : null}
            onChange={event => {
              const { rowsCoordinators, teamAction } = this.state;

              data.weddingTeam.startTime = event ? event.utc() : null;
              rowsCoordinators[index] = data;

              const indexAddEdit = teamAction.addEdit.findIndex(f => f.id === data.weddingTeam.id);
              if (indexAddEdit === -1) teamAction.addEdit.push(data.weddingTeam);
              else teamAction.addEdit[indexAddEdit] = data.weddingTeam;

              teamAction.delete = teamAction.delete.filter(f => f.id !== data.weddingTeam.id);

              this.setState({ rowsCoordinators, teamAction });
              this.props.updateTeam({ addEdit: teamAction.addEdit, delete: teamAction.delete });
              this.props.updateRowsInCharge([...rowsCoordinators, ...rowsChiefs, ...rowsHosts]);
              // this.props.updateRowsInCharge([...rowsCoordinators, ...rowsChiefs, ...rowsHosts, ...rowsTreeHouseHosts]);
            }}
          />
        </InputDiv>
      </Col>
      <Col xl={2} lg={2} md={3} xs={8} sm={4}>
        <InputDiv first={true}>
          <InputLabelDiv>Confirmado</InputLabelDiv>
          <CheckboxDiv
            checked={data.weddingTeam.confirmed}
            disabled={paymentDone}
            onChange={(e) => {
              const { rowsCoordinators, teamAction } = this.state;
              data.weddingTeam.confirmed = e.target.checked;
              rowsCoordinators[index] = data;

              if (data.weddingTeam.employee === '' || data.weddingTeam.employee === null || data.weddingTeam.employee === undefined ||
                data.weddingTeam.startTime === null) {
                teamAction.addEdit = teamAction.addEdit.filter(f => f.id !== data.weddingTeam.id);
              } else {
                const indexAddEdit = teamAction.addEdit.findIndex(f => f.id === data.weddingTeam.id);
                if (indexAddEdit === -1) teamAction.addEdit.push(data.weddingTeam);
                else teamAction.addEdit[indexAddEdit] = data.weddingTeam;
              }

              teamAction.delete = teamAction.delete.filter(f => f.id !== data.weddingTeam.id);

              this.setState({ rowsCoordinators, teamAction });
              this.props.updateTeam({ addEdit: teamAction.addEdit, delete: teamAction.delete });
              this.props.updateRowsInCharge([...rowsCoordinators, ...rowsChiefs, ...rowsHosts]);
              // this.props.updateRowsInCharge([...rowsCoordinators, ...rowsChiefs, ...rowsHosts, ...rowsTreeHouseHosts]);
            }}>
            Sim
          </CheckboxDiv>
        </InputDiv>
      </Col>
      {!isCurrentUserAdmin() ? null : <React.Fragment>
        <Col xl={2} lg={2} md={3} xs={8} sm={4}>
          <InputDiv first={true}>
            <InputLabelDiv>Presença</InputLabelDiv>
            <CheckboxDiv
              checked={data.weddingTeam.presence}
              disabled={paymentDone} // || !this.state.presenceAllowed
              onChange={(e) => {
                const { rowsCoordinators, teamAction } = this.state;
                data.weddingTeam.presence = e.target.checked;
                rowsCoordinators[index] = data;

                if (data.weddingTeam.employee === '' || data.weddingTeam.employee === null || data.weddingTeam.employee === undefined ||
                  data.weddingTeam.startTime === null) {
                  teamAction.addEdit = teamAction.addEdit.filter(f => f.id !== data.weddingTeam.id);
                } else {
                  const indexAddEdit = teamAction.addEdit.findIndex(f => f.id === data.weddingTeam.id);
                  if (indexAddEdit === -1) teamAction.addEdit.push(data.weddingTeam);
                  else teamAction.addEdit[indexAddEdit] = data.weddingTeam;
                }

                teamAction.delete = teamAction.delete.filter(f => f.id !== data.weddingTeam.id);

                this.setState({ rowsCoordinators, teamAction });
                this.props.updateTeam({ addEdit: teamAction.addEdit, delete: teamAction.delete });
                this.props.updateRowsInCharge([...rowsCoordinators, ...rowsChiefs, ...rowsHosts]);
                // this.props.updateRowsInCharge([...rowsCoordinators, ...rowsChiefs, ...rowsHosts, ...rowsTreeHouseHosts]);
              }}>
              Sim
            </CheckboxDiv>
          </InputDiv>
        </Col>
        <Col xl={2} lg={2} md={3} xs={8} sm={4}>
          <InputDiv first={true}>
            <InputLabelDiv>Pontuação</InputLabelDiv>
            <SelectInput
              allowClear
              first={true}
              data={getScoresSelector()}
              disabled={!data.weddingTeam.presence}
              input={{
                value: data.weddingTeam?.score,
                onChange: async event => {
                  if (data?.weddingTeam) {
                    const { rowsCoordinators, teamAction } = this.state;

                    data.weddingTeam.score = Number(event);                    

                    rowsCoordinators[index] = data;

                    if (data.weddingTeam.employee === '' || data.weddingTeam.employee === null || data.weddingTeam.employee === undefined ||
                      data.weddingTeam.startTime === null) {
                      teamAction.addEdit = teamAction.addEdit.filter(f => f.id !== data.weddingTeam.id);
                    } else {
                      const indexAddEdit = teamAction.addEdit.findIndex(f => f.id === data.weddingTeam.id);
                      if (indexAddEdit === -1) teamAction.addEdit.push(data.weddingTeam);
                      else teamAction.addEdit[indexAddEdit] = data.weddingTeam;
                    }

                    teamAction.delete = teamAction.delete.filter(f => f.id !== data.weddingTeam.id);                    

                    this.setState({ rowsCoordinators, teamAction });
                    this.props.updateTeam({ addEdit: teamAction.addEdit, delete: teamAction.delete });
                    this.props.updateRowsInCharge([...rowsCoordinators, ...rowsChiefs, ...rowsHosts]);
                  }
                }
              }}
              meta={{ valid: true }}
            />
          </InputDiv>
        </Col>
        <Col xl={2} lg={2} md={2} xs={8} sm={4}>
          <InputDiv first={true}>
            <InputLabelDiv>Valor(€)</InputLabelDiv>
            <NumberInput
              min={0}
              onInput="validity.valid || (value = '')"
              step={0.01}
              input={{
                value: parseFloat(data.weddingTeam.value).toFixed(2),
              }}
              disabled={true}
              meta={{}}
              type="number"
              placeholder="Insira o valor"
            />
          </InputDiv>
        </Col>
        <Col xl={2} lg={2} md={2} xs={8} sm={4}>
          <InputDiv first={true}>
            <InputLabelDiv>Bónus(€)</InputLabelDiv>
            <NumberInput
              // min={0}
              onInput="validity.valid || (value = '')"
              step={0.01}
              disabled={paymentDone}
              input={{
                value: parseFloat(data.weddingTeam.bonus).toFixed(2),
                onChange: event => {
                  const { rowsCoordinators, teamAction } = this.state;

                  // If the payment - bonus < 0, then bonus is 0
                  if ((parseFloat(data.weddingTeam.value) + parseFloat(event)) >= 0) {
                    data.weddingTeam.bonus = parseFloat(event).toFixed(2);
                  } else {
                    data.weddingTeam.bonus = 0;
                  }

                  rowsCoordinators[index] = data;

                  const indexAddEdit = teamAction.addEdit.findIndex(f => f.id === data.weddingTeam.id);
                  if (indexAddEdit === -1) teamAction.addEdit.push(data.weddingTeam);
                  else teamAction[indexAddEdit] = data.weddingTeam;

                  teamAction.delete = teamAction.delete.filter(f => f.id !== data.weddingTeam.id);

                  this.setState({ rowsCoordinators, teamAction });
                  this.props.updateTeam({ addEdit: teamAction.addEdit, delete: teamAction.delete });
                  this.props.updateRowsInCharge([...rowsCoordinators, ...rowsChiefs, ...rowsHosts]);
                  // this.props.updateRowsInCharge([...rowsCoordinators, ...rowsChiefs, ...rowsHosts, ...rowsTreeHouseHosts]);
                }
              }}
              meta={{}}
              type="number"
              placeholder="Insira o valor do bónus"
            />
          </InputDiv>
        </Col>
        <Col xl={2} lg={2} md={2} xs={8} sm={4}>
          <InputDiv first={true}>
            <InputLabelDiv>Total(€)</InputLabelDiv>
            <NumberInput
              min={0}
              step={0.01}
              onInput="validity.valid || (value = '')"
              input={{
                value: data.price && data.price.setting && data.price.setting.integerValue === false ? ((parseFloat(data.weddingTeam.value) * this.state.pax) + parseFloat(data.weddingTeam.bonus)).toFixed(2) : (parseFloat(data.weddingTeam.value) + parseFloat(data.weddingTeam.bonus)).toFixed(2),
              }}
              disabled={true}
              meta={{}}
              type="number"
              placeholder="Insira o valor"
            />
          </InputDiv>
        </Col>
      </React.Fragment>
      }
      {!paymentDone && <Col xl={2} lg={3} md={4} xs={12} sm={4}>
        <BaseButton
          type={'default'}
          icon={'delete'}
          disabled={paymentDone} // disabled={published}
          block
          text={'Remover'}
          onClick={() => {
            let { coordinators, rowsCoordinators, teamAction, deleteBooking, updateBooking } = this.state;
            const dataOriginal = JSON.parse(JSON.stringify(data));

            if (dataOriginal.weddingTeam.employee !== null && coordinators.findIndex(f => f.id === dataOriginal.weddingTeam.employee.id) === -1) coordinators.push(dataOriginal.weddingTeam.employee);

            if (rowsCoordinators.length === 1) {
              const wedding = data.weddingTeam.wedding;
              data.weddingTeam = JSON.parse(JSON.stringify(newWeddingTeam));
              data.weddingTeam.id = data.weddingTeam.id + moment().valueOf() + '1';
              data.weddingTeam.startTime = moment();
              data.weddingTeam.wedding = wedding;
              data.weddingTeam.subcategory = subcategories.coordinator;
              rowsCoordinators[index] = data;
            } else {
              rowsCoordinators = rowsCoordinators.filter(f => f.weddingTeam.id !== dataOriginal.weddingTeam.id);
            }

            teamAction.addEdit = teamAction.addEdit.filter(f => f.id !== dataOriginal.weddingTeam.id);

            const indexDelete = teamAction.delete.findIndex(f => f.id === dataOriginal.weddingTeam.id);
            if (indexDelete === -1 && !dataOriginal.weddingTeam.id.includes('new#FFSDD')) teamAction.delete.push(dataOriginal.weddingTeam);
            else if (indexDelete > -1 && !dataOriginal.weddingTeam.id.includes('new#FFSDD')) teamAction.delete[indexDelete] = dataOriginal.weddingTeam;

            const booking = teamBooking.find(f => f.subcategory.id === dataOriginal.weddingTeam.subcategory.id);
            if (booking) {
              if (booking?.quantity === 1) {
                const indexDeleteBooking = deleteBooking.findIndex(f => f.id === booking.id);
                if (indexDeleteBooking === -1) deleteBooking.push(booking);

                updateBooking = updateBooking.filter(f => f.id !== booking.id);
              } else {
                booking.quantity = booking?.quantity - 1;
                const indexUpdateBooking = updateBooking.findIndex(f => f.id === booking.id);
                if (indexUpdateBooking === -1) updateBooking.push(booking);

                deleteBooking = deleteBooking.filter(f => f.id !== booking.id);
              }
            }

            this.setState({ coordinators, rowsCoordinators, teamAction });
            this.props.updateTeam({ addEdit: teamAction.addEdit, delete: teamAction.delete });
            this.props.deleteBooking(deleteBooking);
            this.props.updateBooking(updateBooking);
            this.props.updateRowsInCharge([...rowsCoordinators, ...rowsChiefs, ...rowsHosts]);
            // this.props.updateRowsInCharge([...rowsCoordinators, ...rowsChiefs, ...rowsHosts, ...rowsTreeHouseHosts]);
          }}
        />
      </Col>}
      <Col xl={2} lg={3} md={4} xs={12} sm={4}>
        <BaseButton
          type={'default'}
          icon={'plus'}
          disabled={isCurrentUserHumanResources() && payments?.length > 0}// disabled={published}
          block
          text={'Adicionar'}
          onClick={() => {
            const { rowsCoordinators, wedding } = this.state;

            const weddingTeam = JSON.parse(JSON.stringify(newWeddingTeam));
            weddingTeam.id = weddingTeam.id + moment().valueOf() + '1';
            weddingTeam.wedding = wedding;
            weddingTeam.subcategory = subcategories.coordinator;
            weddingTeam.startTime = getStartTimeBySubcategory(subcategories.coordinator, scheduler);

            rowsCoordinators.push({ weddingTeam, price: null, employees: [], extraToBooking: false });

            this.setState({ rowsCoordinators });
            this.props.updateRowsInCharge([...rowsCoordinators, ...rowsChiefs, ...rowsHosts]);
            // this.props.updateRowsInCharge([...rowsCoordinators, ...rowsChiefs, ...rowsHosts, ...rowsTreeHouseHosts]);
          }}
        />
      </Col>
    </RowInCharge>)
  }

  /**
   * @description Set chief rows
   * @param {*} data 
   * @returns HTML element with employee in charge form
   */
  setRowsChief = (data, index) => {
    const { subcategories, payments, published, teamBooking, deleteBooking, scheduler } = this.state;
    const { rowsCoordinators, rowsHosts } = this.state;
    // const {rowsTreeHouseHosts} = this.state;

    const labelEmployee = `${subcategories?.chief?.name || ''} ${data?.weddingTeam?.setting ? `(${data.weddingTeam.setting.name})` : ''}`;
    const paymentDone = this.checkPaymentDone(data);

    reorderEmployeesList(this.state.chiefs);

    return (<RowInCharge key={index} gutter={[12, 12]}>
      <Col xl={5} lg={5} md={7} xs={24} sm={isCurrentUserHumanResources() ? 8 : 10} style={{ display: 'inline-flex' }}>
        <DivSelectInput showWarning={(data?.isUnavailable || data?.weddingTeam?.employee?.deleted)}>
          <EmployeeSelectInput
            allowClear={true}
            label={labelEmployee}
            placeholder={"Selecione o " + subcategories.chief.name}
            data={this.state.chiefs}
            disabled={paymentDone} // || published
            input={{
              value: data.weddingTeam.employee?.name,
              onChange: async event => {
                let { chiefs, rowsChiefs, teamAction } = this.state;
                const dataOriginal = JSON.parse(JSON.stringify(data));

                this.updateEmployeesList(event, dataOriginal, true);

                if (event == '') {
                  data.weddingTeam.employee = null;
                  data.weddingTeam.confirmed = false;
                  data.weddingTeam.presence = false;
                  data.weddingTeam.value = 0.00;
                  data.weddingTeam.bonus = 0.00;
                  data.price = null;
                } else {
                  const employee = chiefs.find(f => f.id === event);
                  const resPrice = employee ? await GetEmployeePriceByEmployeeAndSubcategory(employee.id, data.weddingTeam.subcategory.id) : null;
                  const employeePrice = resPrice && resPrice.success && resPrice.data ? resPrice.data : null;

                  data.weddingTeam.employee = employee;
                  data.weddingTeam.value = employeePrice ? employeePrice.value : 0;
                  data.weddingTeam.setting = employeePrice ? employeePrice.setting : null;
                  data.price = employeePrice;
                }

                this.updateEmployeesList(event, dataOriginal, false);

                rowsChiefs[index] = data;

                if (event === '') {
                  teamAction.addEdit = teamAction.addEdit.filter(f => f.id !== dataOriginal.weddingTeam.id);

                  const indexDelete = teamAction.delete.findIndex(f => f.id === dataOriginal.weddingTeam.id);
                  if (indexDelete === -1 && !dataOriginal.weddingTeam.id.includes('new#FFSDD')) teamAction.delete.push(dataOriginal.weddingTeam);
                } else {
                  const indexAddEdit = teamAction.addEdit.findIndex(f => f.id === data.weddingTeam.id);
                  if (indexAddEdit === -1) teamAction.addEdit.push(data.weddingTeam);
                  else teamAction.addEdit[indexAddEdit] = data.weddingTeam;

                  teamAction.delete = teamAction.delete.filter(f => f.id !== data.weddingTeam.id);
                }

                this.setState({ rowsChiefs, teamAction });
                this.props.updateTeam({ addEdit: teamAction.addEdit, delete: teamAction.delete });
                this.props.updateRowsInCharge([...rowsCoordinators, ...rowsChiefs, ...rowsHosts]);
                // this.props.updateRowsInCharge([...rowsCoordinators, ...rowsChiefs, ...rowsHosts, ...rowsTreeHouseHosts]);
              }
            }}
            meta={{ valid: true }}
          />
        </DivSelectInput>
        {data?.isUnavailable && <Tooltip title="Funcionário Indisponível">
          <IconWarning type="warning" warning={true} />
        </Tooltip>}
        {data?.weddingTeam?.employee?.deleted && <Tooltip title="Funcionário inativo">
          <IconWarning type="user-delete" error={true} />
        </Tooltip>}
      </Col>
      <Col xl={2} lg={3} md={3} xs={8} sm={4}>
        <InputDiv>
          <InputLabelDiv>Entrada</InputLabelDiv>
          <TimePicker style={{ width: '100%' }}
            type="time"
            format='HH:mm'
            value={data?.weddingTeam?.startTime ? moment(data.weddingTeam.startTime).local() : null}
            disabled={paymentDone} //|| published
            onChange={event => {
              const { rowsChiefs, teamAction } = this.state;

              data.weddingTeam.startTime = event ? event.utc() : null;
              rowsChiefs[index] = data;

              const indexAddEdit = teamAction.addEdit.findIndex(f => f.id === data.weddingTeam.id);
              if (indexAddEdit === -1) teamAction.addEdit.push(data.weddingTeam);
              else teamAction.addEdit[indexAddEdit] = data.weddingTeam;

              teamAction.delete = teamAction.delete.filter(f => f.id !== data.weddingTeam.id);

              this.setState({ rowsChiefs, teamAction });
              this.props.updateTeam({ addEdit: teamAction.addEdit, delete: teamAction.delete });
              this.props.updateRowsInCharge([...rowsCoordinators, ...rowsChiefs, ...rowsHosts]);
              // this.props.updateRowsInCharge([...rowsCoordinators, ...rowsChiefs, ...rowsHosts, ...rowsTreeHouseHosts]);
            }}
          />
        </InputDiv>
      </Col>
      <Col xl={2} lg={2} md={3} xs={8} sm={4}>
        <InputDiv>
          <InputLabelDiv>Confirmado</InputLabelDiv>
          <CheckboxDiv
            checked={data.weddingTeam.confirmed}
            disabled={paymentDone}
            onChange={(e) => {
              const { rowsChiefs, teamAction } = this.state;
              data.weddingTeam.confirmed = e.target.checked;
              rowsChiefs[index] = data;

              if (data.weddingTeam.employee === '' || data.weddingTeam.employee === null || data.weddingTeam.employee === undefined ||
                data.weddingTeam.startTime === null) {
                teamAction.addEdit = teamAction.addEdit.filter(f => f.id !== data.weddingTeam.id);
              } else {
                const indexAddEdit = teamAction.addEdit.findIndex(f => f.id === data.weddingTeam.id);
                if (indexAddEdit === -1) teamAction.addEdit.push(data.weddingTeam);
                else teamAction.addEdit[indexAddEdit] = data.weddingTeam;
              }

              teamAction.delete = teamAction.delete.filter(f => f.id !== data.weddingTeam.id);

              this.setState({ rowsChiefs, teamAction });
              this.props.updateTeam({ addEdit: teamAction.addEdit, delete: teamAction.delete });
              this.props.updateRowsInCharge([...rowsCoordinators, ...rowsChiefs, ...rowsHosts]);
              // this.props.updateRowsInCharge([...rowsCoordinators, ...rowsChiefs, ...rowsHosts, ...rowsTreeHouseHosts]);
            }}>
            Sim
          </CheckboxDiv>
        </InputDiv>
      </Col>
      {!isCurrentUserAdmin() ? null : <React.Fragment>
        <Col xl={2} lg={2} md={3} xs={8} sm={4}>
          <InputDiv>
            <InputLabelDiv>Presença</InputLabelDiv>
            <CheckboxDiv
              checked={data.weddingTeam.presence}
              disabled={paymentDone} // || !this.state.presenceAllowed
              onChange={(e) => {
                const { rowsChiefs, teamAction } = this.state;
                data.weddingTeam.presence = e.target.checked;                
                rowsChiefs[index] = data;

                if (data.weddingTeam.employee === '' || data.weddingTeam.employee === null || data.weddingTeam.employee === undefined ||
                  data.weddingTeam.startTime === null) {
                  teamAction.addEdit = teamAction.addEdit.filter(f => f.id !== data.weddingTeam.id);
                } else {
                  const indexAddEdit = teamAction.addEdit.findIndex(f => f.id === data.weddingTeam.id);
                  if (indexAddEdit === -1) teamAction.addEdit.push(data.weddingTeam);
                  else teamAction.addEdit[indexAddEdit] = data.weddingTeam;
                }

                teamAction.delete = teamAction.delete.filter(f => f.id !== data.weddingTeam.id);

                this.setState({ rowsChiefs, teamAction });
                this.props.updateTeam({ addEdit: teamAction.addEdit, delete: teamAction.delete });
                this.props.updateRowsInCharge([...rowsCoordinators, ...rowsChiefs, ...rowsHosts]);
                // this.props.updateRowsInCharge([...rowsCoordinators, ...rowsChiefs, ...rowsHosts, ...rowsTreeHouseHosts]);
              }}>
              Sim
            </CheckboxDiv>
          </InputDiv>
        </Col>
        <Col xl={2} lg={2} md={2} xs={8} sm={4}>
          <InputDiv>
            <InputLabelDiv>Pontuação</InputLabelDiv>
            <SelectInput
              allowClear
              first={true}
              data={getScoresSelector()}
              disabled={!data.weddingTeam.presence}
              input={{
                value: data.weddingTeam?.score,
                onChange: async event => {
                  if (data?.weddingTeam) {
                    const { rowsChiefs, teamAction } = this.state;

                    data.weddingTeam.score = Number(event);

                    rowsChiefs[index] = data;

                    if (data.weddingTeam.employee === '' || data.weddingTeam.employee === null || data.weddingTeam.employee === undefined ||
                      data.weddingTeam.startTime === null) {
                      teamAction.addEdit = teamAction.addEdit.filter(f => f.id !== data.weddingTeam.id);
                    } else {
                      const indexAddEdit = teamAction.addEdit.findIndex(f => f.id === data.weddingTeam.id);
                      if (indexAddEdit === -1) teamAction.addEdit.push(data.weddingTeam);
                      else teamAction.addEdit[indexAddEdit] = data.weddingTeam;
                    }

                    teamAction.delete = teamAction.delete.filter(f => f.id !== data.weddingTeam.id);

                    this.setState({ rowsChiefs, teamAction });
                    this.props.updateTeam({ addEdit: teamAction.addEdit, delete: teamAction.delete });
                    this.props.updateRowsInCharge([...rowsCoordinators, ...rowsChiefs, ...rowsHosts]);
                  }
                }
              }}
              meta={{ valid: true }}
            />
          </InputDiv>
        </Col>
        <Col xl={2} lg={2} md={2} xs={8} sm={4}>
          <InputDiv>
            <InputLabelDiv>Valor(€)</InputLabelDiv>
            <NumberInput
              min={0}
              onInput="validity.valid || (value = '')"
              step={0.01}
              input={{
                value: parseFloat(data.weddingTeam.value).toFixed(2),
              }}
              disabled={true}
              meta={{}}
              type="number"
              placeholder="Insira o valor"
            />
          </InputDiv>
        </Col>
        <Col xl={2} lg={2} md={2} xs={8} sm={4}>
          <InputDiv>
            <InputLabelDiv>Bónus(€)</InputLabelDiv>
            <NumberInput
              // min={0}
              onInput="validity.valid || (value = '')"
              step={0.01}
              disabled={paymentDone}
              input={{
                value: parseFloat(data.weddingTeam.bonus).toFixed(2),
                onChange: event => {
                  const { rowsChiefs, teamAction } = this.state;

                  // If the payment - bonus < 0, then bonus is 0
                  if ((parseFloat(data.weddingTeam.value) + parseFloat(event)) >= 0) {
                    data.weddingTeam.bonus = parseFloat(event).toFixed(2);
                  } else {
                    data.weddingTeam.bonus = 0;
                  }

                  rowsChiefs[index] = data;

                  const indexAddEdit = teamAction.addEdit.findIndex(f => f.id === data.weddingTeam.id);
                  if (indexAddEdit === -1) teamAction.addEdit.push(data.weddingTeam);
                  else teamAction[indexAddEdit] = data.weddingTeam;

                  teamAction.delete = teamAction.delete.filter(f => f.id !== data.weddingTeam.id);

                  this.setState({ rowsChiefs, teamAction });
                  this.props.updateTeam({ addEdit: teamAction.addEdit, delete: teamAction.delete });
                  this.props.updateRowsInCharge([...rowsCoordinators, ...rowsChiefs, ...rowsHosts]);
                  // this.props.updateRowsInCharge([...rowsCoordinators, ...rowsChiefs, ...rowsHosts, ...rowsTreeHouseHosts]);
                }
              }}
              meta={{}}
              type="number"
              placeholder="Insira o valor do bónus"
            />
          </InputDiv>
        </Col>
        <Col xl={2} lg={2} md={2} xs={8} sm={4}>
          <InputDiv>
            <InputLabelDiv>Total(€)</InputLabelDiv>
            <NumberInput
              min={0}
              step={0.01}
              onInput="validity.valid || (value = '')"
              input={{
                value: data.price && data.price.setting && data.price.setting.integerValue === false ? ((parseFloat(data.weddingTeam.value) * this.state.pax) + parseFloat(data.weddingTeam.bonus)).toFixed(2) : (parseFloat(data.weddingTeam.value) + parseFloat(data.weddingTeam.bonus)).toFixed(2),
              }}
              disabled={true}
              meta={{}}
              type="number"
              placeholder="Insira o valor"
            />
          </InputDiv>
        </Col>
      </React.Fragment>}
      {!paymentDone && <Col xl={2} lg={3} md={4} xs={12} sm={4}>
        <BaseButton
          type={'default'}
          icon={'delete'}
          disabled={paymentDone} // disabled={published}
          block
          text={'Remover'}
          onClick={() => {
            let { chiefs, rowsChiefs, teamAction, updateBooking } = this.state;
            const dataOriginal = JSON.parse(JSON.stringify(data));

            if (dataOriginal.weddingTeam.employee !== null && chiefs.findIndex(f => f.id === dataOriginal.weddingTeam.employee.id) === -1) chiefs.push(dataOriginal.weddingTeam.employee);

            if (rowsChiefs.length === 1) {
              const wedding = data.weddingTeam.wedding;
              data.weddingTeam = JSON.parse(JSON.stringify(newWeddingTeam));
              data.weddingTeam.id = data.weddingTeam.id + moment().valueOf() + '2'
              data.weddingTeam.startTime = moment();
              data.weddingTeam.wedding = wedding;
              data.weddingTeam.subcategory = subcategories.chief;
              rowsChiefs[index] = data;
            } else {
              rowsChiefs = rowsChiefs.filter(f => f.weddingTeam.id !== dataOriginal.weddingTeam.id);
            }

            teamAction.addEdit = teamAction.addEdit.filter(f => f.id !== dataOriginal.weddingTeam.id);

            const indexDelete = teamAction.delete.findIndex(f => f.id === dataOriginal.weddingTeam.id);
            if (indexDelete === -1 && !dataOriginal.weddingTeam.id.includes('new#FFSDD')) teamAction.delete.push(dataOriginal.weddingTeam);
            else if (indexDelete > -1 && !dataOriginal.weddingTeam.id.includes('new#FFSDD')) teamAction.delete[indexDelete] = dataOriginal.weddingTeam;

            const booking = teamBooking.find(f => f.subcategory.id === dataOriginal.weddingTeam.subcategory.id);
            if (booking) {
              if (booking?.quantity === 1) {
                const indexDeleteBooking = deleteBooking.findIndex(f => f.id === booking.id);
                if (indexDeleteBooking === -1) deleteBooking.push(booking);

                updateBooking = updateBooking.filter(f => f.id !== booking.id);
              } else {
                booking.quantity = booking.quantity - 1;
                const indexUpdateBooking = updateBooking.findIndex(f => f.id === booking.id);
                if (indexUpdateBooking === -1) updateBooking.push(booking);

                deleteBooking = deleteBooking.filter(f => f.id !== booking.id);
              }
            }

            this.setState({ chiefs, rowsChiefs, teamAction });
            this.props.updateTeam({ addEdit: teamAction.addEdit, delete: teamAction.delete });
            this.props.deleteBooking(deleteBooking);
            this.props.updateBooking(updateBooking);
            this.props.updateRowsInCharge([...rowsCoordinators, ...rowsChiefs, ...rowsHosts]);
            // this.props.updateRowsInCharge([...rowsCoordinators, ...rowsChiefs, ...rowsHosts, ...rowsTreeHouseHosts]);
          }}
        />
      </Col>}
      <Col xl={2} lg={3} md={4} xs={12} sm={4}>
        <BaseButton
          type={'default'}
          icon={'plus'}
          block
          text={'Adicionar'}
          disabled={isCurrentUserHumanResources() && payments?.length > 0} // disabled={published}
          onClick={() => {
            const { rowsChiefs, wedding } = this.state;

            const weddingTeam = JSON.parse(JSON.stringify(newWeddingTeam));
            weddingTeam.id = weddingTeam.id + moment().valueOf() + '2';
            weddingTeam.wedding = wedding;
            weddingTeam.subcategory = subcategories.chief;
            weddingTeam.startTime = getStartTimeBySubcategory(subcategories.chief, scheduler);

            rowsChiefs.push({ weddingTeam, price: null, employees: [], extraToBooking: false });

            this.setState({ rowsChiefs });
            this.props.updateRowsInCharge([...rowsCoordinators, ...rowsChiefs, ...rowsHosts]);
            // this.props.updateRowsInCharge([...rowsCoordinators, ...rowsChiefs, ...rowsHosts, ...rowsTreeHouseHosts]);
          }}
        />
      </Col>
    </RowInCharge>)
  }

  /**
   * @description Set host rows
   * @param {*} data 
   * @returns HTML element with employee in charge form
   */
  setRowsHost = (data, index) => {
    const { subcategories, payments, published, teamBooking, scheduler } = this.state;
    const { rowsCoordinators, rowsChiefs } = this.state;
    // const { rowsTreeHouseHosts } = this.state;

    const labelEmployee = `${subcategories.host.name} ${data?.weddingTeam?.setting ? `(${data.weddingTeam.setting.name})` : ''}`;
    const paymentDone = this.checkPaymentDone(data);

    reorderEmployeesList(this.state.hosts);

    return <RowInCharge key={index} gutter={[12, 12]}>
      <Col xl={5} lg={5} md={7} xs={24} sm={isCurrentUserHumanResources() ? 8 : 10} style={{ display: 'inline-flex' }}>
        <DivSelectInput showWarning={(data?.isUnavailable || data?.weddingTeam?.employee?.deleted)}>
          <EmployeeSelectInput
            allowClear
            label={labelEmployee}
            placeholder={"Selecione a " + subcategories.host.name}
            data={this.state.hosts}
            disabled={paymentDone} //|| published
            input={{
              value: data.weddingTeam.employee?.name,
              onChange: async (event) => {
                let { hosts, rowsHosts, teamAction } = this.state;
                const dataOriginal = JSON.parse(JSON.stringify(data));

                this.updateEmployeesList(event, dataOriginal, true);

                if (event === '') {
                  data.weddingTeam.employee = null;
                  data.weddingTeam.confirmed = false;
                  data.weddingTeam.presence = false;
                  data.weddingTeam.value = 0.00;
                  data.weddingTeam.bonus = 0.00;
                  data.price = null;
                } else {
                  const employee = hosts.find(f => f.id === event);
                  const resPrice = employee ? await GetEmployeePriceByEmployeeAndSubcategory(employee.id, data.weddingTeam.subcategory.id) : null;
                  const employeePrice = resPrice && resPrice.success && resPrice.data ? resPrice.data : null;

                  data.weddingTeam.employee = employee;
                  data.weddingTeam.value = employeePrice ? employeePrice.value : 0;
                  data.weddingTeam.setting = employeePrice ? employeePrice.setting : null;
                  data.price = employeePrice;
                }

                this.updateEmployeesList(event, dataOriginal, false);

                rowsHosts[index] = data;

                if (event === '') {
                  teamAction.addEdit = teamAction.addEdit.filter(f => f.id !== dataOriginal.weddingTeam.id);

                  const indexDelete = teamAction.delete.findIndex(f => f.id === dataOriginal.weddingTeam.id);
                  if (indexDelete === -1 && !dataOriginal.weddingTeam.id.includes('new#FFSDD')) teamAction.delete.push(dataOriginal.weddingTeam);
                } else {
                  const indexAddEdit = teamAction.addEdit.findIndex(f => f.id === data.weddingTeam.id);
                  if (indexAddEdit === -1) teamAction.addEdit.push(data.weddingTeam);
                  else teamAction.addEdit[indexAddEdit] = data.weddingTeam;

                  teamAction.delete = teamAction.delete.filter(f => f.id !== data.weddingTeam.id);
                }
                this.setState({ rowsHosts, teamAction });
                this.props.updateTeam({ addEdit: teamAction.addEdit, delete: teamAction.delete });
                this.props.updateRowsInCharge([...rowsCoordinators, ...rowsChiefs, ...rowsHosts]);
                // this.props.updateRowsInCharge([...rowsCoordinators, ...rowsChiefs, ...rowsHosts, ...rowsTreeHouseHosts]);
              }
            }}
            meta={{ valid: true }}
          />
        </DivSelectInput>
        {data?.isUnavailable && <Tooltip title="Funcionário indisponível">
          <IconWarning type="warning" warning={true} />
        </Tooltip>}
        {data?.weddingTeam?.employee?.deleted && <Tooltip title="Funcionário inativo">
          <IconWarning type="user-delete" error={true} />
        </Tooltip>}
      </Col>
      <Col xl={2} lg={3} md={3} xs={8} sm={4}>
        <InputDiv>
          <InputLabelDiv>Entrada</InputLabelDiv>
          <TimePicker style={{ width: '100%' }}
            type="time"
            format='HH:mm'
            value={data?.weddingTeam?.startTime ? moment(data.weddingTeam.startTime).local() : null}
            disabled={paymentDone} //|| published
            onChange={event => {
              const { rowsHosts, teamAction } = this.state;

              data.weddingTeam.startTime = event ? event.utc() : null;
              rowsHosts[index] = data;

              const indexAddEdit = teamAction.addEdit.findIndex(f => f.id === data.weddingTeam.id);
              if (indexAddEdit === -1) teamAction.addEdit.push(data.weddingTeam);
              else teamAction.addEdit[indexAddEdit] = data.weddingTeam;

              teamAction.delete = teamAction.delete.filter(f => f.id !== data.weddingTeam.id);

              this.setState({ rowsHosts, teamAction });
              this.props.updateTeam({ addEdit: teamAction.addEdit, delete: teamAction.delete });
              this.props.updateRowsInCharge([...rowsCoordinators, ...rowsChiefs, ...rowsHosts]);
              // this.props.updateRowsInCharge([...rowsCoordinators, ...rowsChiefs, ...rowsHosts, ...rowsTreeHouseHosts]);
            }}
          />
        </InputDiv>
      </Col>
      <Col xl={2} lg={2} md={3} xs={8} sm={4}>
        <InputDiv>
          <InputLabelDiv>Confirmado</InputLabelDiv>
          <CheckboxDiv
            checked={data.weddingTeam.confirmed}
            disabled={paymentDone}
            onChange={(e) => {
              const { rowsHosts, teamAction } = this.state;
              data.weddingTeam.confirmed = e.target.checked;
              rowsHosts[index] = data;

              if (data.weddingTeam.employee === '' || data.weddingTeam.employee === null || data.weddingTeam.employee === undefined ||
                data.weddingTeam.startTime === null) {
                teamAction.addEdit = teamAction.addEdit.filter(f => f.id !== data.weddingTeam.id);
              } else {
                const indexAddEdit = teamAction.addEdit.findIndex(f => f.id === data.weddingTeam.id);
                if (indexAddEdit === -1) teamAction.addEdit.push(data.weddingTeam);
                else teamAction.addEdit[indexAddEdit] = data.weddingTeam;
              }

              teamAction.delete = teamAction.delete.filter(f => f.id !== data.weddingTeam.id);

              this.setState({ rowsHosts, teamAction });
              this.props.updateTeam({ addEdit: teamAction.addEdit, delete: teamAction.delete });
              this.props.updateRowsInCharge([...rowsCoordinators, ...rowsChiefs, ...rowsHosts]);
              // this.props.updateRowsInCharge([...rowsCoordinators, ...rowsChiefs, ...rowsHosts, ...rowsTreeHouseHosts]);
            }}>
            Sim
          </CheckboxDiv>
        </InputDiv>
      </Col>
      {!isCurrentUserAdmin() ? null : <React.Fragment>
        <Col xl={2} lg={2} md={3} xs={8} sm={4}>
          <InputDiv>
            <InputLabelDiv>Presença</InputLabelDiv>
            <CheckboxDiv
              checked={data.weddingTeam.presence}
              disabled={paymentDone} // !this.state.presenceAllowed ||
              onChange={(e) => {
                const { rowsHosts, teamAction } = this.state;
                data.weddingTeam.presence = e.target.checked;
                rowsHosts[index] = data;

                if (data.weddingTeam.employee === '' || data.weddingTeam.employee === null || data.weddingTeam.employee === undefined ||
                  data.weddingTeam.startTime === null) {
                  teamAction.addEdit = teamAction.addEdit.filter(f => f.id !== data.weddingTeam.id);
                } else {
                  const indexAddEdit = teamAction.addEdit.findIndex(f => f.id === data.weddingTeam.id);
                  if (indexAddEdit === -1) teamAction.addEdit.push(data.weddingTeam);
                  else teamAction.addEdit[indexAddEdit] = data.weddingTeam;
                }

                teamAction.delete = teamAction.delete.filter(f => f.id !== data.weddingTeam.id);

                this.setState({ rowsHosts, teamAction });
                this.props.updateTeam({ addEdit: teamAction.addEdit, delete: teamAction.delete });
                this.props.updateRowsInCharge([...rowsCoordinators, ...rowsChiefs, ...rowsHosts]);
                // this.props.updateRowsInCharge([...rowsCoordinators, ...rowsChiefs, ...rowsHosts, ...rowsTreeHouseHosts]);
              }}>
              Sim
            </CheckboxDiv>
          </InputDiv>
        </Col>
        <Col xl={2} lg={2} md={2} xs={8} sm={4}>
          <InputDiv>
            <InputLabelDiv>Pontuação</InputLabelDiv>
            <SelectInput
              allowClear
              first={true}
              data={getScoresSelector()}
              disabled={!data.weddingTeam.presence}
              input={{
                value: data.weddingTeam?.score,
                onChange: async event => {
                  if (data?.weddingTeam) {
                    const { rowsHosts, teamAction } = this.state;

                    data.weddingTeam.score = Number(event);

                    rowsHosts[index] = data;

                    if (data.weddingTeam.employee === '' || data.weddingTeam.employee === null || data.weddingTeam.employee === undefined ||
                      data.weddingTeam.startTime === null) {
                      teamAction.addEdit = teamAction.addEdit.filter(f => f.id !== data.weddingTeam.id);
                    } else {
                      const indexAddEdit = teamAction.addEdit.findIndex(f => f.id === data.weddingTeam.id);
                      if (indexAddEdit === -1) teamAction.addEdit.push(data.weddingTeam);
                      else teamAction.addEdit[indexAddEdit] = data.weddingTeam;
                    }

                    teamAction.delete = teamAction.delete.filter(f => f.id !== data.weddingTeam.id);

                    this.setState({ rowsHosts, teamAction });
                    this.props.updateTeam({ addEdit: teamAction.addEdit, delete: teamAction.delete });
                    this.props.updateRowsInCharge([...rowsCoordinators, ...rowsChiefs, ...rowsHosts]);
                  }
                }
              }}
              meta={{ valid: true }}
            />
          </InputDiv>
        </Col>
        <Col xl={2} lg={2} md={2} xs={8} sm={4}>
          <InputDiv>
            <InputLabelDiv>Valor(€)</InputLabelDiv>
            <NumberInput
              min={0}
              step={0.01}
              onInput="validity.valid || (value = '')"
              input={{
                value: parseFloat(data.weddingTeam.value).toFixed(2),
              }}
              disabled={true}
              meta={{}}
              type="number"
              placeholder="Insira o valor"
            />
          </InputDiv>
        </Col>
        <Col xl={2} lg={2} md={2} xs={8} sm={4}>
          <InputDiv>
            <InputLabelDiv>Bónus(€)</InputLabelDiv>
            <NumberInput
              // min={0}
              onInput="validity.valid || (value = '')"
              step={0.01}
              disabled={paymentDone}
              input={{
                value: parseFloat(data.weddingTeam.bonus).toFixed(2),
                onChange: event => {
                  const { rowsHosts, teamAction } = this.state;

                  // If the payment - bonus < 0, then bonus is 0
                  if ((parseFloat(data.weddingTeam.value) + parseFloat(event)) >= 0) {
                    data.weddingTeam.bonus = parseFloat(event).toFixed(2);
                  } else {
                    data.weddingTeam.bonus = 0;
                  }

                  rowsHosts[index] = data;

                  const indexAddEdit = teamAction.addEdit.findIndex(f => f.id === data.weddingTeam.id);
                  if (indexAddEdit === -1) teamAction.addEdit.push(data.weddingTeam);
                  else teamAction[indexAddEdit] = data.weddingTeam;

                  teamAction.delete = teamAction.delete.filter(f => f.id !== data.weddingTeam.id);

                  this.setState({ rowsHosts, teamAction });
                  this.props.updateTeam({ addEdit: teamAction.addEdit, delete: teamAction.delete });
                  this.props.updateRowsInCharge([...rowsCoordinators, ...rowsChiefs, ...rowsHosts]);
                  // this.props.updateRowsInCharge([...rowsCoordinators, ...rowsChiefs, ...rowsHosts, ...rowsTreeHouseHosts]);
                }
              }}
              meta={{}}
              type="number"
              placeholder="Insira o valor do bónus"
            />
          </InputDiv>
        </Col>
        <Col xl={2} lg={2} md={2} xs={8} sm={4}>
          <InputDiv>
            <InputLabelDiv>Total(€)</InputLabelDiv>
            <NumberInput
              min={0}
              step={0.01}
              onInput="validity.valid || (value = '')"
              input={{
                value: data.price && data.price.setting && data.price.setting.integerValue === false ? ((parseFloat(data.weddingTeam.value) * this.state.pax) + parseFloat(data.weddingTeam.bonus)).toFixed(2) : (parseFloat(data.weddingTeam.value) + parseFloat(data.weddingTeam.bonus)).toFixed(2),
              }}
              disabled={true}
              meta={{}}
              type="number"
              placeholder="Insira o valor"
            />
          </InputDiv>
        </Col>
      </React.Fragment>}
      {!paymentDone && <Col xl={2} lg={3} md={4} xs={12} sm={4}>
        <BaseButton
          type={'default'}
          icon={'delete'}
          disabled={paymentDone} // disabled={published}
          block
          text={'Remover'}
          onClick={() => {
            let { hosts, rowsHosts, teamAction, deleteBooking, updateBooking } = this.state;
            const dataOriginal = JSON.parse(JSON.stringify(data));

            if (dataOriginal.weddingTeam.employee !== null && hosts.findIndex(f => f.id === dataOriginal.weddingTeam.employee.id) === -1) hosts.push(dataOriginal.weddingTeam.employee);

            if (index === 0) {
              const wedding = data.weddingTeam.wedding;
              data.weddingTeam = JSON.parse(JSON.stringify(newWeddingTeam));
              data.weddingTeam.id = data.weddingTeam.id + moment().valueOf() + '3';
              data.weddingTeam.startTime = moment();
              data.weddingTeam.wedding = wedding;
              data.weddingTeam.subcategory = subcategories.host;
              rowsHosts[index] = data;
            } else {
              rowsHosts = rowsHosts.filter(f => f.weddingTeam.id !== dataOriginal.weddingTeam.id);
            }

            teamAction.addEdit = teamAction.addEdit.filter(f => f.id !== dataOriginal.weddingTeam.id);

            const indexDelete = teamAction.delete.findIndex(f => f.id === dataOriginal.weddingTeam.id);
            if (indexDelete === -1 && !dataOriginal.weddingTeam.id.includes('new#FFSDD')) teamAction.delete.push(dataOriginal.weddingTeam);
            else if (indexDelete > -1 && !dataOriginal.weddingTeam.id.includes('new#FFSDD')) teamAction.delete[indexDelete] = dataOriginal.weddingTeam;

            const booking = teamBooking.find(f => f.subcategory.id === dataOriginal.weddingTeam.subcategory.id);
            if (booking) {
              if (booking?.quantity === 1) {
                const indexDeleteBooking = deleteBooking.findIndex(f => f.id === booking.id);
                if (indexDeleteBooking === -1) deleteBooking.push(booking);

                updateBooking = updateBooking.filter(f => f.id !== booking.id);
              } else {
                booking.quantity = booking.quantity - 1;
                const indexUpdateBooking = updateBooking.findIndex(f => f.id === booking.id);
                if (indexUpdateBooking === -1) updateBooking.push(booking);

                deleteBooking = deleteBooking.filter(f => f.id !== booking.id);
              }
            }

            this.setState({ hosts, rowsHosts, teamAction });
            this.props.updateTeam({ addEdit: teamAction.addEdit, delete: teamAction.delete });
            this.props.deleteBooking(deleteBooking);
            this.props.updateBooking(updateBooking);
            this.props.updateRowsInCharge([...rowsCoordinators, ...rowsChiefs, ...rowsHosts]);
            // this.props.updateRowsInCharge([...rowsCoordinators, ...rowsChiefs, ...rowsHosts, ...rowsTreeHouseHosts]);
          }}
        />
      </Col>}
      <Col xl={2} lg={3} md={4} xs={12} sm={4}>
        <BaseButton
          type={'default'}
          icon={'plus'}
          block
          text={'Adicionar'}
          disabled={isCurrentUserHumanResources() && payments?.length > 0} // disabled={published}
          onClick={() => {
            const { rowsHosts, wedding } = this.state;

            const weddingTeam = JSON.parse(JSON.stringify(newWeddingTeam));
            weddingTeam.id = weddingTeam.id + moment().valueOf() + '3';
            weddingTeam.wedding = wedding;
            weddingTeam.subcategory = subcategories.host;
            weddingTeam.startTime = getStartTimeBySubcategory(subcategories.host, scheduler);

            rowsHosts.push({ weddingTeam, price: null, employees: [], extraToBooking: false });

            this.setState({ rowsHosts });
            this.props.updateRowsInCharge([...rowsCoordinators, ...rowsChiefs, ...rowsHosts]);
            // this.props.updateRowsInCharge([...rowsCoordinators, ...rowsChiefs, ...rowsHosts, ...rowsTreeHouseHosts]);
          }}
        />
      </Col>
    </RowInCharge>
  }

  /**
   * @description Set tree house host rows
   * @param {*} data 
   * @returns HTML element with employee in charge form
   */
  // setRowsTreeHouseHost = (data, index) => {
  //   const { subcategories, payments, published, teamBooking, scheduler } = this.state;
  //   const { rowsCoordinators, rowsChiefs, rowsHosts } = this.state;
  //   const { treeHouseHosts } = this.state;

  //   const labelEmployee = `${subcategories?.treeHouseHost?.name || ''} ${data?.weddingTeam?.setting ? `(${data?.weddingTeam?.setting?.name})` : ''}`;
  //   const paymentDone = this.checkPaymentDone(data);

  //   return <RowInCharge key={index} gutter={[12, 12]}>
  //     <Col xl={5} lg={5} md={7} xs={24} sm={isCurrentUserHumanResources() ? 8 : 10} style={{ display: 'inline-flex' }}>
  //       <DivSelectInput showWarning={(data?.isUnavailable || data?.weddingTeam?.employee?.deleted)}>
  //         <SelectInput
  //           allowClear
  //           label={labelEmployee}
  //           placeholder={"Selecione a " + subcategories.treeHouseHost.name}
  //           data={treeHouseHosts}
  //           disabled={paymentDone} //|| published
  //           input={{
  //             value: data.weddingTeam.employee?.name,
  //             onChange: async (event) => {
  //               let { treeHouseHosts, rowsTreeHouseHosts, teamAction } = this.state;
  //               const dataOriginal = JSON.parse(JSON.stringify(data));

  //               this.updateEmployeesList(event, dataOriginal, true);

  //               if (event === '') {
  //                 data.weddingTeam.employee = null;
  //                 data.weddingTeam.confirmed = false;
  //                 data.weddingTeam.presence = false;
  //                 data.weddingTeam.value = 0.00;
  //                 data.weddingTeam.bonus = 0.00;
  //                 data.price = null;
  //               } else {
  //                 const employee = treeHouseHosts?.find(f => f?.id === event);
  //                 const resPrice = employee ? await GetEmployeePriceByEmployeeAndSubcategory(employee.id, data.weddingTeam.subcategory.id) : null;
  //                 const employeePrice = resPrice && resPrice.success && resPrice.data ? resPrice.data : null;

  //                 data.weddingTeam.employee = employee;
  //                 data.weddingTeam.value = employeePrice ? employeePrice.value : 0;
  //                 data.weddingTeam.setting = employeePrice ? employeePrice.setting : null;
  //                 data.price = employeePrice;
  //               }

  //               this.updateEmployeesList(event, dataOriginal, false);

  //               rowsTreeHouseHosts[index] = data;

  //               if (event === '') {
  //                 teamAction.addEdit = teamAction.addEdit.filter(f => f?.id !== dataOriginal?.weddingTeam?.id);

  //                 const indexDelete = teamAction.delete.findIndex(f => f?.id === dataOriginal?.weddingTeam?.id);
  //                 if (indexDelete === -1 && !dataOriginal.weddingTeam.id.includes('new#FFSDD')) teamAction.delete.push(dataOriginal.weddingTeam);
  //               } else {
  //                 const indexAddEdit = teamAction.addEdit.findIndex(f => f?.id === data?.weddingTeam?.id);
  //                 if (indexAddEdit === -1) teamAction.addEdit.push(data.weddingTeam);
  //                 else teamAction.addEdit[indexAddEdit] = data.weddingTeam;

  //                 teamAction.delete = teamAction.delete.filter(f => f?.id !== data?.weddingTeam?.id);
  //               }
  //               this.setState({ rowsTreeHouseHosts, teamAction });
  //               this.props.updateTeam({ addEdit: teamAction.addEdit, delete: teamAction.delete });
  //               this.props.updateRowsInCharge([...rowsCoordinators, ...rowsChiefs, ...rowsHosts, ...rowsTreeHouseHosts]);
  //             }
  //           }}
  //           meta={{ valid: true }}
  //         />
  //       </DivSelectInput>
  //       {data?.isUnavailable && <Tooltip title="Funcionário indisponível">
  //         <IconWarning type="warning" warning={true} />
  //       </Tooltip>}
  //       {data?.weddingTeam?.employee?.deleted && <Tooltip title="Funcionário inativo">
  //         <IconWarning type="user-delete" error={true} />
  //       </Tooltip>}
  //     </Col>
  //     <Col xl={2} lg={3} md={3} xs={8} sm={4}>
  //       <InputDiv>
  //         <InputLabelDiv>Entrada</InputLabelDiv>
  //         <TimePicker style={{ width: '100%' }}
  //           type="time"
  //           format='HH:mm'
  //           value={data?.weddingTeam?.startTime ? moment(data.weddingTeam.startTime).local() : null}
  //           disabled={paymentDone} //|| published
  //           onChange={event => {
  //             const { rowsTreeHouseHosts, teamAction } = this.state;

  //             data.weddingTeam.startTime = event ? event.utc() : null;
  //             rowsTreeHouseHosts[index] = data;

  //             const indexAddEdit = teamAction.addEdit.findIndex(f => f?.id === data?.weddingTeam?.id);
  //             if (indexAddEdit === -1) teamAction.addEdit.push(data.weddingTeam);
  //             else teamAction.addEdit[indexAddEdit] = data.weddingTeam;

  //             teamAction.delete = teamAction.delete.filter(f => f?.id !== data?.weddingTeam?.id);

  //             this.setState({ rowsTreeHouseHosts, teamAction });
  //             this.props.updateTeam({ addEdit: teamAction.addEdit, delete: teamAction.delete });
  //             this.props.updateRowsInCharge([...rowsCoordinators, ...rowsChiefs, ...rowsHosts, ...rowsTreeHouseHosts]);
  //           }}
  //         />
  //       </InputDiv>
  //     </Col>
  //     <Col xl={2} lg={2} md={3} xs={8} sm={4}>
  //       <InputDiv>
  //         <InputLabelDiv>Confirmado</InputLabelDiv>
  //         <CheckboxDiv
  //           checked={data.weddingTeam.confirmed}
  //           disabled={paymentDone}
  //           onChange={(e) => {
  //             const { rowsTreeHouseHosts, teamAction } = this.state;
  //             data.weddingTeam.confirmed = e.target.checked;
  //             rowsTreeHouseHosts[index] = data;

  //             if (data?.weddingTeam?.employee === '' || data?.weddingTeam?.employee === null || data?.weddingTeam?.employee === undefined ||
  //               data?.weddingTeam?.startTime === null) {
  //               teamAction.addEdit = teamAction.addEdit.filter(f => f?.id !== data?.weddingTeam?.id);
  //             } else {
  //               const indexAddEdit = teamAction.addEdit.findIndex(f => f?.id === data?.weddingTeam?.id);
  //               if (indexAddEdit === -1) teamAction.addEdit.push(data.weddingTeam);
  //               else teamAction.addEdit[indexAddEdit] = data.weddingTeam;
  //             }

  //             teamAction.delete = teamAction.delete.filter(f => f?.id !== data?.weddingTeam?.id);

  //             this.setState({ rowsTreeHouseHosts, teamAction });
  //             this.props.updateTeam({ addEdit: teamAction.addEdit, delete: teamAction.delete });
  //             this.props.updateRowsInCharge([...rowsCoordinators, ...rowsChiefs, ...rowsHosts, ...rowsTreeHouseHosts]);
  //           }}>
  //           Sim
  //         </CheckboxDiv>
  //       </InputDiv>
  //     </Col>
  //     {!isCurrentUserAdmin() ? null : <React.Fragment>
  //       <Col xl={2} lg={2} md={3} xs={8} sm={4}>
  //         <InputDiv>
  //           <InputLabelDiv>Presença</InputLabelDiv>
  //           <CheckboxDiv
  //             checked={data?.weddingTeam?.presence}
  //             disabled={paymentDone} // !this.state.presenceAllowed ||
  //             onChange={(e) => {
  //               const { rowsTreeHouseHosts, teamAction } = this.state;
  //               data.weddingTeam.presence = e.target.checked;
  //               rowsTreeHouseHosts[index] = data;

  //               if (data?.weddingTeam?.employee === '' || data?.weddingTeam?.employee === null || data?.weddingTeam?.employee === undefined ||
  //                 data?.weddingTeam?.startTime === null) {
  //                 teamAction.addEdit = teamAction.addEdit.filter(f => f?.id !== data?.weddingTeam?.id);
  //               } else {
  //                 const indexAddEdit = teamAction.addEdit.findIndex(f => f?.id === data?.weddingTeam?.id);
  //                 if (indexAddEdit === -1) teamAction.addEdit.push(data.weddingTeam);
  //                 else teamAction.addEdit[indexAddEdit] = data.weddingTeam;
  //               }

  //               teamAction.delete = teamAction.delete.filter(f => f?.id !== data?.weddingTeam?.id);

  //               this.setState({ rowsTreeHouseHosts, teamAction });
  //               this.props.updateTeam({ addEdit: teamAction.addEdit, delete: teamAction.delete });
  //               this.props.updateRowsInCharge([...rowsCoordinators, ...rowsChiefs, ...rowsHosts, ...rowsTreeHouseHosts]);
  //             }}>
  //             Sim
  //           </CheckboxDiv>
  //         </InputDiv>
  //       </Col>
  //       <Col xl={2} lg={2} md={2} xs={8} sm={4}>
  //         <InputDiv>
  //           <InputLabelDiv>Valor(€)</InputLabelDiv>
  //           <NumberInput
  //             min={0}
  //             step={0.01}
  //             onInput="validity.valid || (value = '')"
  //             input={{
  //               value: parseFloat(data.weddingTeam.value).toFixed(2),
  //             }}
  //             disabled={true}
  //             meta={{}}
  //             type="number"
  //             placeholder="Insira o valor"
  //           />
  //         </InputDiv>
  //       </Col>
  //       <Col xl={2} lg={2} md={2} xs={8} sm={4}>
  //         <InputDiv>
  //           <InputLabelDiv>Bónus(€)</InputLabelDiv>
  //           <NumberInput
  //             // min={0}
  //             onInput="validity.valid || (value = '')"
  //             step={0.01}
  //             disabled={paymentDone}
  //             input={{
  //               value: parseFloat(data.weddingTeam.bonus).toFixed(2),
  //               onChange: event => {
  //                 const { rowsTreeHouseHosts, teamAction } = this.state;

  //                 // If the payment - bonus < 0, then bonus is 0
  //                 if ((parseFloat(data?.weddingTeam?.value) + parseFloat(event)) >= 0) {
  //                   data.weddingTeam.bonus = parseFloat(event).toFixed(2);
  //                 } else {
  //                   data.weddingTeam.bonus = 0;
  //                 }

  //                 rowsTreeHouseHosts[index] = data;

  //                 const indexAddEdit = teamAction.addEdit.findIndex(f => f?.id === data?.weddingTeam?.id);
  //                 if (indexAddEdit === -1) teamAction.addEdit.push(data.weddingTeam);
  //                 else teamAction[indexAddEdit] = data.weddingTeam;

  //                 teamAction.delete = teamAction.delete.filter(f => f?.id !== data?.weddingTeam?.id);

  //                 this.setState({ rowsTreeHouseHosts, teamAction });
  //                 this.props.updateTeam({ addEdit: teamAction.addEdit, delete: teamAction.delete });
  //                 this.props.updateRowsInCharge([...rowsCoordinators, ...rowsChiefs, ...rowsHosts, ...rowsTreeHouseHosts]);
  //               }
  //             }}
  //             meta={{}}
  //             type="number"
  //             placeholder="Insira o valor do bónus"
  //           />
  //         </InputDiv>
  //       </Col>
  //       <Col xl={2} lg={2} md={2} xs={8} sm={4}>
  //         <InputDiv>
  //           <InputLabelDiv>Total(€)</InputLabelDiv>
  //           <NumberInput
  //             min={0}
  //             step={0.01}
  //             onInput="validity.valid || (value = '')"
  //             input={{
  //               value: data?.price && data?.price?.setting && data?.price?.setting?.integerValue === false ? ((parseFloat(data?.weddingTeam?.value) * this.state.pax) + parseFloat(data?.weddingTeam?.bonus)).toFixed(2) : (parseFloat(data?.weddingTeam?.value) + parseFloat(data?.weddingTeam?.bonus)).toFixed(2),
  //             }}
  //             disabled={true}
  //             meta={{}}
  //             type="number"
  //             placeholder="Insira o valor"
  //           />
  //         </InputDiv>
  //       </Col>
  //     </React.Fragment>}
  //     {!paymentDone && <Col xl={2} lg={3} md={4} xs={12} sm={4}>
  //       <BaseButton
  //         type={'default'}
  //         icon={'delete'}
  //         disabled={paymentDone} // disabled={published}
  //         block
  //         text={'Remover'}
  //         onClick={() => {
  //           let { treeHouseHosts, rowsTreeHouseHosts, teamAction, deleteBooking, updateBooking } = this.state;
  //           const dataOriginal = JSON.parse(JSON.stringify(data));

  //           if (dataOriginal?.weddingTeam?.employee !== null && treeHouseHosts.findIndex(f => f?.id === dataOriginal?.weddingTeam?.employee?.id) === -1) {
  //             treeHouseHosts.push(dataOriginal.weddingTeam.employee);
  //           }

  //           if (index === 0) {
  //             const wedding = data.weddingTeam.wedding;
  //             data.weddingTeam = JSON.parse(JSON.stringify(newWeddingTeam));
  //             data.weddingTeam.id = data.weddingTeam.id + moment().valueOf() + '3';
  //             data.weddingTeam.startTime = moment();
  //             data.weddingTeam.wedding = wedding;
  //             data.weddingTeam.subcategory = subcategories.host;
  //             rowsTreeHouseHosts[index] = data;
  //           } else {
  //             rowsTreeHouseHosts = rowsTreeHouseHosts.filter(f => f?.weddingTeam?.id !== dataOriginal?.weddingTeam?.id);
  //           }

  //           teamAction.addEdit = teamAction.addEdit.filter(f => f?.id !== dataOriginal?.weddingTeam?.id);

  //           const indexDelete = teamAction.delete.findIndex(f => f?.id === dataOriginal?.weddingTeam?.id);
  //           if (indexDelete === -1 && !dataOriginal?.weddingTeam?.id?.includes('new#FFSDD')) teamAction.delete.push(dataOriginal.weddingTeam);
  //           else if (indexDelete > -1 && !dataOriginal?.weddingTeam?.id?.includes('new#FFSDD')) teamAction.delete[indexDelete] = dataOriginal.weddingTeam;

  //           const booking = teamBooking.find(f => f?.subcategory?.id === dataOriginal?.weddingTeam?.subcategory?.id);
  //           if (booking) {
  //             if (booking?.quantity === 1) {
  //               const indexDeleteBooking = deleteBooking.findIndex(f => f?.id === booking?.id);
  //               if (indexDeleteBooking === -1) deleteBooking.push(booking);

  //               updateBooking = updateBooking.filter(f => f?.id !== booking?.id);
  //             } else {
  //               booking.quantity = booking.quantity - 1;
  //               const indexUpdateBooking = updateBooking.findIndex(f => f?.id === booking?.id);
  //               if (indexUpdateBooking === -1) updateBooking.push(booking);

  //               deleteBooking = deleteBooking.filter(f => f?.id !== booking?.id);
  //             }
  //           }

  //           this.setState({ treeHouseHosts, rowsTreeHouseHosts, teamAction });
  //           this.props.updateTeam({ addEdit: teamAction.addEdit, delete: teamAction.delete });
  //           this.props.deleteBooking(deleteBooking);
  //           this.props.updateBooking(updateBooking);
  //           this.props.updateRowsInCharge([...rowsCoordinators, ...rowsChiefs, ...rowsHosts, ...rowsTreeHouseHosts]);
  //         }}
  //       />
  //     </Col>}
  //     <Col xl={2} lg={3} md={4} xs={12} sm={4}>
  //       <BaseButton
  //         type={'default'}
  //         icon={'plus'}
  //         block
  //         text={'Adicionar'}
  //         disabled={isCurrentUserHumanResources() && payments?.length > 0} // disabled={published}
  //         onClick={() => {
  //           const { rowsTreeHouseHosts, wedding } = this.state;

  //           const weddingTeam = JSON.parse(JSON.stringify(newWeddingTeam));
  //           weddingTeam.id = weddingTeam.id + moment().valueOf() + '3';
  //           weddingTeam.wedding = wedding;
  //           weddingTeam.subcategory = subcategories.treeHouseHost;
  //           weddingTeam.startTime = getStartTimeBySubcategory(subcategories.treeHouseHost, scheduler);

  //           rowsTreeHouseHosts.push({ weddingTeam, price: null, employees: [], extraToBooking: false });

  //           this.setState({ rowsTreeHouseHosts });
  //           this.props.updateRowsInCharge([...rowsCoordinators, ...rowsChiefs, ...rowsHosts, ...rowsTreeHouseHosts]);
  //         }}
  //       />
  //     </Col>
  //   </RowInCharge>
  // }

  render() {
    const { loading } = this.state;
    const { rowsCoordinators, rowsChiefs, rowsHosts, rowsTreeHouseHosts } = this.state;

    if (loading) return <SpinLoading />;
    return (
      <React.Fragment>
        <SectionTitle subsection>Funcionários Responsáveis</SectionTitle>
        <div className='desktop-width-80 mobile-full-width'>
          {rowsCoordinators.map((row, index) => this.setRowsCoordinator(row, index))}
          {rowsChiefs.map((row, index) => this.setRowsChief(row, index))}
          {rowsHosts.map((row, index) => this.setRowsHost(row, index))}
          {/* {rowsTreeHouseHosts.map((row, index) => this.setRowsTreeHouseHost(row, index))} */}
        </div>

      </React.Fragment>
    );
  }
}

export default AddEditEmployeesInCharge;
