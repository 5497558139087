const SystemQuestions = [
  '5e3184829621e5d6a3d89742',
  '5e3184939621e5d6a3d89743',
  '5e3184b59621e5d6a3d89744',
  '5e3185a69621e5d6a3d89749',
  '5e6a0a0629196b814faa7ce9',
  '5e7360f129196b814fb9885b',
  '5e73610729196b814fb9887f',
  '60e2dbe612cef67e129c0d33',
  '5e18723686fdaa6d7aeedd91',
  '5e1e58a76c30747c0329e56d',
  '67c88aae451b84687ed0cc3d'
];

export const EnableDelete = id =>
  SystemQuestions.findIndex(x => x === id) === -1;

const ChaptersWithoutQuestions = [
  '5e3182279621e5d6a3d89741',
  '5e42e53429196b814f617d30',
  '5e42e52b29196b814f617d18',
  '65b37c49b42ba97e4a0db4c9',
  '65afd1a7b42ba97e4a0db4b3'
];

export const EnableQuestions = id =>
  ChaptersWithoutQuestions.findIndex(x => x === id) === -1;

const ChaptersWithoutNewQuestions = [
  '5e3181e69621e5d6a3d89740',
  '5e735eeb29196b814fb983b6',
  '67c5799bdaa8547cb6e7db97'
];

export const EnableNewQuestions = id =>
  ChaptersWithoutNewQuestions.findIndex(x => x === id) === -1;
