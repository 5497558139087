import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { withLocalize } from "react-localize-redux";
import { Checkbox, Icon, Spin, Tooltip } from "antd";
import {
  SelectDiv,
  StyledSelectInput,
  MultipleStyledSelectInput,
  MultipleSelectDiv,
  SelectOptions,
  SelectOption,
  InputLabelDiv,
  SelectOptionUpgradeSelection
} from "./InputStyles";

const SelectRestrictionInput = ({
  input,
  data,
  dataKey,
  dataLabel,
  placeholder,
  notFoundMessage,
  label,
  disabled,
  fetchData,
  loading,
  meta,
  translatable = true,
  activeLanguage,
  allowClear,
  blockMainDishes
}) => {
  const { invalid, submitFailed } = meta;
  const showError = invalid && submitFailed;
  const [filled, setFilled] = useState(input && input.value !== "");
  const [open, toogleOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState([]);
  const dropdownRef = useRef(null);

  // useEffect(() => {
  //   setOptions(data.map(m => ({ ...m, checked: false })))
  // }, [data]);

  useEffect(() => {
    if (data?.length > 0) {
      setOptions(data.map(m => ({ ...m, checked: input.value?.includes(m._id) })));
    }
  }, [data, input.value]);

  useEffect(() => {
    setValue(input.value || []);

    const value = input?.value || [];
    const options = data.map(m => ({ ...m, checked: value?.includes(m?._id) }));
    setOptions(options);
  }, [input.value]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current?.contains(event.target)
        && !event?.target?.class?.includes('select-restriction-icon')) {
        toogleOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const formatValue = () => {
    const value = input.value;
    if (!value) return undefined;
    if (Array.isArray(value)) {
      const selected = value?.length > 0
        ? options.filter(f => value.includes(f?._id))
        : [];

      return selected?.length > 0
        ? selected?.map(m => m?.acronym['pt'])?.join(', ')
        : undefined;
    }
    return value.toString();
  };

  const isFilled = () => {
    return input?.value && Array.isArray(input?.value) && input?.value?.length > 0 ? true : false;
  }

  const changeSelect = value => {
    input.onChange(value || '');
  };

  const changeChecked = (event, elem) => {
    let value = input.value || [];

    if (event?.target.checked) {
      if (elem.upgradeSelection) {
        value = value.filter(id => {
          const option = data.find(item => item._id === id);
          return option && !option.upgradeSelection;
        });
      }
      value.push(elem._id);
    } else {
      value = value.filter(f => f != elem._id);
    }

    const options = data.map(m => ({
      ...m,
      checked: value.includes(m._id),
    }));

    setOptions(options);
    changeSelect(value);
  };

  const toogleSelect = () => {
    if (disabled) {
      return null;
    } else {
      toogleOpen(!open)
    }
  }

  const BLOCKED_MAIN_DISHES_IDS = () => {
    if (blockMainDishes) {
      return [
        '67c03cceb272a8dfe4179380',
        '67c03d27b272a8dfe4179381'
      ];
    }

    return [];
  }

  return (
    <MultipleSelectDiv disabled={disabled} ref={dropdownRef}>
      {label && <InputLabelDiv>{label}</InputLabelDiv>}
      <MultipleStyledSelectInput filled={isFilled()} disabled={disabled} onClick={toogleSelect}>
        <div className="select-input">
          {formatValue()}
        </div>
        <span className="ant-select-arrow" onClick={toogleSelect}>
          {open
            ? <i class="fa-solid fa-angle-up select-restriction-icon"></i>
            : <i className="fa-solid fa-angle-down select-restriction-icon"></i>
          }
        </span>
        {
          allowClear && input?.value && input?.value?.length > 0 && formatValue() != undefined
          && <span className="ant-select-selection__clear" onClick={() => changeSelect([])}>
            <Icon type="close-circle" theme="filled" />
          </span>
        }
      </MultipleStyledSelectInput>
      {open && <SelectOptions>
        {/* {options.filter(f => !f?.deleted).map((elem, index) => ( */}
        {options.map((elem, index) => (
          <Tooltip key={dataKey ? elem[dataKey] : index} title={elem['subtitle']['pt']}>
            <SelectOptionUpgradeSelection key={dataKey ? elem[dataKey] : index} disabled={BLOCKED_MAIN_DISHES_IDS().includes(elem[dataKey]) || elem?.disabled} upgradeSelection={elem?.upgradeSelection}>
              <Checkbox checked={elem.checked} onChange={(e) => changeChecked(e, elem)} disabled={BLOCKED_MAIN_DISHES_IDS().includes(elem[dataKey])}>
                <span>
                  {dataLabel
                    ? (translatable ? elem[dataLabel]['pt'] : elem[dataLabel])
                    : elem}
                </span>
              </Checkbox>
            </SelectOptionUpgradeSelection>
          </Tooltip>
        ))}
      </SelectOptions>}
    </MultipleSelectDiv>
  );
};

SelectRestrictionInput.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  dataKey: PropTypes.string,
  dataLabel: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  fetchData: PropTypes.func,
  loading: PropTypes.bool,
  onPressEnter: PropTypes.func,
  allowClear: PropTypes.bool,
  removeSelection: PropTypes.func,
  tag: PropTypes.string
};

SelectRestrictionInput.defaultProps = {
  data: [],
  allowClear: true,
  dataLabel: 'acronym',
  dataKey: '_id'
};

export default withLocalize(SelectRestrictionInput);
