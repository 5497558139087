import React, { Component } from 'react';
import {
    Row,
    Col,
} from 'antd';
import {
    SectionTitle
} from '../../styles/BasicStyles';
import { withRouter } from 'react-router-dom';
import BaseButton from '../../components/buttons/BaseButton';
import { EmailYearType, GetDEYearByType, UpdateDEYear } from '../../infra/requests/DynamicEmailYearRequests';
import Alerts from '../../components/alert/Alert';
import NumberInput from '../../components/inputs/NumberInput';
import { CreateAvgScoreMonthDayConfig, UpdateAvgScoreMonthDayConfig, GetAvgScoreMonthDayConfig, GetAvgScoresAllMonths, UpdateCurrentAvgScoreMonthDayConfig, UpdateAllMonths } from '../../infra/requests/AvgScoreMonthDayRequests'

class ScoreTable extends Component {
    state = {
        loading: false,
        pageSize: 15,
        currentPage: 1,
        filtersYear: { type: 'C' },
        selectedIndex: null,
        submitted: false,
        loadingAvgScoreMonthDay: false,
        saveAvgScoreMonthDay: false,
        cancelAvgScoreMonthDay: false,
        avgScoreMonthDay: null,
        submittedAvgScoreMonthDay: false,
        rowsYear: [],
        totalYear: 0
    };

    componentDidMount = async () => {
        await this.getAvgScoresAllMonths();
    };

    getAvgScoresAllMonths = async () => {
        this.setState({ loadingAvgScoreMonthDay: true });

        try {
            const result = await GetAvgScoresAllMonths();

            this.setState({
                avgScoreMonthDay: result?.success && result?.data ? result?.data : [],
                loadingAvgScoreMonthDay: false
            });
        } catch (e) {
            this.setState({ loadingAvgScoreMonthDay: false });
            console.error(e);
        }
    }

    saveAvgScoreMonthDay = async () => {
        const { avgScoreMonthDay } = this.state;
        this.setState({ submittedAvgScoreMonthDay: true });

        if (!avgScoreMonthDay) {
            return;
        }

        if (avgScoreMonthDay > 31) {
            Alerts.new({
                type: 'error',
                title: 'Erro!',
                text: 'Não pode ser superior a dia 31!'
            });
            return;
        }

        this.setState({ saveAvgScoreMonthDay: true });
        
        const result = await UpdateAllMonths(avgScoreMonthDay);        

        if (result.success) {
            this.setState({
                avgScoreMonthDay: result?.success && result?.data ? result?.data : []
            });

            Alerts.new({
                type: 'success',
                title: 'Sucesso!',
                text: 'Dia do mês para o cálculo da pontuação média atualizado!'
            });
            this.setState({ saveAvgScoreMonthDay: false, submittedAvgScoreMonthDay: false });
        } else {
            Alerts.new({
                type: 'error',
                title: 'Erro!',
                text: 'Não foi possível atualizar o dia do mês para o cálculo da pontuação média!'
            });
            this.setState({ saveAvgScoreMonthDay: false, submittedAvgScoreMonthDay: false }, () => this.getAvgScoreMonthDay());
        }
    }

    render() {
        const { saveAvgScoreMonthDay, cancelAvgScoreMonthDay, submittedAvgScoreMonthDay } = this.state;
        let { avgScoreMonthDay } = this.state;        

        return (
            <React.Fragment>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20 }}>
                    <SectionTitle>Dia do mês - cálculo da média</SectionTitle>
                    <div>
                        <BaseButton
                            type={'primary'}
                            icon={'save'}
                            text={'Guardar'}
                            loading={saveAvgScoreMonthDay}
                            onClick={() => this.saveAvgScoreMonthDay()}
                        />
                        {/* <BaseButton
                            style={{ marginLeft: 10 }}
                            type={'default'}
                            icon={'close'}
                            text={'Cancelar'}
                            loading={cancelAvgScoreMonthDay}
                            onClick={() => this.getAvgScoreMonthDay()}
                        /> */}
                    </div>
                </div>
                <Row gutter={[16, 16]}>
                    {avgScoreMonthDay?.map(m => (
                        <Col key={m?.monthName} lg={6} md={8} sm={12} xs={24}>
                            <div style={{ marginBottom: 10 }}>
                                <strong>{m?.monthName}</strong>
                                <NumberInput
                                    noLabel={true}
                                    input={{
                                        value: m?.day,
                                        onChange: (e) => {
                                            m.day = e;
                                            this.setState({ avgScoreMonthDay });
                                        }
                                    }}
                                    max={m?.maxDay}
                                    min={0}
                                    meta={{}}
                                    requiredError={submittedAvgScoreMonthDay && avgScoreMonthDay ? true : false}
                                    type="number"
                                    placeholder="Insira o dia"
                                />
                            </div>
                        </Col>
                    ))}
                </Row>
            </React.Fragment>
        );
    }
}

export default withRouter(ScoreTable);